import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import UploadFileSvg from '../../../assets/file-upload.svg';
import { useLanguage } from '../../../Hooks';
import { colorPalette } from '../../../utils/colorPalette';

const i18n = {
  es: {
    titles: { title1: `Selecciona tu archivo` },
    texts: { text1: `Arrastra o da clic para subir tu archivo` },
  },
  en: {
    titles: { title1: `Select your file` },
    texts: { text1: `Drag or click to upload your file` },
  },
};

const DropZoneWrapper = styled('div')(({ disabled }) => ({
  color: disabled ? colorPalette.grey[200] : colorPalette.grey[700],
  padding: '10px 10px',
  backgroundColor: '#f4f6f8',
  borderRadius: '10px',
  pointerEvents: disabled ? 'none' : 'all',
  transition: 'all 0.5s',
  border: `1px dashed ${colorPalette.grey[500]}`,
  '&:hover': {
    cursor: 'pointer',
    color: colorPalette.grey[800],
  },
  '& img': {
    width: 'calc(100% - 30px)',
  },
}));

export const DropZone = (props) => {
  const { onDropHandle, fileTypes, disabled, image } = props;
  const { translations } = useLanguage({ i18n });
  const { getRootProps, getInputProps } = useDropzone({
    accept: fileTypes,
    onDrop: onDropHandle,
    onDropRejected: (files) => {
      //TODO: catch rejected files
      console.log(files, 'rejected');
    },
  });

  return (
    <DropZoneWrapper disabled={disabled} {...getRootProps()}>
      <Grid
        direction="row"
        container
        alignItems="center"
        justifyContent="center"
      >
        <input {...getInputProps()} />
        <Grid item xs={6}>
          <img
            src={image || UploadFileSvg}
            alt="front-ine"
            width="100%"
            height="100%"
          />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}>
          <Typography variant="subtitle2" color={colorPalette.grey[900]}>
            <strong>{translations?.titles?.title1}</strong>
          </Typography>
          <Typography variant="caption">
            {translations?.texts?.text1}
          </Typography>
        </Grid>
      </Grid>
    </DropZoneWrapper>
  );
};
