import React from 'react';

export default function CheckLine(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="remix-icons/outlined/system/check-line">
        <g id="Group">
          <path
            id="Vector"
            d="M9.99999 15.172L19.192 5.979L20.607 7.393L9.99999 18L3.63599 11.636L5.04999 10.222L9.99999 15.172Z"
            fill={props.fill ? props.fill : 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
}
