import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const initSentry = (environment) => {
  Sentry.init({
    dsn: 'https://77fa8885ecb943f6ab4d79e60196d9a6@o878095.ingest.sentry.io/5829545',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    environment,
    tracesSampleRate: 0.2,
  });
};
