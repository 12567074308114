import { TextField, Box } from '@mui/material';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import React, { forwardRef, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { styled } from '@mui/system';
import { validationUtil } from '../../../../utils/fieldUtils';
import { WarnTooltip } from '../WarnTooltip';

export const CustomPhoneInput = styled(PhoneInputWithCountry)(({ size }) => ({
  '&.PhoneInput': {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& input': {
      marginLeft: '45px',
    },
  },
  '& label:not(.MuiInputLabel-shrink)': {
    marginLeft: '45px',
  },
  '& .PhoneInputCountry': {
    position: 'absolute',
    top: size === 'small' ? '12px' : '20px',
    left: '20px',
    '& .PhoneInputCountrySelectArrow': {
      display: 'none',
    },
  },
}));
const MUIPhoneField = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} fullWidth />;
});

export const PhoneInputControlled = ({
  name,
  control,
  muiProps,
  disableOnDefaultValue = false,
  errorPayload,
  warning,
  rules = {
    validate: (value) => validationUtil.phone(value),
  },
  errorMapper = ({ error }) => {
    return {
      pattern: 'Campo inválido',
      required: 'Campo requerido',
    }['pattern'];
  },
}) => {
  const { InputProps = {}, InputLabelProps = {} } = muiProps || {};
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const { invalid, isDirty, error } = fieldState;
  const { onChange, onBlur, value, ref } = field;

  const [disableOnDefaultState, setDisableOnDefaultState] = useState(false);

  useEffect(() => {
    if (disableOnDefaultValue && value && !isDirty) {
      setDisableOnDefaultState(true);
    }
  }, [disableOnDefaultValue, value, isDirty]);

  const handleChangePhone = (value) => {
    const fakeEv = {
      target: {
        value,
        name: 'phone',
        id: 'phone',
      },
    };
    onChange(fakeEv);
  };

  let endAdornment = null;
  if (warning) endAdornment = <WarnTooltip />;
  const muiPropsEndAdornment = InputProps?.endAdornment;
  if (muiPropsEndAdornment) endAdornment = muiPropsEndAdornment;

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <CustomPhoneInput
        defaultCountry="MX"
        value={value} // input value
        name={name} // send down the input name
        onChange={(value = '') => handleChangePhone(value)}
        onBlur={onBlur}
        inputComponent={MUIPhoneField}
        error={!warning && invalid}
        helperText={
          invalid ? errorMapper({ error, payload: errorPayload }) : ''
        }
        disabled={disableOnDefaultState}
        control={control}
        color={warning ? 'warning' : 'primary'}
        inputRef={ref} // send input ref, so we can focus on input when error appear
        {...muiProps}
        InputProps={{
          ...InputProps,
          endAdornment,
        }}
        InputLabelProps={{
          ...InputLabelProps,
          color: warning ? 'warning' : 'primary',
        }}
      />
    </Box>
  );
};
