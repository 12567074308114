import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

export const isoTimeParser = (isoString) => {
  const [year, month, day] = isoString.split('T')[0].split('-');
  return `${day}/${month}/${year}`;
};

export const msTimeHandler = ({
  y = 0,
  m = 0,
  w = 0,
  d = 0,
  h = 0,
  mo = 0,
  s = 0,
}) => {
  const second = 1000;
  const minutes = second * 60;
  const hours = minutes * 60;
  const days = hours * 24;
  const weeks = days * 7;
  const months = weeks * 4 + days * 2; // This is an average
  const years = months * 12 + 5;

  const appliedSecond = second * s;
  const appliedMinutes = minutes * m;
  const appliedHours = hours * h;
  const appliedDays = days * d;
  const appliedWeeks = weeks * w;
  const appliedMonths = months * mo;
  const appliedYears = years * y;

  return (
    appliedSecond +
    appliedMinutes +
    appliedHours +
    appliedDays +
    appliedWeeks +
    appliedMonths +
    appliedYears
  );
};

export const dateToLocalTimeZone = (date) => {
  dayjs.extend(timezone);

  const localTime = `${dayjs(date).format(
    'YYYY-MM-DD HH:mm:ss',
  )} ${dayjs.tz.guess()}`;

  return localTime;
};
