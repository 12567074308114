import { loggerUtil } from '../../utils/loggerUtil';
import { dialogErrorsUtil } from '../../utils/ErrorHandling/dialogErrorUtil';
import { localStorageUtil } from '../../utils/localStorageUtils';
import { logoutUtil } from '../../utils/dispatchUtil';

const tokenExpHandler = (err) => {
  return err.type === 'TOKEN_EXPIRED' || err.type === 'TOKEN_MISMATCH';
};
const unauthHandler = (err) => {
  return err.type === 'UNAUTHORIZED_EXCEPTION';
};

const requestInterceptor = (config) => {
  loggerUtil.requestLog(config);
  const cachedToken = localStorageUtil.getToken();
  if (cachedToken && !config.baseURL.includes('ipify')) {
    config.headers = {
      ...config.headers,
      Authorization: config.headers?.Authorization || cachedToken.token,
    };
  }
  return config;
};

const responseInterceptor = (res) => {
  loggerUtil.responseLog(res);
  // Checks timezone diff
  if (res.config.url.includes('login') || res.config.url.includes('register')) {
    if (res.data?.token?.exp < Date.now()) {
      const diff = Date.now() - res.data.token.exp;
      res.data.token.exp = res.data.token.exp + diff + 60 * 60 * 1000;
    }
  }

  if (res.status === 401 && res.data?.errors?.some(tokenExpHandler)) {
    dialogErrorsUtil.tokenExpired();
  }
  if (res.data?.errors?.some(unauthHandler)) {
    logoutUtil();
  }
  if (res.status === 500) {
    dialogErrorsUtil.errorOnServer();
  }
  return res;
};

export const intercpetorHandler = (instance) => {
  instance.interceptors.request.use(requestInterceptor, (error) =>
    Promise.reject(error),
  );
  instance.interceptors.response.use(responseInterceptor, (error) => {
    if (!navigator.onLine) {
      return dialogErrorsUtil.noNetworkConnection();
    }
    if (
      error.message === 'Network Error' ||
      error.message.includes('timeout')
    ) {
      dialogErrorsUtil.noNetworkConnection();
    }
    return Promise.reject(error);
  });
};
