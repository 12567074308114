import { verifyPhoneErrorConstants } from '../Profile/ProfileGeneral/constants';

const verifyPhoneErrorHandler = ({ response, setError }) => {
  const {
    status,
    data: { errors },
  } = response;
  const error = verifyPhoneErrorConstants[status][errors[0].type];
  if (error.field) {
    setError(error.field, { type: error.type }, { shouldFocus: true });
  }

  return error.action();
};
export const errorHandler = {
  verifyPhone: verifyPhoneErrorHandler,
};
