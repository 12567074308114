import React from 'react';
import { Box, Link } from '@mui/material';
import { useLanguage } from '../../../Hooks';
import { i18n } from './i18n';

export const BrowserLinksMobile = () => {
  const { translations, lang } = useLanguage({ i18n });

  return (
    <>
      {translations?.texts?.text1}
      <br />
      <Box fontWeight="bold" mt="1rem">
        {translations?.texts?.text2}
      </Box>
      <Box
        fontWeight="bold"
        mt="0.5rem"
        style={{ display: 'flex', justifyContent: 'space-around' }}
      >
        <Link
          target="_blank"
          href={
            lang === 'es'
              ? 'https://support.google.com/chrome/answer/2693767?hl=es-419&co=GENIE.Platform%3DAndroid'
              : '"https://support.google.com/chrome/answer/2693767?hl=en-419&co=GENIE.Platform%3DAndroid"'
          }
        >
          Chrome
        </Link>
        <Link
          target="_blank"
          href={
            lang === 'en'
              ? 'https://support.mozilla.org/es/kb/como-administrar-los-permisos-de-tu-camara-y-micro#:~:text=Si%20has%20desactivado%20el%20acceso,Permisos%20para%20la%20aplicaci%C3%B3n%20Firefox.'
              : 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_change-camera-permissions:~:text=or%20Block.-,Use%20Your%20Android%20device%E2%80%99s%20settings%20menu%20to%20change%20camera%20and%20microphone%20permissions,-If%20you%E2%80%99ve%20turned'
          }
        >
          Firefox
        </Link>
        <Link
          target="_blank"
          href="https://support.apple.com/guide/iphone/control-access-to-hardware-features-iph168c4bbd5/ios"
        >
          Safari
        </Link>
      </Box>
    </>
  );
};
