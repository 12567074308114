import React, { useState } from 'react';
import { Wrapper, styles } from './style';
import {
  Typography,
  Container,
  CssBaseline,
  Button,
  Grid,
  useTheme,
} from '@mui/material';

import { phoneVerificationAPI } from '../../API/services';
import { Link, useHistory, useParams } from 'react-router-dom';
import { errorHandler } from './helperFuncs';
import VerifyOtp from '../VerifyOtp';
import { globalFeedbackUtil } from '../../utils/FeedbackUtils/globalUtils';
import { dialogUtil } from '../../utils/dialogUtils';
import { snackUtil } from '../../utils/snackUtils';

export const PhoneVerification = () => {
  const history = useHistory();
  const { user } = useParams();
  const theme = useTheme();
  const style = styles(theme);

  const [tokenVerified, setTokenVerified] = useState(false);

  const handleSubmitOtp = async (otp) => {
    try {
      globalFeedbackUtil.setBackdropIndeterminate({
        clickable: false,
        text: 'Verificando código de único uso',
      });

      const verifyPhoneRes = await phoneVerificationAPI({ otp }, {}, { user });
      globalFeedbackUtil.closeBackdrop();
      if (verifyPhoneRes.status >= 400) {
        return errorHandler.verifyPhone({ response: verifyPhoneRes });
      }
      dialogUtil.defaultOnAction();
      setTokenVerified(true);
      snackUtil.phoneVerificationSuccess();
    } catch (error) {
      globalFeedbackUtil.closeBackdrop();
    }
  };

  return (
    <Wrapper>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={style.paper}>
          <Link to="/login">
            <img
              src={process.env.PUBLIC_URL + '/assets/imgs/logo_sife_crop.png'}
              alt=""
              height="65px"
              width="auto"
              style={{ margin: '10px' }}
            />
          </Link>
          <Typography component="h1" variant="h5">
            Verificación de teléfono celular
          </Typography>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              {tokenVerified && '¡Tu teléfono ha sido verificado!'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {tokenVerified ? (
              <Button
                disabled={!tokenVerified}
                variant="contained"
                color="primary"
                onClick={() => history.push('/')}
              >
                Continuar
              </Button>
            ) : (
              <VerifyOtp
                overrideSubmit={handleSubmitOtp || console.log}
                // overrideSendAgain={handleSendPhoneOtpAgain || console.log}
                noSendAgain
                noLogo
                noBack
                noDesc
                noMargin
                noHeight
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
