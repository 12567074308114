import { closeBackdropAction, setBackdropAction } from '../../actions';
import { notificationDispatchUtil } from '../dispatchUtil';

export const globalFeedbackUtil = {
  closeBackdrop: () => notificationDispatchUtil.dispatch(closeBackdropAction()),
  setBackdropIndeterminate: (payload) =>
    notificationDispatchUtil.dispatch(
      setBackdropAction({
        open: true,
        variant: 'indeterminate',
        ...payload,
      }),
    ),
  setBackdropDeterminate: ({
    clickable = true,
    current = 0,
    total = 100,
  } = {}) =>
    notificationDispatchUtil.dispatch(
      setBackdropAction({
        open: true,
        current,
        total,
        clickable,
        variant: 'determinate',
      }),
    ),
};
