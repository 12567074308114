import { Grid, ButtonBase } from '@mui/material';
import { Cropper } from 'react-cropper';
import { styled } from '@mui/system';

export const Wrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'pictureReady',
})(({ theme, pictureReady, height, width }) => ({
  padding: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed',
  borderStyle: pictureReady ? 'solid' : 'dashed',
  borderColor: pictureReady
    ? theme.palette.primary.main
    : 'rgba(145, 158, 171, 0.32)',
  borderRadius: '50%',
  height: height + 'px',
  width: width + 'px',
}));

const WRAPPER_STYLE = {
  textAlign: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: '#F4F6F8',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  postion: 'relative',
};

export const SpanWrapper = styled('span')(() => WRAPPER_STYLE);
export const ButtonWrapper = styled(ButtonBase)(() => WRAPPER_STYLE);
export const GridWrapper = styled(Grid)(() => WRAPPER_STYLE);
export const CropperWrapper = styled(Cropper)(() => WRAPPER_STYLE);
