import React, { useState } from 'react';
import { Paper, InputBase, IconButton } from '@mui/material';
import SearchLine from '../Icons/system/SearchLine';

export const SearchInput = (props) => {
  const [text, setText] = useState('');
  const { placeholder } = props;

  const handleSearch = () => {
    props?.handleSearch(text);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '16px',
      }}
    >
      <InputBase
        value={text}
        placeholder={placeholder}
        onChange={(event) => setText(event.target.value)}
        onKeyDown={(event) => event.key === 'Enter' && handleSearch()}
        sx={{
          marginLeft: (theme) => theme.spacing(1),
          flex: 1,
        }}
      />
      <IconButton
        type="submit"
        aria-label="search"
        onClick={handleSearch}
        sx={{ padding: '10px' }}
      >
        <SearchLine />
      </IconButton>
    </Paper>
  );
};
