import React, { useEffect } from 'react';
import {
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  Link as MUILink,
  useTheme,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { InputControlled, RadioControlled } from '../_common/ControlledInputs';
import { sanitizers, errors } from './constants';
import { validationUtil } from '../../utils/fieldUtils';
import { dialogUtil } from '../../utils/dialogUtils';
import { getContactMethods, sendRecovery } from './helperFuncs';

const ContactMethodsForm = (props) => {
  const { form, payload } = props;
  const { contactMethods } = payload;
  const history = useHistory();
  const theme = useTheme();

  const handleSendRecovery = async () => {
    await sendRecovery({
      user: form.getValues('user'),
      contactMethod: form.getValues('contactMethod'),
      callbackSuccess: (data) => {
        dialogUtil.customSuccess({
          payload: {
            title: 'Enlace enviado',
            message: 'Enlace de recuperación enviado correctamente.',
          },
          onConfirm: () => {
            history.push('/login');
            dialogUtil.defaultOnAction();
          },
        });
      },
    });
  };

  useEffect(() => {
    if (contactMethods?.length > 0) {
      form.setValue('contactMethod', contactMethods[0].type, {
        shouldDirty: true,
      });
    }
  }, [form, contactMethods]);

  return (
    <React.Fragment>
      <Typography variant="body1">
        ¿Por dónde quieres ser contactado?
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioControlled
            name="contactMethod"
            control={form.control}
            muiProps={{
              style: {
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'flex-start',
              },
            }}
          >
            <Grid container spacing={2}>
              {contactMethods.map((contactMethod, index) => (
                <Grid item xs={12} key={index}>
                  <Card
                    variant="outlined"
                    style={{
                      padding: '1rem',
                      borderRadius: '8px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography variant="body1">
                      {contactMethod.type === 'email' && 'Correo electrónico'}
                      {contactMethod.type === 'phone' && 'Teléfono'}
                    </Typography>
                    <FormControlLabel
                      value={contactMethod.type}
                      control={<Radio color="primary" />}
                      label={contactMethod.contact}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </RadioControlled>
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ margin: theme.spacing(3, 0, 2) }}
        disableElevation
        onClick={handleSendRecovery}
      >
        Continuar
      </Button>
    </React.Fragment>
  );
};

const UserForm = (props) => {
  const { form, handleNext, handleUpdatePayload } = props;
  const { formState } = form;
  const theme = useTheme();

  const isUserValid = formState.dirtyFields.user && !formState.errors.user;

  const handleRetrieveContactMethods = async () => {
    await getContactMethods({
      user: form.getValues('user'),
      callbackSuccess: (contactMethods) => {
        handleUpdatePayload({ contactMethods });
        handleNext();
      },
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputControlled
            name="user"
            rules={{
              required: true,
              validate: {
                notValid: (value) => {
                  return (
                    validationUtil.email(value) ||
                    validationUtil.rfc(value) ||
                    validationUtil.curp(value)
                  );
                },
              },
            }}
            muiProps={{
              label: 'RFC / CURP/ Correo electrónico',
              variant: 'outlined',
              fullWidth: true,
              autoFocus: true,
              required: true,
            }}
            control={form.control}
            sanitizeFn={sanitizers.user}
            errorMapper={errors.user}
          />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ margin: theme.spacing(3, 0, 2) }}
        disableElevation
        onClick={handleRetrieveContactMethods}
        disabled={!isUserValid}
      >
        Continuar
      </Button>
    </React.Fragment>
  );
};

export const SendRecoveryForm = (props) => {
  const { form, handleUpdatePayload, payload, step, handleNext } = props;
  const theme = useTheme();

  return (
    <div
      style={{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src={process.env.PUBLIC_URL + '/assets/imgs/logo_sife.png'}
        alt=""
        height="60px"
        width="auto"
        style={{ margin: '10px' }}
      />
      <Typography component="h1" variant="h3">
        Recupera tu cuenta
      </Typography>
      <br />
      {step === 1 && (
        <UserForm
          form={form}
          payload={payload}
          handleNext={handleNext}
          handleUpdatePayload={handleUpdatePayload}
        />
      )}
      {step === 2 && (
        <ContactMethodsForm
          form={form}
          payload={payload}
          handleNext={handleNext}
          handleUpdatePayload={handleUpdatePayload}
        />
      )}
      <MUILink to={`/login`} component={Link}>
        <Typography variant="body2">Regresar</Typography>
      </MUILink>
    </div>
  );
};
