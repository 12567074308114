import React from 'react';
import { Wrapper } from './style';
import { Fab, useTheme } from '@mui/material';

export const MUIFab = (props) => {
  const {
    children,
    classes,
    color,
    component,
    disabled,
    disableFocusRipple,
    disableRipple,
    href,
    size,
    variant,
    active = true,
    bgColor,
    noShadow,
    margin,
    onClick,
  } = props;
  const theme = useTheme();
  return (
    <Wrapper
      active={active}
      bgColor={bgColor}
      margin={margin}
      noShadow={noShadow}
      theme={theme}
      color={color || 'primary'}
    >
      <Fab
        classes={classes}
        color={color}
        component={component}
        disabled={disabled}
        disableFocusRipple={disableFocusRipple}
        disableRipple={disableRipple}
        href={href}
        size={size}
        variant={variant}
        onClick={onClick}
      >
        {children}
      </Fab>
    </Wrapper>
  );
};
