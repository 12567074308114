import React from 'react';

export default function AddLine(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="remix-icons/outlined/system/add-line">
        <g id="Group">
          <path
            id="Vector"
            d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
            fill={props.fill ? props.fill : '#09121F'}
          />
        </g>
      </g>
    </svg>
  );
}
