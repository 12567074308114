import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        sx={{ color: props.backdrop ? 'white' : null }}
        variant={props.variant}
        value={props.value}
        size={props.big ? '5rem' : undefined}
      />
      {props.variant === 'determinate' ? (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            style={{ fontSize: props.big ? '1.5rem' : null }}
            variant="caption"
            component="div"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export const CircularFeedBack = (props) => {
  const [progress, setProgress] = useState(0);

  const { variant, total, current, backdrop, big } = props;
  useEffect(() => {
    const percent = Math.min((current * 100) / total) || 0;
    setProgress(percent);
    if (percent === 100) {
      setTimeout(() => setProgress(0), 2000);
    }

    return () => {
      setProgress(0);
    };
  }, [total, current]);

  return (
    <CircularProgressWithLabel
      variant={variant}
      value={progress}
      big={big}
      backdrop={backdrop}
    />
  );
};
