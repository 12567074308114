import { lazy } from 'react';
const ConfirmDeleteDocs = lazy(() => import('./ConfirmDeleteDocs'));
const ViewPdfFile = lazy(() => import('./ViewPdfFile'));
const DocumentEncrypted = lazy(() => import('./DocumentEncrypted'));
const PreRegisterUserErrors = lazy(() => import('./PreRegisterUserErrors'));
const DocsToSignNotFound = lazy(() => import('./DocsToSignNotFound'));
const InvalidAuthLink = lazy(() => import('./InvalidAuthLink'));
const UserNotFound = lazy(() => import('./UserNotFound'));
const CustomError = lazy(() => import('./CustomError'));
const CustomSuccess = lazy(() => import('./CustomSuccess'));
const SendOtp = lazy(() => import('./SentOtp'));
const EmailVerified = lazy(() => import('./EmailVerified'));
const RequireUserData = lazy(() => import('./RequireUserData'));
const GeolocationRequired = lazy(() => import('./GeolocationRequired'));
const GeolocationInformative = lazy(() => import('./GeolocationInformative'));
const ErrorOnServer = lazy(() => import('./ErrorOnServer'));
const NotAuthorized = lazy(() => import('./NotAuthorized'));
const TokenExpired = lazy(() => import('./TokenExpired'));
const FileNotFound = lazy(() => import('./FileNotFound'));
const DocAlreadySigned = lazy(() => import('./DocAlreadySigned'));
const PrivacyNotice = lazy(() => import('./PrivacyNotice'));
const ConfirmAddExtAPI = lazy(() => import('./ConfirmAddExtAPI'));
const ConfirmDeleteCron = lazy(() => import('./ConfirmDeleteCron'));
const MaxDocsWithVerifSelected = lazy(() =>
  import('./MaxDocsWithVerifSelected'),
);

export const DialogToShow = {
  confirmDeleteDocs: ConfirmDeleteDocs,
  viewFile: ViewPdfFile,
  documentEncrypted: DocumentEncrypted,
  preRegisterUserErrors: PreRegisterUserErrors,
  docsToSignNotFound: DocsToSignNotFound,
  invalidAuthLink: InvalidAuthLink,
  maxDocsWithVerifSelected: MaxDocsWithVerifSelected,
  userNotFound: UserNotFound,
  customError: CustomError,
  customSuccess: CustomSuccess,
  sentOtp: SendOtp,
  emailVerified: EmailVerified,
  requireUserData: RequireUserData,
  geolocationRequired: GeolocationRequired,
  geolocationInformative: GeolocationInformative,
  errorOnServer: ErrorOnServer,
  notAuthorized: NotAuthorized,
  tokenExpired: TokenExpired,
  fileNotFound: FileNotFound,
  privacyNotice: PrivacyNotice,
  docAlreadySigned: DocAlreadySigned,
  confirmAddExtAPI: ConfirmAddExtAPI,
  confirmDeleteCron: ConfirmDeleteCron,
};
