import { endpointHandler } from '../../endpoints';

const {
  adminExtAPIEndpoint,
  adminSingleExtAPIEndpoint,
  adminExtAPIAccessFileEndpoint,
  adminExtPermissionsEndpoint,
} = endpointHandler();

export const adminCreateExtAPITokenAPI = (data, reqConfig, urlVar) => {
  return adminExtAPIEndpoint.post(data, reqConfig, urlVar);
};

export const adminGetExtAPIDataAPI = (reqConfig, urlVar) => {
  return adminExtAPIEndpoint.get(reqConfig, urlVar);
};

export const adminPutExtApiDataAPI = (data, reqConfig, urlVar) => {
  return adminSingleExtAPIEndpoint.put(data, reqConfig, urlVar);
};

export const adminGetExtPermissionsAPI = (reqConfig, urlVar) => {
  return adminExtPermissionsEndpoint.get(reqConfig, urlVar);
};

export const adminGetExtAPIAccessFileAPI = (reqConfig, urlVar) => {
  return adminExtAPIAccessFileEndpoint.get(reqConfig, urlVar);
};
