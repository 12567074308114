import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled('form')`
  width: 100%;
  margin-top: 1rem;
`;

export const Contents = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
