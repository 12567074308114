import { useEffect } from 'react';
import { useStore } from '../Store';
import { setLanguage } from '../actions';
import { dispatchUtil } from '../utils/dispatchUtil';

const allowLanguages = ['en', 'es'];

export const useLanguage = ({ i18n } = {}) => {
  const { state } = useStore();
  const { language } = state;

  useEffect(() => {
    const language = sessionStorage.getItem('lang');
    if (allowLanguages.includes(language)) {
      dispatchUtil.dispatch(setLanguage(language));
    } else {
      dispatchUtil.dispatch(setLanguage('es'));
    }
  }, []);

  return {
    lang: language,
    translations: i18n[language] || {},
  };
};
