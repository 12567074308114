import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Typography,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import { Wrapper, styles, Form } from './style';
import { useStore } from '../../Store';
import { checkResetTokenAPI, resetPasswordAPI } from '../../API/services';
import { setDialogStateAction, setSnackState } from '../../actions';
import { useHistory } from 'react-router-dom';
import { dialogStates, snackStates } from './constants';
import { validationUtil } from '../../utils/fieldUtils';
import { notificationDispatchUtil } from '../../utils/dispatchUtil';
import EyeLine from '../_common/Icons/system/EyeLine';
import EyeCloseLine from '../_common/Icons/system/EyeCloseLine';
import { globalFeedbackUtil } from '../../utils/FeedbackUtils/globalUtils';

export const ResetPassword = (props) => {
  const history = useHistory();
  const { match } = props;
  const theme = useTheme();
  const style = styles(theme);

  const secondPswdRef = useRef();

  const [newPswd, setNewPswd] = useState('');
  const [confNewPswd, setConfNewPswd] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confPasswordVisibility, setConfPasswordVisibility] = useState(false);
  const [checkTokenCalled, setCheckTokenCalled] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(true);
  const [id, setId] = useState('');
  const {
    state: { snack },
  } = useStore();
  const { user, token } = match.params;
  // TODO refactor to FP
  const checkTokenCall = useCallback(async () => {
    if (user && token) {
      const params = {
        token,
      };
      const checkRes = await checkResetTokenAPI({ params }, { user });
      if (checkRes.status === 200) {
        setTokenVerified(true);
        setId(checkRes.data.data._id);
      } else {
        setTokenVerified(false);
        notificationDispatchUtil.dispatch(
          setDialogStateAction(
            dialogStates.resetTokenFailed(
              notificationDispatchUtil.dispatch,
              history,
            ),
          ),
        );
      }
    }
  }, [history, user, token]);

  useEffect(() => {
    if (!checkTokenCalled) {
      setCheckTokenCalled(true);
      checkTokenCall();
    }
  }, [checkTokenCall, match.params, checkTokenCalled]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    globalFeedbackUtil.setBackdropIndeterminate({
      text: 'Restableciendo contraseña...',
      clickable: false,
    });
    const isPasswordValid = validationUtil.password(newPswd);
    if (!isPasswordValid) {
      globalFeedbackUtil.closeBackdrop();
      return notificationDispatchUtil.dispatch(
        setSnackState(snackStates.invalidPassword),
      );
    }
    if (confNewPswd && newPswd !== confNewPswd) {
      globalFeedbackUtil.closeBackdrop();
      return notificationDispatchUtil.dispatch(
        setSnackState(snackStates.invalidConfirmPassword),
      );
    }
    const body = {
      password: newPswd,
      token,
    };
    const changeRes = await resetPasswordAPI(body, {}, { user: id });
    if (changeRes.status === 200) {
      notificationDispatchUtil.dispatch(
        setDialogStateAction(
          dialogStates.resetPasswordSuccess(
            notificationDispatchUtil.dispatch,
            history,
          ),
        ),
      );
    } else if (changeRes.status === 401) {
      notificationDispatchUtil.dispatch(
        setDialogStateAction(
          dialogStates.resetTokenFailed(
            notificationDispatchUtil.dispatch,
            history,
          ),
        ),
      );
    } else {
      notificationDispatchUtil.dispatch(
        setDialogStateAction(
          dialogStates.resetPasswordFailed(
            notificationDispatchUtil.dispatch,
            history,
          ),
        ),
      );
    }
    globalFeedbackUtil.closeBackdrop();
  };

  const handleVisibilityPswd1 = () => {
    setPasswordVisibility(!passwordVisibility);
  };
  const handleVisibilityPswd2 = () => {
    setConfPasswordVisibility(!confPasswordVisibility);
  };

  return (
    <Wrapper>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={style.paper}>
          <img
            src={process.env.PUBLIC_URL + '/assets/imgs/logo_sife_crop.png'}
            alt=""
            height="65px"
            width="auto"
            style={{ margin: '10px' }}
          />
          <Typography component="h1" variant="h5">
            Restablece tu contraseña
          </Typography>
          <Form style={style.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="newPswd"
                  label="Contraseña"
                  type={passwordVisibility ? 'text' : 'password'}
                  id="newPswd"
                  value={newPswd}
                  onChange={(e) => setNewPswd(e.target.value)}
                  error={snack.err === 'invalidPswd'}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleVisibilityPswd1}>
                        {passwordVisibility ? <EyeCloseLine /> : <EyeLine />}
                      </IconButton>
                    ),
                  }}
                  disabled={!tokenVerified}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confNewPswd"
                  label="Verifica tu contraseña"
                  type={confPasswordVisibility ? 'text' : 'password'}
                  id="confNewPswd"
                  value={confNewPswd}
                  onChange={(e) => setConfNewPswd(e.target.value)}
                  error={snack.err === 'pswdMismatch'}
                  inputRef={secondPswdRef}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleVisibilityPswd2}>
                        {confPasswordVisibility ? (
                          <EyeCloseLine />
                        ) : (
                          <EyeLine />
                        )}
                      </IconButton>
                    ),
                  }}
                  disabled={!tokenVerified}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={style.submit}
              disabled={!tokenVerified}
            >
              Enviar
            </Button>
          </Form>
        </div>
      </Container>
    </Wrapper>
  );
};
