import { logoutAction, setLoadingAction } from '../actions';

export const dispatchUtil = {
  isReady: false,
  dispatch: () => {
    console.error('store is NOT ready');
  },
};

export const verifyDispatchUtil = {
  isReady: false,
  dispatch: () => {
    console.error('store is NOT ready');
  },
};

export const notificationDispatchUtil = {
  isReady: false,
  dispatch: () => {
    console.error('notificationStore is NOT ready');
  },
};
export const docsDispatchUtil = {
  isReady: false,
  dispatch: () => {
    console.error('docsStore is NOT ready');
  },
};

export const logoutUtil = () => {
  dispatchUtil.dispatch(setLoadingAction(true));
  dispatchUtil.dispatch(logoutAction());
  notificationDispatchUtil.dispatch(logoutAction());
  verifyDispatchUtil.dispatch(logoutAction());
  docsDispatchUtil.dispatch(logoutAction());
};
