import React, { useRef } from 'react';
import { Cropper } from 'react-cropper';
import { DialogOptionsContents } from './style';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import { colorPalette } from '../../../../utils/colorPalette';
import { BrowserSettingsLinks } from '../../BrowserSettingsLinks';
import { SupportEmailMessage } from '../../SupportEmailMessage';
import { Picture } from '../../../Picture';
import { MUILink } from '../MUILink';
// ICONS
import TimeLineIcon from '../../Icons/system/TimeLine';
import ErrorWarningLineIcon from '../../Icons/system/ErrorWarningLine';
import FileDamageLineIcon from '../../Icons/document/FileDamageLine';
import CameraOffLineIcon from '../../Icons/media/CameraOffLine';
import QuillPenLineIcon from '../../Icons/design/QuillPenLine';
import LockPasswordLineIcon from '../../Icons/system/LockPasswordLine';
import EyeCloseLineIcon from '../../Icons/system/EyeCloseLine';
import EyeLineIcon from '../../Icons/system/EyeLine';
import MailSendLineIcon from '../../Icons/business/MailSendLine';
import CheckboxCircleLine from '../../Icons/system/CheckboxCircleLine';
import CheckboxBlankCircleLineIcon from '../../Icons/system/CheckboxBlankCircleLine';
import FileDownloadLineIcon from '../../Icons/document/FileDownloadLine';
import CheckLineIcon from '../../Icons/system/CheckLine';
import CloseLineIcon from '../../Icons/system/CloseLine';
import Message2LineIcon from '../../Icons/communication/Message2Line';
import InformationLineIcon from '../../Icons/system/InformationLine';
import { VerifyOtp } from '../../../VerifyOtp/VerifyOtp';

export const dialogOptions = ({
  password,
  visibility,
  setPassword,
  setVisibility,
  passwordRef,
  payload,
  form,
  theme,
  classes,
}) => {
  return {
    docsSignedSuccess: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
            ¡Has Firmado con éxito!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {payload.message}
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Cerrar',
        cancel: '',
      },
      img: (
        <CheckLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
          }}
        />
      ),
    }),
    cameraBlocked: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Cámara bloqueada</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Por favor busca en la configuración de tu navegador como
              restablecer los permisos de la cámara,{' '}
              <strong>de otra manera no podrás continuar.</strong>
            </DialogContentText>
          </DialogContent>
          <BrowserSettingsLinks />
          <SupportEmailMessage />
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Cerrar',
        cancel: '',
      },
      img: (
        <CameraOffLineIcon
          fill={colorPalette.red[500]}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
          }}
        />
      ),
    }),
    confirmPhoto: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Confirma tu foto</DialogTitle>
          <DialogContent>
            <Picture />
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Confirmar',
        cancel: 'Reintentar',
      },
    }),
    emailVerificationTokenExpired: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            Tu token ha expirado
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>
                El tiempo para realizar tu verificación de cuenta ha expirado
              </strong>
              . Te redireccionaremos a la pantalla de ingreso para que vuelvas a
              realizar el proceso
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Inicio',
        cancel: '',
      },
      img: (
        <TimeLineIcon
          fill={colorPalette.amber[500]}
          width={90}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
          }}
        />
      ),
    }),
    emailVerificationTokenMismatch: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Token incorrecto</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              El token de tu liga no es valido. Te redireccionaremos a la
              pantalla de ingreso para que vuelvas a realizar el proceso
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Inicio',
        cancel: '',
      },
      img: (
        <CloseLineIcon
          fill={theme.palette.error.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
          }}
        />
      ),
    }),
    resetTokenFailed: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            Tu token ha expirado
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              El tiempo para realizar tu cambio de contraseña ha expirado. Te
              redireccionaremos a la pantalla de ingreso para que vuelvas a
              realizar el proceso
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Inicio',
        cancel: '',
      },
      img: (
        <TimeLineIcon
          fill={colorPalette.amber[500]}
          width={90}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
          }}
        />
      ),
    }),
    resetPasswordSuccess: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            ¡Se ha cambiado tu contraseña exitosamente!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Da click en "Inicio" para regresar a la pantalla de ingreso, ahí
              deberás ingresar tu correo y tu nueva contraseña
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Inicio',
        cancel: '',
      },
      img: (
        <CheckboxCircleLine
          fill={colorPalette.green[400]}
          width={90}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
          }}
        />
      ),
    }),
    resetPasswordFailed: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Lo sentimos mucho</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Hubo un error con el cambio de contraseña, favor de intentar mas
              tarde
            </DialogContentText>
          </DialogContent>
          <SupportEmailMessage />
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Inicio',
        cancel: '',
      },
      img: (
        <ErrorWarningLineIcon
          fill={colorPalette.red[500]}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
            color: theme.palette.primary.main,
          }}
        />
      ),
    }),
    revokeSignature: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Revocar Firma</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Para revocar tu firma por favor introduce la contraseña que usas
              para ingresar a la plataforma.
              <br />
              <br />
              <strong>
                {' '}
                Una vez revocada, deberás crear tu firma de nuevo para poder
                continuar.
              </strong>
            </DialogContentText>
            <br />
            <TextField
              variant="outlined"
              ref={passwordRef}
              required
              fullWidth
              error={passwordRef?.current?.error}
              name="password"
              label="Contraseña"
              type={visibility ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setVisibility(!visibility)}>
                    {visibility ? <EyeLineIcon /> : <EyeCloseLineIcon />}
                  </IconButton>
                ),
              }}
            />
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Revocar',
        cancel: 'Cancelar',
      },
      img: '',
    }),
    emailOtpVerificationSent: () => {
      return {
        contents: (
          <DialogOptionsContents>
            <DialogTitle id="alert-dialog-title">
              {' '}
              ¡Verifica tu correo!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Hemos enviado un <strong>código de único uso</strong> vía correo
                electrónico a:
                <br />
                <br />
                <strong
                  style={{ textTransform: 'uppercase', color: '#673ab7' }}
                >
                  {payload?.email || 'developer@sora.mx'}
                </strong>
              </DialogContentText>
            </DialogContent>
            <VerifyOtp
              overrideSubmit={payload?.overrideSubmit || console.log}
              noLogo
              noBack
              noDesc
              noMargin
              noButton
            />
          </DialogOptionsContents>
        ),
        buttons: {
          confirm: '',
          cancel: 'Cerrar',
        },
        img: (
          <MailSendLineIcon
            fill={theme.palette.primary.main}
            width={80}
            height={90}
            style={{
              marginTop: '3vh',
              marginLeft: 'auto',
              marginRight: 'auto',
              flexShrink: 0,
              color: theme.palette.primary.main,
            }}
          />
        ),
      };
    },
    emailLinkVerificationSent: () => {
      return {
        contents: (
          <DialogOptionsContents>
            <DialogTitle id="alert-dialog-title">
              {' '}
              ¡Verifica tu correo!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Hemos enviado un <strong>link de único uso</strong> vía correo
                electrónico a:
                <br />
                <br />
                <strong
                  style={{ textTransform: 'uppercase', color: '#673ab7' }}
                >
                  {payload?.email || 'developer@sora.mx'}
                </strong>
                <br />
                <br />
                Ingresa al link que te enviamos para verificar tu correo
                <br />
                <br />
                <strong>Tendrás que verificar tu correo en 24 horas</strong>. De
                lo contrario será necesario que vuelvas a realizar el proceso.
                <br />
                <br />
                Si no recibiste el link, revisa tu carpeta de{' '}
                <strong>spam</strong>
              </DialogContentText>
            </DialogContent>
          </DialogOptionsContents>
        ),
        buttons: {
          confirm: '',
          cancel: 'Cerrar',
        },
        img: (
          <MailSendLineIcon
            fill={theme.palette.primary.main}
            width={80}
            height={90}
            style={{
              marginTop: '3vh',
              marginLeft: 'auto',
              marginRight: 'auto',
              flexShrink: 0,
              color: theme.palette.primary.main,
            }}
          />
        ),
      };
    },
    requirePhoneOtp: () => {
      return {
        contents: (
          <DialogOptionsContents>
            <DialogTitle id="alert-dialog-title">
              {' '}
              ¡Verifica tu teléfono!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Hemos enviado un <strong>código de único uso</strong> vía
                mesnaje de texto a:
                <br />
                <br />
                <strong
                  style={{ textTransform: 'uppercase', color: '#673ab7' }}
                >
                  {payload?.phone || '+528117986527'}
                </strong>
              </DialogContentText>
            </DialogContent>
            <VerifyOtp
              overrideSubmit={payload?.overrideSubmit || console.log}
              noLogo
              noSendAgain
              noBack
              noDesc
              noMargin
              noButton
            />
          </DialogOptionsContents>
        ),
        buttons: {
          confirm: '',
          cancel: 'Cerrar',
        },
        img: (
          <Message2LineIcon
            fill={theme.palette.primary.main}
            width={80}
            height={90}
            style={{
              marginTop: '3vh',
              marginLeft: 'auto',
              marginRight: 'auto',
              flexShrink: 0,
              color: theme.palette.primary.main,
            }}
          />
        ),
      };
    },
    noNetworkConnection: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            Problemas de conexión
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Detectamos un problema con tu <strong>conexión a internet</strong>
              , por favor verifica que tu conexión esté <strong>activa</strong>
            </DialogContentText>
          </DialogContent>
          <SupportEmailMessage />
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Continuar',
        cancel: '',
      },
      img: (
        <ErrorWarningLineIcon
          fill={colorPalette.red[500]}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
            color: theme.palette.primary.main,
          }}
        />
      ),
    }),
    finishRedirect: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            {' '}
            Te redireccionaremos de vuelta a tu portal.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong style={{ textTransform: 'uppercase', color: '#673ab7' }}>
                {payload?.redirect?.split('//')[1]}
              </strong>
              <br />
              <br />
              <strong>
                {' '}
                ¡No olvides revisar si tienes documentos por firmar!
              </strong>
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Finalizar',
        cancel: '',
      },
      img: (
        <CheckLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
          }}
        />
      ),
    }),
    phoneTriesExceeded: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            {' '}
            ¿Cambiaste de telefono?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong style={{ textTransform: 'uppercase', color: '#673ab7' }}>
                {payload?.redirect?.split('//')[1]}
              </strong>
              <br />
              <br />
              <strong>
                {' '}
                Si cambiaste de telefono por favor escribenos{' '}
                {/*TODO: Hueco de seguridad y experiencia de usuario*/}
              </strong>
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Finalizar',
        cancel: '',
      },
      img: (
        <CheckLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
          }}
        />
      ),
    }),
    filesUploadSuccess: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">¡Carga exitosa!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Se ha cargado exitosamente tus documentos{' '}
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Ir a firmar',
        cancel: 'Cerrar',
      },
      img: (
        <CheckboxCircleLine
          fill={colorPalette.green[400]}
          width={90}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
          }}
        />
      ),
    }),
    encryptedFileError: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Documento inválido</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Detectamos un problema con el documento <br />
              <br />
              <strong style={{ fontSize: '1.2rem' }}>
                {payload.fileName}{' '}
              </strong>
              <br />
              <br />
              Es posible que el documento esté <strong>encriptado</strong>
              <br /> Por favor verifica que tu documento esté{' '}
              <strong>libre de hacer modificaciones</strong>
            </DialogContentText>
          </DialogContent>
          <SupportEmailMessage />
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Continuar',
        cancel: '',
      },
      img: (
        <FileDamageLineIcon
          fill={colorPalette.red[500]}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
          }}
        />
      ),
    }),
    signDocuments: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            Firma tus documentos
          </DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <DialogContentText id="alert-dialog-description">
              {' '}
              Introduce tu NIP para firmar tus documentos.
              <br />
              <br />
              <span
                onClick={() => {
                  form.resetForm();
                  payload?.revokeCB();
                }}
              >
                <MUILink decoration to="/home/wallet#simple">
                  Si olvidaste tu NIP da click aquí para revocar tu firma
                </MUILink>
              </span>
              <br /> <br />{' '}
            </DialogContentText>
            <Typography>Documentos a firmar</Typography>
            <Typography variant="h5">{payload?.docsNum}</Typography>
            <br /> <br />{' '}
            <TextField
              autoFocus
              required
              variant="outlined"
              id="private-code"
              sx={{ '& input': { paddingLeft: theme.spacing(2) } }}
              name="private-code"
              label="NIP de firma electrónica"
              type={visibility ? 'text' : 'password'}
              helperText={form.errors.privateCode}
              value={form.data?.privateCode || ''}
              onChange={(e) =>
                form.handleChange({
                  name: 'privateCode',
                  value: e.target.value,
                })
              }
              error={
                form.touched.privateCode && Boolean(form.errors.privateCode)
              }
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setVisibility(!visibility);
                    }}
                  >
                    {visibility ? (
                      <EyeLineIcon fill={theme.palette.text.secondary} />
                    ) : (
                      <EyeCloseLineIcon fill={theme.palette.text.secondary} />
                    )}
                  </IconButton>
                ),
                startAdornment: (
                  <LockPasswordLineIcon
                    width={34}
                    fill={theme.palette.text.secondary}
                  />
                ),
              }}
            />
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Firmar',
        cancel: 'Cerrar',
      },
      img: (
        <QuillPenLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
            color: theme.palette.primary.main,
          }}
        />
      ),
    }),
    confirmEditExtAPI: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Confirmación de API</DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <DialogContentText id="alert-dialog-description">
              {' '}
              ¿Estás seguro de estos datos?
            </DialogContentText>
            <br />
            {Object.keys(payload.apiData).map((key) => (
              <Typography key={key} variant="body1">
                {key}: <b>{payload.apiData[key]}</b>
              </Typography>
            ))}
            {/* <Typography variant="h6">{payload.origin}</Typography> */}
            <br />
            <DialogContentText id="alert-dialog-description">
              {' '}
              ¿Con estos permisos?
            </DialogContentText>
            <List>
              {payload.permissions.map((permission) => (
                <ListItem key={permission.title} role="listitem">
                  <ListItemIcon>
                    {permission.hasAccess ? (
                      <CheckboxCircleLine fill={theme.palette.primary.main} />
                    ) : (
                      <CheckboxBlankCircleLineIcon
                        fill={theme.palette.text.secondary}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color:
                        !permission.hasAccess && theme.palette.text.secondary,
                    }}
                    primary={permission.title}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Confirmar',
        cancel: 'Cerrar',
      },
      img: (
        <QuillPenLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
            color: theme.palette.primary.main,
          }}
        />
      ),
    }),
    downloadExtAPIFile: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            ¿Deseas descargar el archivo token?
          </DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">{payload?.fileName}</Typography>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Descargar',
        cancel: 'Cerrar',
      },
      img: (
        <FileDownloadLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
            color: theme.palette.primary.main,
          }}
        />
      ),
    }),
    confirmEditCron: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">Cronjob edition</DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <DialogContentText id="alert-dialog-description">
              {' '}
              ¿Are your sure of this changes?
            </DialogContentText>
            <br />
            <Typography variant="subtitle1">
              Current name: <strong>{payload.currentName}</strong>
            </Typography>
            <Typography variant="subtitle1">
              Current time: <strong>{payload.currentHumanCronExp}</strong>
            </Typography>
            <br />
            {Boolean(payload.newCronData?.humanCronExp) && (
              <div style={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" color="secondary">
                  New running time
                </Typography>
                <Typography variant="h6">
                  <strong>{payload.newCronData?.humanCronExp}</strong>
                </Typography>
              </div>
            )}
            <br />
            <Typography variant="h6">Changes details</Typography>
            <List>
              {Object.entries(payload.newCronData)
                .filter((entry) => entry[0] !== 'humanCronExp')
                .map(([key, value]) => {
                  return (
                    <ListItem key={key} role="listitem">
                      <ListItemText
                        primary={
                          <Typography variant="body1">
                            {key} = <strong>{`${value}`}</strong>
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
            </List>
            <br />
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Confirm',
        cancel: 'Close',
      },
      img: (
        <QuillPenLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '6rem',
            color: theme.palette.primary.main,
          }}
        />
      ),
    }),
    CropProfilePicture: () => {
      const cropperRef = useRef(null);
      return {
        contents: (
          <DialogOptionsContents>
            <DialogTitle id="alert-dialog-title">
              Recorta tu imagen de perfil
            </DialogTitle>
            <DialogContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0.5}
                initialAspectRatio={1}
                // preview=".img-preview"
                src={
                  'https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg'
                }
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                ref={cropperRef}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  payload.setCropper(instance);
                }}
                guides={true}
              />
            </DialogContent>
          </DialogOptionsContents>
        ),
        buttons: {
          confirm: 'Cambiar',
          cancel: 'Cerrar',
        },
        img: '',
      };
    },
    maxDocsSelected: () => ({
      contents: (
        <DialogOptionsContents>
          <DialogTitle id="alert-dialog-title">
            Número máximo de documentos seleccionados
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Si deseas seleccionar más elementos cambia el número de{' '}
              <strong>documentos por página</strong>. Esta opción se encuentra
              en la parte inferior de la tabla.
              <br />
              <br />
            </DialogContentText>
          </DialogContent>
        </DialogOptionsContents>
      ),
      buttons: {
        confirm: 'Entendido',
        cancel: '',
      },
      img: (
        <InformationLineIcon
          fill={theme.palette.primary.main}
          width={80}
          height={90}
          style={{
            marginTop: '3vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexShrink: 0,
            color: theme.palette.primary.main,
          }}
        />
      ),
    }),
  };
};
