import { createContext, useContext, useReducer, useEffect } from 'react';
import docsReducer, { initialState } from './docsReducer';
import { docsDispatchUtil } from '../utils/dispatchUtil';

const DocsContext = createContext();

const DocsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(docsReducer, initialState);

  useEffect(() => {
    if (!docsDispatchUtil.isReady) {
      docsDispatchUtil.isReady = true;
      docsDispatchUtil.dispatch = (params) => dispatch(params);
      Object.freeze(docsDispatchUtil);
    }
  }, []);

  return (
    <DocsContext.Provider value={[state, dispatch]}>
      {children}
    </DocsContext.Provider>
  );
};

const useDocsStore = () => useContext(DocsContext)[0];
const useDocsDispatch = () => useContext(DocsContext)[1];

export { DocsContext, useDocsStore, useDocsDispatch };
export default DocsProvider;
