import { useEffect } from 'react';
import { trustedOrigins } from '../API/instance/createInstance';
import { inIframe } from '../utils/windowUtils';
import { dialogUtil } from '../utils/dialogUtils';
import { useUserData } from './users';

export const usePrivacyNotice = () => {
  const { user, isLoading } = useUserData();
  const parentWindowLocation = sessionStorage.getItem('parentWindowLocation');

  useEffect(() => {
    if (user && !isLoading) {
      const privacyNoticeAccepted = user?.privacyNotice?.accepted;
      if (privacyNoticeAccepted) return;

      const isOpenFromIframe = inIframe();
      if (!isOpenFromIframe) return dialogUtil.privacyNotice();

      const isOpenFromSoraDomain =
        trustedOrigins.includes(parentWindowLocation);
      if (!isOpenFromSoraDomain) return dialogUtil.privacyNotice();
    }
  }, [user, isLoading, parentWindowLocation]);
};
