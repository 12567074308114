import { useEffect, useMemo, useRef, useState } from 'react';

export const useAsync = (
  asyncFunc,
  { initialParams = {}, immediate = true, defaultData = null } = {},
) => {
  const [loading, setLoading] = useState(immediate);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(null);
  const mountedRef = useRef(true);

  const execute = useMemo(
    () => (params) => {
      setLoading(true);
      return asyncFunc({
        ...initialParams,
        ...params,
      })
        .then((res) => {
          if (!mountedRef.current) return null;
          setData(res);
          setError(null);
          setLoading(false);
          return res;
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          setError(err);
          setLoading(false);
          throw err;
        });
    },
    // eslint-disable-next-line
    [asyncFunc, setData, setError, setLoading],
  );

  useEffect(() => {
    if (immediate) {
      execute(initialParams);
    }
    // eslint-disable-next-line
  }, [immediate, execute]);

  return {
    execute,
    loading,
    data,
    error,
  };
};
