import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute, Loading } from '../_common';
import { useStore } from '../../Store';
import { MUIDialog, MUINewSnackbar } from '../_common/MUICustom';
import { SendRecovery } from '../SendRecovery';
import { ResetPassword } from '../ResetPassword';
import { BackdropFeedback } from '../_common/MUICustom/BackdropFeedback/BackdropFeedback';
import { setMessageReceiver } from '../../utils/windowUtils';
import PhoneVerification from '../PhoneVerification';
import { useQuery } from '../../Hooks';

const VerifyOtp = React.lazy(() => import('../VerifyOtp'));
const NormalLogin = React.lazy(() => import('../Login/NormalLogin'));
const ExtLogin = React.lazy(() => import('../Login/ExtLogin'));
const NormalSignup = React.lazy(() => import('../Signup/NormalSignup'));
const ExtSignup = React.lazy(() => import('../Signup/ExtSignup'));
const EndSignup = React.lazy(() => import('../Signup/EndSignup'));
const DocumentStatus = React.lazy(() => import('../DocumentStatus'));
const EmailVerification = React.lazy(() => import('../EmailVerification'));
const AutoLogin = React.lazy(() => import('../Login/AutoLogin'));
const Home = React.lazy(() => import('../Home'));

if (window.opener || window.self !== window.top) {
  setMessageReceiver();
}
const MainRouter = () => {
  const query = useQuery();
  const lang = query.get('lang');
  if (lang) sessionStorage.setItem('lang', lang);

  const {
    state: { loading },
  } = useStore();

  return (
    <React.Fragment>
      {Boolean(loading) && <Loading />}
      <MUIDialog />
      <BackdropFeedback />
      <MUINewSnackbar />
      {!loading && (
        <Switch>
          <Redirect exact from="/" to="/home/summary" />
          <Route path="/signup">
            <Suspense fallback={<Loading />}>
              <NormalSignup />
            </Suspense>
          </Route>
          <Route path="/ext-signup">
            <Suspense fallback={<Loading />}>
              <ExtSignup />
            </Suspense>
          </Route>
          <Route path="/end-signup">
            <Suspense fallback={<Loading />}>
              <EndSignup />
            </Suspense>
          </Route>
          <Route path="/login">
            <Suspense fallback={<Loading />}>
              <NormalLogin />
            </Suspense>
          </Route>
          <Route path="/auto-login">
            <Suspense fallback={<Loading />}>
              <AutoLogin />
            </Suspense>
          </Route>
          <Route path="/ext-login">
            <Suspense fallback={<Loading />}>
              <ExtLogin />
            </Suspense>
          </Route>
          <Route path="/recovery" component={SendRecovery} />
          <Route exact path="/reset/:user/:token" component={ResetPassword} />
          <Route exact path="/verify-email/:user/:token">
            <Suspense fallback={<Loading />}>
              <EmailVerification />
            </Suspense>
          </Route>
          <Route exact path="/verify-phone/:user">
            <Suspense fallback={<Loading />}>
              <PhoneVerification />
            </Suspense>
          </Route>
          <ProtectedRoute path="/verify-otp">
            <Suspense fallback={<Loading />}>
              <VerifyOtp />
            </Suspense>
          </ProtectedRoute>
          <ProtectedRoute path="/home">
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          </ProtectedRoute>
          <Route path="/document-status/:docId">
            <Suspense fallback={<Loading />}>
              <DocumentStatus />
            </Suspense>
          </Route>
        </Switch>
      )}
    </React.Fragment>
  );
};

export const InternalRouter = () => {
  return (
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  );
};
