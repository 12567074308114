import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { useController } from 'react-hook-form';

export const DateInputControlled = ({
  control,
  name: nameProp,
  rules,
  muiProps,
  errorMapper = (error) => {
    return {
      pattern: 'Campo inválido',
      required: 'Campo requerido',
    }[error.type];
  },
  disableOnDefaultValue = false,
}) => {
  const { field, fieldState } = useController({
    name: nameProp,
    control,
    rules,
  });
  const { invalid, isDirty, error } = fieldState;
  const { onChange, onBlur, value, ref } = field;

  const [disableOnDefaultState, setDisableOnDefaultState] = useState(false);

  useEffect(() => {
    if (disableOnDefaultValue && value && !isDirty) {
      setDisableOnDefaultState(true);
    }
  }, [disableOnDefaultValue, value, isDirty]);

  return (
    <DatePicker
      label={muiProps?.label}
      format="DD-MM-YYYY"
      disableFuture
      value={dayjs(value)}
      slotProps={{
        textField: {
          inputRef: ref,
          size: muiProps?.size,
          fullWidth: muiProps?.fullWidth,
          placeholder: muiProps?.placeholder || 'DD-MM-AAAA',
          disabled: muiProps?.disabled || disableOnDefaultState,
          error: invalid,
          helperText: invalid ? errorMapper({ error }) : '',
          onChange: (date) => onChange(date),
          onBlur: (date) => onBlur(date),
        },
      }}
      disabled={muiProps?.disabled || disableOnDefaultState}
      onChange={(date) => onChange(date)}
    />
  );
};
