export const es = {
  titles: {
    title1: `Introduce tu código`,
  },
  buttons: {
    button1: `Verificar`,
    button2: `Enviar de nuevo`,
  },
  texts: {
    text1: `Un código de único uso se ha enviado a tu correo por propósitos de verificación`,
    text2: `¿No recibiste tu código?`,
    text3: `Verificando OTP...`,
    text4: `Enviando OTP...`,
  },
};
