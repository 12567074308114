import { endpointHandler } from '../../endpoints';
const {
  usersEndpoint,
  preRegisterUsersEndpoint,
  loginUserEndpoint,
  sendRecoveryEndpoint,
  checkResetTokenEndpoint,
  resetPasswordEndpoint,
  resetPasswordInternalEndpoint,
  userDataEndpoint,
  userMetadataEndpoint,
  extCompleteUserDataEndpoint,
  extSendOtpEndpoint,
  extVerifyOtpEndpoint,
  emailVerificationEndpoint,
  phoneVerificationEndpoint,
  profilePictureEndpoint,
  sendEmailVerificationEndpoint,
  sendPhoneVerificationEndpoint,
  verifyContactMethodEndpoint,
  endUserRegistrationEndpoint,
  sendVerificationCodeEndpoint,
  getContactMethodsEndpoint,
} = endpointHandler();

export const registerUserAPI = (data, reqConfig, urlVar) => {
  return usersEndpoint.post(data, reqConfig, urlVar);
};

export const preRegisterUsersAPI = (data, reqConfig, urlVar) => {
  return preRegisterUsersEndpoint.post(data, reqConfig, urlVar);
};

export const loginUserAPI = (data, reqConfig, urlVar) => {
  return loginUserEndpoint.post(data, reqConfig, urlVar);
};

export const sendRecoveryAPI = (data, reqConfig, urlVar) => {
  return sendRecoveryEndpoint.post(data, reqConfig, urlVar);
};

export const checkResetTokenAPI = (reqConfig, urlVar) => {
  return checkResetTokenEndpoint.get(reqConfig, urlVar);
};

export const resetPasswordAPI = (data, reqConfig, urlVar) => {
  return resetPasswordEndpoint.post(data, reqConfig, urlVar);
};

export const resetPasswordInternalAPI = (data, reqConfig, urlVar) => {
  return resetPasswordInternalEndpoint.post(data, reqConfig, urlVar);
};

export const userDataAPI = (reqConfig, urlVar) => {
  return userDataEndpoint.get(reqConfig, urlVar);
};

export const putUserDataAPI = (data, reqConfig, urlVar) => {
  return userDataEndpoint.put(data, reqConfig, urlVar);
};

export const postUserMetaAPI = (data, reqConfig, urlVar) => {
  return userMetadataEndpoint.post(data, reqConfig, urlVar);
};

export const extCompleteUserDataAPI = (data, reqConfig, urlVar) => {
  return extCompleteUserDataEndpoint.put(data, reqConfig, urlVar);
};

export const extSendOtpAPI = (data, reqConfig, urlVar) => {
  return extSendOtpEndpoint.post(data, reqConfig, urlVar);
};

export const extVerifyOtpAPI = (data, reqConfig, urlVar) => {
  return extVerifyOtpEndpoint.post(data, reqConfig, urlVar);
};

export const postProfilePictureAPI = (data, reqConfig, urlVar) => {
  return profilePictureEndpoint.post(data, reqConfig, urlVar);
};

export const deleteProfilePictureAPI = (reqConfig, urlVar) => {
  return profilePictureEndpoint.delete(reqConfig, urlVar);
};

export const emailVerificationAPI = (data, reqConfig, urlVar) => {
  return emailVerificationEndpoint.post(data, reqConfig, urlVar);
};

export const phoneVerificationAPI = (data, reqConfig, urlVar) => {
  return phoneVerificationEndpoint.post(data, reqConfig, urlVar);
};

export const sendEmailVerificationAPI = (data, reqConfig, urlVar) => {
  return sendEmailVerificationEndpoint.post(data, reqConfig, urlVar);
};

export const sendPhoneVerificationAPI = (data, reqConfig, urlVar) => {
  return sendPhoneVerificationEndpoint.post(data, reqConfig, urlVar);
};

export const verifyContactMethodAPI = (data, reqConfig, urlVar) => {
  return verifyContactMethodEndpoint.post(data, reqConfig, urlVar);
};

export const endUserRegistrationAPI = (data, reqConfig, urlVar) => {
  return endUserRegistrationEndpoint.post(data, reqConfig, urlVar);
};

export const sendVerificationCodeAPI = (data, reqConfig, urlVar) => {
  return sendVerificationCodeEndpoint.post(data, reqConfig, urlVar);
};

export const getContactMethodsAPI = (reqConfig, urlVar) => {
  return getContactMethodsEndpoint.get(reqConfig, urlVar);
};
