import axios from 'axios';
import { initSentry, sifeConfig } from '../configs';
// import { getInternalIp } from '../configs/sifeConfig';
import { intercpetorHandler } from './interceptors';

const origin = window.location.origin;
const localIp = process.env.REACT_APP_LOCAL_IP;
let soraClientHostname = 'localhost';
const soraClientPort = '4002';
let scClientHostname = 'localhost';
const scClientPort = '3002';

if (localIp) {
  soraClientHostname = localIp;
  scClientHostname = localIp;
}
let cstmEnv = 'local';
let scOrign = `http://${soraClientHostname}:${soraClientPort}`;
let soraOrigin = `http://${scClientHostname}:${scClientPort}`;
if (origin.includes('staging')) {
  cstmEnv = 'staging';
  scOrign = 'https://staging.contratos.sora.mx';
  soraOrigin = 'https://staging.app.sora.mx';
} else if (origin.includes('dev')) {
  cstmEnv = 'dev';
  scOrign = 'https://dev.contratos.sora.mx';
  soraOrigin = 'https://dev.app.sora.mx';
  if (window && window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = () => {};
  }
} else if (origin.includes('://sife')) {
  cstmEnv = 'prod';
  scOrign = 'https://contratos.sora.mx';
  soraOrigin = 'https://app.sora.mx';
  if (window && window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = () => {};
  }
}

export const trustedOrigins = [scOrign, soraOrigin];
export const environment = cstmEnv;
const getApiClient = ({ protocol, hostname, port, api, version }) => {
  // const ip = await getInternalIp();
  if (environment !== 'prod' && environment !== 'dev' && localIp) {
    hostname = localIp;
  }
  const portString = port ? `:${port}` : '';
  const apiString = api ? `/${api}` : '';
  const versionString = version ? `/${version}` : '';
  const axiosConfig = {
    baseURL: `${protocol}://${hostname}${portString}${apiString}${versionString}`,
    responseType: 'json',
    validateStatus: () => {
      return true;
    },
  };
  environment !== 'prod' && console.log('Pointed API: ', axiosConfig);
  return axios.create(axiosConfig);
};

// Log build version
if (environment !== 'prod') {
  console.log(
    'APP VERSION:',
    document.head.querySelector('meta[name="build-version"]')?.content ||
      'No v meta tag found',
  );
}

// Sentry init only on dev and prod
if (environment !== 'local') {
  initSentry(environment);
}

// Initialize Google Analytics
if (environment !== 'local' && environment !== 'staging') {
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-135634858-2';
  script.async = true;
  document.head.appendChild(script);
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'UA-135634858-2');
}

//instances
// export const sifeInstance = getApiClient(sifeConfig(environment)).then(
//   (config) => intercpetorHandler(sifeInstance),
// );
export const sifeInstance = getApiClient(sifeConfig(environment));
intercpetorHandler(sifeInstance);
