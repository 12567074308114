import {
  curpValidator,
  fileNameValidator,
  fileNameValidCharsRegex,
  namesValidator,
  privateCodeValidator,
  pswdValidator,
  validateEmail,
  validatePhone,
  validateRFC,
} from './validators';

const sanitizeHelper = (name) => {
  const firstLetter = name.charAt(0).toUpperCase();
  const rest = name.slice(1).toLowerCase();
  return firstLetter + rest;
};

export const sanitationUtil = {
  names: (string) => {
    const splitNames = string.split(' ');
    if (splitNames.length === 1) {
      return sanitizeHelper(string);
    }
    return splitNames.map(sanitizeHelper).join(' ');
  },
  name: (string) => {
    const splitNames = string.split(' ');
    if (splitNames.length === 1) {
      return sanitizeHelper(string);
    }
    return splitNames.map(sanitizeHelper).join(' ');
  },
  lastNameP: (string) => {
    const splitNames = string.split(' ');
    if (splitNames.length === 1) {
      return sanitizeHelper(string);
    }
    return splitNames.map(sanitizeHelper).join(' ');
  },
  lastNameM: (string) => {
    const splitNames = string.split(' ');
    if (splitNames.length === 1) {
      return sanitizeHelper(string);
    }
    return splitNames.map(sanitizeHelper).join(' ');
  },
  phone: (string) => string,
  email: (string) => string.split(' ').join('').toLowerCase(),
  rfc: (string) => string.split(' ').join('').toUpperCase(),
  curp: (string) => string.split(' ').join('').toUpperCase(),
  password: (string) => string,
  privateCode: (string) => string,
  fileName: (string) => string.match(fileNameValidCharsRegex).join(''),
};

export const validationUtil = {
  names: (string) => namesValidator(string),
  phone: (string) => validatePhone(string),
  email: (string) => validateEmail(string),
  rfc: (string) => validateRFC(string),
  curp: (string) => curpValidator(string),
  password: (string) => pswdValidator(string),
  privateCode: (string) => privateCodeValidator(string),
  fileName: (string) => fileNameValidator(string),
  date: (date, { disableFuture }) => {
    if (typeof date === 'object' && date?.toString() === 'Invalid Date') {
      return false;
    }
    if (disableFuture) {
      return new Date(date) < new Date();
    }
    return true;
  },
};
