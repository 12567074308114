import React from 'react';

export default function CameraOffLine(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="remix-icons/outlined/media/camera-off-line">
        <g id="Group">
          <path
            id="Vector"
            d="M19.586 21H3C2.73479 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V6.00001C2 5.73479 2.10536 5.48043 2.29289 5.2929C2.48043 5.10536 2.73479 5.00001 3 5.00001H3.586L1.393 2.80801L2.808 1.39301L22.607 21.193L21.192 22.607L19.586 21ZM5.586 7.00001H4V19H17.586L15.406 16.82C14.3486 17.6569 13.0205 18.0762 11.6742 17.9981C10.3279 17.92 9.05719 17.35 8.10362 16.3964C7.15004 15.4428 6.58003 14.1721 6.50193 12.8258C6.42383 11.4795 6.84308 10.1515 7.68 9.09401L5.586 7.00001ZM9.11 10.525C8.64898 11.1988 8.43799 12.0125 8.51352 12.8253C8.58906 13.6382 8.94637 14.3991 9.52364 14.9764C10.1009 15.5536 10.8618 15.9109 11.6747 15.9865C12.4875 16.062 13.3013 15.851 13.975 15.39L9.11 10.525ZM22 17.785L20 15.785V7.00001H16.172L14.172 5.00001H9.828L9.521 5.30701L8.107 3.89301L9 3.00001H15L17 5.00001H21C21.2652 5.00001 21.5196 5.10536 21.7071 5.2929C21.8946 5.48043 22 5.73479 22 6.00001V17.786V17.785ZM11.263 7.05001C12.1024 6.9364 12.9566 7.01819 13.7592 7.28899C14.5617 7.55979 15.2909 8.0123 15.8898 8.61122C16.4887 9.21014 16.9412 9.93931 17.212 10.7419C17.4828 11.5444 17.5646 12.3987 17.451 13.238L15.113 10.9C14.7783 10.2512 14.2498 9.72276 13.601 9.388L11.263 7.05001Z"
            fill={props.fill ? props.fill : 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
}
