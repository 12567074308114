import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { useLanguage } from '../../../Hooks';
import { i18n } from './i18n';

export const BrowserSettingsLinks = () => {
  const { translations, lang } = useLanguage({ i18n });

  return (
    <Stack spacing={4} mb={4}>
      <Stack spacing={1} sx={{ textAlign: 'center' }}>
        <Typography variant="body1" fontWeight="bold">
          {translations?.texts?.text2}
        </Typography>
      </Stack>
      <Stack flexDirection="row" gap={6} justifyContent="center">
        <Link
          target="_blank"
          href="https://support.apple.com/guide/mac-help/allow-apps-to-detect-the-location-of-your-mac-mh35873/mac"
        >
          Safari
        </Link>
        <Link
          target="_blank"
          href={
            lang === 'es'
              ? 'https://support.google.com/chrome/answer/114662?hl=es-419&co=GENIE.Platform%3DDesktop'
              : 'https://support.google.com/chrome/answer/114662?hl=en-419&co=GENIE.Platform%3DDesktop'
          }
        >
          Chrome
        </Link>
        <Link
          target="_blank"
          href={
            lang === 'es'
              ? 'https://support.mozilla.org/es/kb/opciones-preferencias-y-configuracion-de-firefox'
              : 'https://support.mozilla.org/bm/kb/firefox-options-preferences-and-settings'
          }
        >
          Firefox
        </Link>
      </Stack>
    </Stack>
  );
};
