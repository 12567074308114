import { setSnackState } from '../../actions';
import { notificationDispatchUtil } from '../dispatchUtil';

export const snackErrorsUtil = {
  openerFailure: ({ changerFunc, lang } = {}) => {
    const i18n = {
      es: `Error en plataforma externa`,
      en: `Error on external platform`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        duration: 3000,
        changerFunc,
      }),
    );
  },
  devErrorMessage: (errMsg) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: errMsg,
      }),
    ),
  otpExpired: ({ lang } = {}) => {
    const i18n = {
      es: `Tu código ha expirado`,
      en: `Your code has expired`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  invalidOtp: ({ lang } = {}) => {
    const i18n = {
      es: `Tu código es inválido, intenta de nuevo`,
      en: `Your code is invalid, please try again`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  invalidEmail: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Tu correo no tiene un formato correcto, verificalo por favor',
        err: 'noUser',
      }),
    ),
  invalidRFC: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Tu RFC no tiene un formato correcto, verificalo por favor',
        err: 'conflict',
      }),
    ),
  invalidCURP: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Tu CURP no tiene un formato correcto, verificalo por favor',
        err: 'conflict',
      }),
    ),
  invalidPhone: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'El teléfono proporcionado no tiene un formato correcto, verificalo por favor',
        err: 'conflict',
      }),
    ),
  invalidDate: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'La fecha proporcionada no tiene un formato correcto, verificala por favor',
        err: 'conflict',
      }),
    ),
  invalidCredentials: ({ lang } = {}) => {
    const i18n = {
      es: `Credenciales inválidas, verifica que tus credenciales sean correctas`,
      en: `Invalid credentials, check that your credentials are correct`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        err: 'invalidCreds',
      }),
    );
  },
  registerEmailConflict: ({ lang } = {}) => {
    const i18n = {
      es: `El correo proporcionado ya está registrado, por favor procede a iniciar sesión`,
      en: `Email provided is already registered, please proceed to login`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        err: 'conflict',
      }),
    );
  },
  registerRfcConflict: ({ lang } = {}) => {
    const i18n = {
      es: `El RFC proporcionado ya está registrado, por favor procede a iniciar sesión`,
      en: `RFC provided is already registered, please proceed to login`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        err: 'conflict',
      }),
    );
  },
  emailConflict: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Este correo ya está registrado',
        err: 'conflict',
      }),
    ),
  rfcConflict: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Este RFC ya está registrado',
        err: 'conflict',
      }),
    ),
  curpConflict: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Este CURP ya se encuentra registrado',
        err: 'conflict',
      }),
    ),
  signupError: ({ lang } = {}) => {
    const i18n = {
      es: `Problema al realizar el registro, por favor contacte a soporte`,
      en: `Problem to sign up, please contact support`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        err: 'internal',
      }),
    );
  },
  userRegistered: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'El usuario ya se encuentra registrado',
        err: 'conflict',
      }),
    ),
  loginError: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Problema al realizar el ingreso, por favor contacte a soporte',
        err: 'internal',
      }),
    ),
  updateUserError: ({ lang } = {}) => {
    const i18n = {
      es: `Problema al realizar la actualización de datos, por favor contacte a soporte`,
      en: `Problem when updating data, please contact support`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        err: 'internal',
      }),
    );
  },
  nonExistentUser: ({ lang } = {}) => {
    const i18n = {
      es: `Este usuario no existe, da click en el link de registro`,
      en: `This user does not exist, click on the registration link`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        err: 'noUser',
      }),
    );
  },
  userDoesNotExist: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No encontramos registros de este usuario con los datos proporcionados',
        err: 'noUser',
      }),
    ),
  incompleteReg: ({ lang } = {}) => {
    const i18n = {
      es: `¡Falta completar tu registro! procede al link debajo de ingreso`,
      en: `You need to complete your registration! Proceed to the link below login`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        err: 'noUser',
      }),
    );
  },
  incorrectPassword: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Tu contraseña es incorrecta, verifica tu contraseña y vuelve a intentar',
      }),
    ),
  invalidPassword: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Contraseña invalida',
      }),
    ),
  invalidNewPassword: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Por tu seguridad, la contraseña debe ser de al menos 8 caracteres',
      }),
    ),
  invalidConfirmPassword: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Las contraseñas deben ser iguales.',
        err: 'pswdMismatch',
      }),
    ),
  incorrectPrivateCode: ({ lang } = {}) => {
    const i18n = {
      es: `NIP Incorrecto`,
      en: `Incorrect PIN`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  docsSigningNotFound: ({ lang } = {}) => {
    const i18n = {
      es: `No encontramos tu documento(s)`,
      en: `We can't find your document(s)`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'error',
      }),
    );
  },
  docsSigningVerifNotValid: ({ lang } = {}) => {
    const i18n = {
      es: `Algunos de los documentos a firmar necesitan de una verificación de identidad`,
      en: `Some of the documents to sign require an identity verification`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'error',
      }),
    );
  },
  docsSigningError: ({ lang } = {}) => {
    const i18n = {
      es: `Error al firmar documentos`,
      en: `Error signing documents`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'error',
      }),
    );
  },
  docsSigningOutService: ({ lang } = {}) => {
    const i18n = {
      es: `Firma de documentos fuera de servicio, por favor intenta más tarde`,
      en: `Document signing out of service, please try again later`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'error',
      }),
    );
  },
  docUpdateError: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Hubo un error al tratar de actualizar tu documento',
      }),
    ),
  userNotVerified: (history) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No te has verificado aún, procede a verificarte.',
        changerFunc: () => {
          history.push('/');
        },
      }),
    ),
  originNotValid: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: `Origin debe ser una url valida`,
        severity: 'error',
      }),
    ),
  pictureModerationFailed: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Tu imagen fue marcada como inapropiada, por favor intenta con otra',
      }),
    ),
  noCronsFound: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No hay crons registrados',
      }),
    ),
  cronFunctionNotFound: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Specified cron function not found',
      }),
    ),
  callError: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Hubo un error, contacta a memo',
      }),
    ),
  errorOnServer: ({ lang } = {}) => {
    const i18n = {
      es: `Ocurrió un problema en nuestro servicio, favor de intentar más tarde.`,
      en: `There was a problem with our service, please try again later.`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  efirmaCertExpired: ({ lang } = {}) => {
    const i18n = {
      es: `El certificado de la e.firma ha experido`,
      en: `The e.firma certificate has expired`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  efirmaCertRevoked: ({ lang } = {}) => {
    const i18n = {
      es: `El certificado de la e.firma se encuentra revocado`,
      en: `The e.firma certificate is revoked`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  certUnsupported: ({ lang } = {}) => {
    const i18n = {
      es: `El certificado que esta usando no esta soportado`,
      en: `The certificate you are using is not supported`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  wrongPasswordEfirma: ({ lang } = {}) => {
    const i18n = {
      es: `Contraseña de la e.firma invalida`,
      en: `Invalid e.firma password`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  wrongKeysMatchingEfirma: ({ lang } = {}) => {
    const i18n = {
      es: `Los archivos .cer y .key no pertencen a la misma e.firma`,
      en: `The .cer and .key files do not belong to the same e.firma`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
      }),
    );
  },
  docNotFound: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No se encontro el documento',
      }),
    ),
  docAccessDenied: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No estás autorizado a acceder a este documento',
      }),
    ),
  docDeleteCompletelySigned: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'El documento no puede eliminarse porque ya se encuentra completamente firmado',
      }),
    ),
  docsNotFound: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No se encontraron documentos',
      }),
    ),
  userNotFound: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No se encontro información del usuario',
      }),
    ),
  noDocumentUploaded: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Hubo un problema al subir tu documento',
      }),
    ),
};
