import React from 'react';
import { useTheme, Link as MaterialLink } from '@mui/material';
import { Link } from 'react-router-dom';

export const MUILink = (props) => {
  const { children, to, decoration, color } = props;
  const theme = useTheme();
  return (
    <MaterialLink
      to={to}
      component={Link}
      style={{
        color: color || theme.palette.primary.main,
        textDecoration: decoration || 'none',
      }}
    >
      {children}
    </MaterialLink>
  );
};
