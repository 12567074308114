import React, {
  useReducer,
  useContext,
  createContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { globalState } from './globalState';
import mainReducer from './mainReducer';
import { setUserAction, setDeviceInfo } from './actions';
import { dispatchUtil } from './utils/dispatchUtil';
import { dialogErrorsUtil } from './utils/ErrorHandling/dialogErrorUtil';
import { localStorageUtil } from './utils/localStorageUtils';
import { deviceUtil } from './utils/deviceUtils';
const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, globalState);
  const [online] = useState(navigator.onLine);

  const getDevices = useCallback(async () => {
    if (!deviceUtil.checkNavigator()) {
      return alert(
        '¡Este navegador no esta soportado! Si estas usando un iPhone, por favor ingresa a nuestra plataforma usando Safari.',
      );
    }
    // await deviceUtil.getInternalIp();
    let geolocation = { ...state.deviceInfo.geolocation };
    if (!state.deviceInfo.geolocation.permission) {
      const permissionState = await deviceUtil.checkGeoPermission();
      geolocation.permission = permissionState?.state || null;
    }
    const userAgent = deviceUtil.userAgent();
    const vendor = deviceUtil.vendor();
    if (!deviceUtil.checkMediaDevices()) {
      const deviceInfo = {
        userAgent,
        vendor,
        support: false,
        geolocation,
        isMobile: deviceUtil.isMobile(),
      };
      alert(
        '¡Este navegador no esta soportado! Si estas usando un iPhone, por favor ingresa a nuestra plataforma usando Safari.',
      );
      return dispatch(setDeviceInfo(deviceInfo));
    }
    const mediaDevices = await deviceUtil.mediaDevices();
    const deviceInfo = {
      mediaDevices,
      vendor,
      userAgent,
      support: true,
      geolocation,
      isMobile: deviceUtil.isMobile(),
    };
    localStorageUtil.setDeviceInfo(deviceInfo);
    dispatch(setDeviceInfo(deviceInfo));
  }, [state.deviceInfo.geolocation]);

  useEffect(() => {
    if (!online) {
      return dialogErrorsUtil.noNetworkConnection();
    }
    const cachedUser = localStorageUtil.getUserSession();
    if (state.user) {
      if (!state.user._id && cachedUser && Object.keys(cachedUser).length) {
        dispatch(setUserAction(cachedUser));
      }
    }
  }, [state.user, online]);

  useEffect(() => {
    if (!state.deviceInfo.device) {
      if (state.deviceInfo.support === null) {
        getDevices();
      }
    }
  }, [state.deviceInfo, getDevices]);

  useEffect(() => {
    if (!dispatchUtil.isReady) {
      dispatchUtil.isReady = true;
      dispatchUtil.dispatch = (params) => dispatch(params);
      Object.freeze(dispatchUtil);
    }
  }, []);

  return (
    <StoreContext.Provider value={[{ state }, { dispatch }]}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext)[0];
export const useDispatch = () => useContext(StoreContext)[1];

export default StoreProvider;
