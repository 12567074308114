import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { sanitationUtil } from '../../../../utils/fieldUtils';
import { WarnTooltip } from '../WarnTooltip';

const handleChange = ({
  event,
  name,
  sanitizeFn,
  onChange,
  callbackOnChange,
}) => {
  // get name to sanitize value, when useFieldArray is used from react-hook-form
  // the name of the field has the follow structure fields.[index].name
  const nameArr = name.split('.');
  const [fieldName] = nameArr.length > 1 ? nameArr.slice(-1) : nameArr;

  let value = event.target.value;
  value = sanitizeFn ? sanitizeFn(value) : value;
  value = sanitationUtil[fieldName] ? sanitationUtil[fieldName](value) : value;
  onChange(value);
  callbackOnChange(value);
};

export const InputControlled = ({
  control,
  name,
  rules,
  muiProps,
  sanitizeFn,
  errorPayload,
  warning,
  errorMapper = ({ error, payload }) => {
    return {
      pattern: 'Campo inválido',
      required: 'Campo requerido',
    }['pattern'];
  },
  disableOnDefaultValue = false,
  callbackOnBlur = () => {},
  callbackOnChange = () => {},
}) => {
  const { InputProps = {}, InputLabelProps = {} } = muiProps || {};
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isDirty, error },
  } = useController({
    name,
    control,
    rules,
  });
  const [disableOnDefaultState, setDisableOnDefaultState] = useState(false);

  useEffect(() => {
    if (disableOnDefaultValue && value && !isDirty) {
      setDisableOnDefaultState(true);
    }
  }, [disableOnDefaultValue, value, isDirty]);

  let endAdornment = null;
  if (warning) endAdornment = <WarnTooltip />;
  const muiPropsEndAdornment = InputProps?.endAdornment;
  if (muiPropsEndAdornment) endAdornment = muiPropsEndAdornment;

  return (
    <TextField
      onChange={(event) =>
        handleChange({ event, name, sanitizeFn, onChange, callbackOnChange })
      } // send value to hook form
      onBlur={(event) => {
        callbackOnBlur(event);
        onBlur(event);
      }} // notify when input is touched/blur
      value={value} // input value
      name={name} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear
      error={!warning && invalid}
      helperText={invalid ? errorMapper({ error, payload: errorPayload }) : ''}
      disabled={disableOnDefaultState}
      {...muiProps}
      color={warning ? 'warning' : 'primary'}
      InputProps={{
        ...InputProps,
        endAdornment,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        color: warning ? 'warning' : 'primary',
      }}
    />
  );
};
