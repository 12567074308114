import { useEffect, useState } from 'react';
import * as validators from '../utils/validators';
import { sanitationUtil } from '../utils/fieldUtils';
import { objectsAreEqual } from '../utils/funcUtils';

export const useForm = ({
  initialValues = {},
  formValidations = {},
  onSubmit,
  dontReset,
  dontValidateOnSubmit,
}) => {
  // const originalValues = initialValues;
  const [data, setData] = useState(initialValues || {});
  const [validations, setValidations] = useState(formValidations || {});
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (Object.keys(initialValues).length && !Object.keys(data).length) {
      setData(initialValues);
    }
    if (
      Object.keys(formValidations).length &&
      !Object.keys(validations).length
    ) {
      setValidations(formValidations);
    }

    if (
      Object.keys(data).length &&
      Object.keys(initialValues).length &&
      !objectsAreEqual(initialValues, data)
    ) {
      setHasChanged(true);
    } else if (
      Object.keys(data).length &&
      Object.keys(initialValues).length &&
      objectsAreEqual(initialValues, data)
    ) {
      setHasChanged(false);
    }
  }, [initialValues, data, formValidations, validations]);

  // function used to valide if a field is equal to another field
  const conditionValidationRule = ({ validation }) => {
    const validate = validation?.validateIf;
    if (validate?.callback && !validate.callback(data)) {
      return false;
    }

    return true;
  };

  // function used to valide if a field is required
  const requiredRule = ({ validation, key, value, newErrors }) => {
    const required = validation?.isRequired;
    if (required?.value && !value) {
      newErrors[key] = validation?.isRequired?.message;
      return false;
    }

    return true;
  };

  // function used to valide if a field is a valid name
  const nameRule = ({ validation, key, value, newErrors }) => {
    const name = validation?.isName;
    if (name?.value && !validators.namesValidator(value)) {
      newErrors[key] = validation?.isName?.message;
      return false;
    }

    return true;
  };

  // function used to valide if a field is a valid email
  const emailRule = ({ validation, key, value, newErrors }) => {
    const email = validation?.isEmail;
    if (email?.value && !validators.validateEmail(value)) {
      newErrors[key] = validation?.isEmail?.message;
      return false;
    }

    return true;
  };

  // function used to valide if a field is a valid rfc
  const rfcRule = ({ validation, key, value, newErrors }) => {
    const rfc = validation?.isRfc;
    if (rfc?.value && !validators.validateRFC(value)) {
      newErrors[key] = validation?.isRfc?.message;
      return false;
    }

    return true;
  };

  // function used to valide if a field is a valid curp
  const curpRule = ({ validation, key, value, newErrors }) => {
    const curp = validation?.isCurp;
    if (curp?.value && !validators.curpValidator(value)) {
      newErrors[key] = validation?.isCurp?.message;
      return false;
    }

    return true;
  };

  // function used to valide if a field is a valid phone
  const phoneRule = ({ validation, key, value, newErrors }) => {
    const phone = validation?.isPhone;
    if (phone?.value && !validators.validatePhone(value)) {
      newErrors[key] = validation?.isPhone?.message;
      return false;
    }

    return true;
  };

  // function used to valide if a field is a valid password
  const passwordRule = ({ validation, key, value, newErrors }) => {
    const password = validation?.isPassword;
    if (password?.value && !validators.pswdValidator(value)) {
      newErrors[key] = validation?.isPassword?.message;
      return false;
    }

    return true;
  };

  // function used to check if a field is a valid private code OR NIP
  const privateCodeRule = ({ validation, key, value, newErrors }) => {
    const privateCode = validation?.isPrivateCode;
    if (privateCode?.value && !validators.privateCodeValidator(value)) {
      newErrors[key] = validation?.isPrivateCode?.message;
      return false;
    }

    return true;
  };
  const urlRule = ({ validation, key, value, newErrors }) => {
    const url = validation?.isUrl;
    if (url?.value && !validators.urlValidator(value)) {
      newErrors[key] = validation?.isUrl?.message;
      return false;
    }

    return true;
  };

  const dateRule = ({ validation, key, value, newErrors }) => {
    const date = validation?.isDate;
    if (date?.value && !validators.dateValidator(value)) {
      newErrors[key] = validation?.isDate?.message;
      return false;
    }

    return true;
  };
  // function used to valide if a field is equal to another field
  const equalRule = ({ validation, key, value, newErrors }) => {
    const equal = validation?.isEqual;
    if (equal?.value && data[equal.withField] !== value) {
      newErrors[key] = validation?.isEqual?.message;
      return false;
    }

    return true;
  };

  // function used to valide if a field complies with a regex
  const patternRule = ({ validation, key, value, newErrors }) => {
    const pattern = validation?.pattern;
    if (pattern?.value && !RegExp(pattern.value).test(value)) {
      newErrors[key] = pattern.message;
      return false;
    }

    return true;
  };

  // function used to call a callback function and verify if field is valid
  const customRule = ({ validation, key, newErrors }) => {
    const customResponse = [];

    if (!validation) {
      return true;
    }

    Object.keys(validation).forEach((validationOption) => {
      if (validationOption.includes('custom')) {
        const custom = validation?.[validationOption];
        if (custom?.isValid && !custom.isValid(data, key)) {
          customResponse.push(false);
          newErrors[key] = custom.message;
        }
      }
    });

    const existSomeError = customResponse.some((value) => value === false);
    if (existSomeError) {
      return false;
    }

    return true;
  };

  // this function run all validations functions declared previously
  const runValidations = (allowEmptyValues) => {
    let valid = true;

    if (initialValues && validations) {
      const newErrors = {};

      for (const key in initialValues) {
        const value = data[key];
        const validation = validations[key];

        // bypass required if empty value
        if (!value && allowEmptyValues) {
          continue;
        }

        if (!conditionValidationRule({ validation })) {
          continue;
        }

        if (
          !requiredRule({ validation, key, value, newErrors }) &&
          !allowEmptyValues
        ) {
          valid = false;
          continue;
        }

        if (!nameRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!emailRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!rfcRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!curpRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!phoneRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!passwordRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!privateCodeRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!urlRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }
        if (!dateRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!equalRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!patternRule({ validation, key, value, newErrors })) {
          valid = false;
          continue;
        }

        if (!customRule({ validation, key, newErrors })) {
          valid = false;
          continue;
        }
      }

      if (!valid) {
        setErrors(newErrors);
        return valid;
      }
    }

    setErrors({});
    setIsValidForm(true);
    return valid;
  };

  // used to mark all fields as touching and showing errors when submitting the form
  const touchAllProperties = () => {
    const newTouched = {};

    for (const key in initialValues) {
      newTouched[key] = true;
    }

    setTouched(newTouched);
  };

  // used to sanitize values using a callback or existing sanitation function
  const runSanitize = ({ name, value }) => {
    if (!validations[name]?.sanitize) {
      return value;
    }

    const { value: valueRule, type, callback } = validations[name].sanitize;
    if (!valueRule && callback) {
      return callback(value, name);
    }

    if (type === 'names') {
      return sanitationUtil.names(value);
    }

    if (type === 'phone') {
      return sanitationUtil.phone(value);
    }

    if (type === 'email') {
      return sanitationUtil.email(value);
    }

    if (type === 'rfc') {
      return sanitationUtil.rfc(value);
    }

    if (type === 'curp') {
      return sanitationUtil.curp(value);
    }

    if (type === 'password') {
      return sanitationUtil.password(value);
    }
  };

  const handleChange = ({ name, value }) => {
    const currValue = runSanitize({ name, value });

    setData({
      ...data,
      [name]: currValue,
    });
  };

  // function used to run validations when a field is touched
  const handleBlur = ({ name }) => {
    setTouched({ ...touched, [name]: true });
    runValidations();
  };

  function resetForm() {
    setData(initialValues);
    setErrors({});
    setTouched({});
  }

  // Second argument is used for options on submit
  const handleSubmit = (e, options) => {
    e.preventDefault();

    const isValidForm = runValidations(options?.allowEmptyValues);
    touchAllProperties();

    if (!isValidForm && !dontValidateOnSubmit) {
      return;
    }
    if (!dontReset) {
      resetForm();
    }
    if (onSubmit) {
      onSubmit({ data, resetForm, handleForceError });
    }
  };

  // function used to show a temporary error
  const handleForceError = (errorArr) => {
    let newErrors = { ...errors };
    errorArr.forEach(({ name, error }) => {
      if (name && error) {
        newErrors[name] = error;
      }
    });
    touchAllProperties();
    setErrors(newErrors);
  };

  return {
    data,
    validations,
    touched,
    errors,
    isValidForm,
    handleForceError,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setData,
    setValidations,
    runValidations,
    hasChanged,
  };
};
