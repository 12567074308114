import { closeDialogAction, setDialogStateAction } from '../../actions';
import { notificationDispatchUtil, logoutUtil } from '../dispatchUtil';

const defaultOnAction = (logout) => {
  notificationDispatchUtil.dispatch(closeDialogAction());
  if (typeof logout === 'boolean' && logout) {
    logoutUtil();
  }
};

export const dialogErrorsUtil = {
  defaultOnAction,
  cameraBlocked: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'cameraBlocked',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  noNetworkConnection: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'noNetworkConnection',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  errorOnServer: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'errorOnServer',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  tokenExpired: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'tokenExpired',
        payload,
        onConfirm: onConfirm || defaultOnAction(true),
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  notAuthorized: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'notAuthorized',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  fileNotFound: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'fileNotFound',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  docsToSignNotFound: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'docsToSignNotFound',
        payload,
        onConfirm: onConfirm || defaultOnAction(),
        onCancel: onCancel || defaultOnAction(),
      }),
    ),
  userNotFound: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'userNotFound',
        payload,
        onConfirm: onConfirm || defaultOnAction(),
        onCancel: onCancel || defaultOnAction(),
      }),
    ),
  encryptedFileError: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'encryptedFileError',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  geolocationRequired: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'geolocationRequired',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  emailVerificationTokenExpired: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'emailVerificationTokenExpired',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  emailVerificationTokenMismatch: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'emailVerificationTokenMismatch',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  documentEncrypted: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'documentEncrypted',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  preRegisterUserErrors: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'preRegisterUserErrors',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  invalidAuthLink: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'invalidAuthLink',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  contactMethodsNotFound: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'contactMethodsNotFound',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  customError: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'customError',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
};
