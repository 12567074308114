import React, { useRef } from 'react';
import { Button, Dialog } from '@mui/material';
import QuestionLine from './QuestionLine';
import { CropperWrapper } from './style';

export const CropperDialog = (props) => {
  const {
    openDialog,
    setOpenDialog,
    setCropper,
    getCropData,
    droppedPicture,
    setOpenHelpDialog,
    fullScreen,
  } = props;
  const cropperRef = useRef(null);

  return (
    <Dialog
      open={openDialog}
      maxWidth="xl"
      fullScreen={fullScreen}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CropperWrapper
        style={{ height: '100%', width: '100%' }}
        zoomTo={0.5}
        initialAspectRatio={1}
        cropBoxResizable={false}
        src={droppedPicture}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        ref={cropperRef}
        autoCropArea={1}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        guides={true}
      />
      <Button
        onClick={getCropData}
        variant="contained"
        color="inherit"
        style={{ position: 'absolute', bottom: '25px', right: '10px' }}
      >
        Recortar
      </Button>
      <QuestionLine
        fill="white"
        onClick={() => setOpenHelpDialog(true)}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer',
        }}
      />
    </Dialog>
  );
};
