import { sifeInstance } from '../instance/createInstance';
import { createEndpoint } from './createEndpoint';
import sifeRoutes from './routes/sifeRoutes';
const { test, user, document, signature, verify, admin } = sifeRoutes;

// createEndpoint(<route>, <instance>)
export const sifeEndpoints = {
  // * test
  testApiEndpoint: createEndpoint(test.testAPI, sifeInstance),
  testPreRegEndpoint: createEndpoint(test.testPreReg, sifeInstance),
  testPhoneSMSEndPoint: createEndpoint(test.testPhoneSMS, sifeInstance),
  testSESEndpoint: createEndpoint(test.testSES, sifeInstance),
  testUnverifyUserEndpoint: createEndpoint(test.testUnverifyUser, sifeInstance),
  // * user
  usersEndpoint: createEndpoint(user.users, sifeInstance),
  preRegisterUsersEndpoint: createEndpoint(user.preRegisterUsers, sifeInstance),
  loginUserEndpoint: createEndpoint(user.login, sifeInstance),
  sendRecoveryEndpoint: createEndpoint(user.sendRecovery, sifeInstance),
  checkResetTokenEndpoint: createEndpoint(user.checkResetToken, sifeInstance),
  emailVerificationEndpoint: createEndpoint(
    user.emailVerification,
    sifeInstance,
  ),
  phoneVerificationEndpoint: createEndpoint(
    user.phoneVerification,
    sifeInstance,
  ),
  resetPasswordEndpoint: createEndpoint(user.resetPassword, sifeInstance),
  resetPasswordInternalEndpoint: createEndpoint(
    user.resetPasswordInternal,
    sifeInstance,
  ),
  userMetadataEndpoint: createEndpoint(user.userMeta, sifeInstance),
  userDataEndpoint: createEndpoint(user.userData, sifeInstance),
  extCompleteUserDataEndpoint: createEndpoint(
    user.completeUserData,
    sifeInstance,
  ),
  extSendOtpEndpoint: createEndpoint(user.sendOtp, sifeInstance),
  extVerifyOtpEndpoint: createEndpoint(user.verifyOtp, sifeInstance),
  profilePictureEndpoint: createEndpoint(user.profilePicture, sifeInstance),
  sendEmailVerificationEndpoint: createEndpoint(
    user.sendEmailVerification,
    sifeInstance,
  ),
  sendPhoneVerificationEndpoint: createEndpoint(
    user.sendPhoneVerification,
    sifeInstance,
  ),
  verifyContactMethodEndpoint: createEndpoint(
    user.verifyContactMethod,
    sifeInstance,
  ),
  endUserRegistrationEndpoint: createEndpoint(
    user.endUserRegistration,
    sifeInstance,
  ),
  sendVerificationCodeEndpoint: createEndpoint(
    user.sendVerificationCode,
    sifeInstance,
  ),
  getContactMethodsEndpoint: createEndpoint(
    user.getContactMethods,
    sifeInstance,
  ),
  // * verify
  startVerificationEndpoint: createEndpoint(
    verify.startVerification,
    sifeInstance,
  ),
  detectFaceEndpoint: createEndpoint(verify.detectFace, sifeInstance),
  verifyUserEndpoint: createEndpoint(verify.verifyUser, sifeInstance),
  verifyUserManuallyEndpoint: createEndpoint(
    verify.verifyUserManually,
    sifeInstance,
  ),
  verifyIdFrontEndpoint: createEndpoint(verify.verifyIdFront, sifeInstance),
  verifyIdBackEndpoint: createEndpoint(verify.verifyIdBack, sifeInstance),
  verificationDataEndpoint: createEndpoint(
    verify.verificationData,
    sifeInstance,
  ),
  getManuallyVerifTokenEndpoint: createEndpoint(
    verify.getManuallyVerifToken,
    sifeInstance,
  ),
  concludeVerifTestEndpoint: createEndpoint(
    verify.concludeVerifTest,
    sifeInstance,
  ),
  addVideoAgreementEndpoint: createEndpoint(
    verify.addVideoAgreement,
    sifeInstance,
  ),
  // * documents
  signDocsSifeAutographEndpoint: createEndpoint(
    document.signDocsSifeAutograph,
    sifeInstance,
  ),
  signDocsSifeCryptographicEndpoint: createEndpoint(
    document.signDocsSifeCryptographic,
    sifeInstance,
  ),
  signDocsSatCryptographicEndpoint: createEndpoint(
    document.signDocsSatCryptographic,
    sifeInstance,
  ),
  pendingDocsEndpoint: createEndpoint(document.pendingDocs, sifeInstance),
  userDocsEndpoint: createEndpoint(document.userDocs, sifeInstance),
  getSingleDocumentEndpoint: createEndpoint(
    document.getSingleDocument,
    sifeInstance,
  ),
  deleteDocsEndpoint: createEndpoint(document.deleteDocs, sifeInstance),
  uploadDocumentEndpoint: createEndpoint(document.uploadDocument, sifeInstance),
  getDoucmentEndpoint: createEndpoint(document.getDocument, sifeInstance),
  getDocumentStatusEndpoint: createEndpoint(
    document.getDocumentStatus,
    sifeInstance,
  ),
  // * signature
  storeCryptoSignatureEndpoint: createEndpoint(
    signature.storeCryptoSignature,
    sifeInstance,
  ),
  getCryptoSignatureEndpoint: createEndpoint(
    signature.getCryptoSignature,
    sifeInstance,
  ),
  getSatCryptoSignatureEndpoint: createEndpoint(
    signature.getSatCryptoSignature,
    sifeInstance,
  ),
  revokeCryptoSignatureEndpoint: createEndpoint(
    signature.revokeCryptoSignature,
    sifeInstance,
  ),
  // * admin
  adminUsersEndpoint: createEndpoint(admin.adminUsers, sifeInstance),
  adminUserDataEndpoint: createEndpoint(admin.adminUserData, sifeInstance),
  adminUserDocsEndpoint: createEndpoint(admin.adminUserDocs, sifeInstance),
  adminMockPdfDocsEndpoint: createEndpoint(
    admin.adminMockDocsPdf,
    sifeInstance,
  ),
  adminExtAPIEndpoint: createEndpoint(admin.adminExternal, sifeInstance),
  adminSingleExtAPIEndpoint: createEndpoint(
    admin.adminSingleExternal,
    sifeInstance,
  ),
  adminExtAPIAccessFileEndpoint: createEndpoint(
    admin.aminExtAPIAccessFile,
    sifeInstance,
  ),
  adminExtPermissionsEndpoint: createEndpoint(
    admin.adminExternalPermissions,
    sifeInstance,
  ),
  adminCronsEndpoint: createEndpoint(admin.adminCrons, sifeInstance),
  adminCronEndpoint: createEndpoint(admin.adminCron, sifeInstance),
  adminCronStatusEndpoint: createEndpoint(admin.adminCronStatus, sifeInstance),
  adminChargesEndpoint: createEndpoint(admin.adminCharges, sifeInstance),
};
