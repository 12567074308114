import { createContext, useContext, useReducer, useEffect } from 'react';
import notificationReducer, { initialState } from './notificationReducer';
import { notificationDispatchUtil } from '../utils/dispatchUtil';

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  useEffect(() => {
    if (!notificationDispatchUtil.isReady) {
      notificationDispatchUtil.isReady = true;
      notificationDispatchUtil.dispatch = (params) => dispatch(params);
      Object.freeze(notificationDispatchUtil);
    }
  }, []);

  return (
    <NotificationContext.Provider value={[state, dispatch]}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotificationStore = () => useContext(NotificationContext)[0];
const useNotificationDispatch = () => useContext(NotificationContext)[1];

export { NotificationContext, useNotificationStore, useNotificationDispatch };
export default NotificationProvider;
