export const en = {
  titles: {
    title1: `Enter your code`,
  },
  buttons: {
    button1: `Verify`,
    button2: `Send again`,
  },
  texts: {
    text1: `A one-time code has been sent to your email for verification purposes`,
    text2: `Didn't receive your code?`,
    text3: `Verifying OTP...`,
    text4: `Sending OTP...`,
  },
};
