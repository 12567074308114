const sifeRoutes = {
  test: {
    testAPI: '/test',
    testPhoneSMS: '/test/phone',
    testPreReg: '/public/preregister-user',
    testSES: '/test/SES',
    testUnverifyUser: '/test/unverify-user',
  },
  // * users
  user: {
    users: '/users',
    preRegisterUsers: '/users/pre-register',
    login: '/users/login',
    sendRecovery: '/users/:user/send-recovery',
    checkResetToken: '/users/:user/check-reset-token',
    resetPassword: '/users/:user/reset-password',
    resetPasswordInternal: '/users/current/reset-password',
    userData: '/users/current',
    userMeta: '/users/current/metadata',
    sendOtp: '/external-auth/send-otp',
    verifyOtp: '/external-auth/verify-otp',
    profilePicture: '/users/current/profile-picture',
    completeUserData: '/external-auth/:user/complete-data', // @ deprecated
    emailVerification: '/users/:user/email-verification',
    phoneVerification: '/users/:user/phone-verification',
    verifyContactMethod: '/users/:user/verify-contact-method',
    endUserRegistration: '/users/:user/end-user-registration',
    sendVerificationCode: '/users/:user/send-verification-code',
    getContactMethods: '/users/:user/contact-methods',
    sendEmailVerification: '/users/current/send-email-verification',
    sendPhoneVerification: '/users/current/send-phone-verification',
  },
  //* public
  // * document
  document: {
    signDocsSifeAutograph: '/documents/sign-sife-autograph',
    signDocsSifeCryptographic: '/documents/sign-sife-cryptographic',
    signDocsSatCryptographic: '/documents/sign-sat-cryptographic',
    uploadDocument: '/documents/upload',
    userDocs: '/documents/history',
    pendingDocs: '/documents/pending',
    getDocument: '/documents/:id/pdf',
    deleteDocs: '/documents/:docId',
    getSingleDocument: '/documents/:docId',
    getDocumentStatus: '/documents/:docId/verify-status',
  },
  // * signature
  signature: {
    storeCryptoSignature: '/signatures/crypto-signature',
    getCryptoSignature: '/signatures/crypto-signature',
    getSatCryptoSignature: '/signatures/sat-crypto-signature',
    revokeCryptoSignature: '/signatures/crypto-signature',
  },
  // * verify
  verify: {
    startVerification: '/verify/start',
    detectFace: '/verify/selfie',
    verifyIdFront: '/verify/front-id',
    verifyIdBack: '/verify/back-id',
    verifyUser: '/verify/user',
    verifyUserManually: '/admin/verify/:user/manually',
    verificationData: '/verify/data',
    getManuallyVerifToken: '/verify/manually-token',
    concludeVerifTest: '/verify/conclude-test',
    addVideoAgreement: '/verify/video-agreement',
  },
  // * admin users
  admin: {
    adminUsers: '/admin/users',
    adminUserData: '/admin/users/:user',
    adminUserDocs: '/admin/users/current/documents',
    adminMockDocsPdf: '/admin/users/current/documents/pdf',
    adminCharges: '/admin/subscription-charges',
    // TODO
    adminExternal: '/admin/external-access',
    adminSingleExternal: '/admin/external-access/:id',
    aminExtAPIAccessFile: '/admin/external-access/access-file',
    adminExternalPermissions: '/admin/external-access/permissions',
    adminCrons: '/admin/crons',
    adminCron: '/admin/crons/:id',
    adminCronStatus: '/admin/crons/:id/change-status',
  },
};
export default sifeRoutes;
