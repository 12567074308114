import { createContext, useContext, useReducer, useEffect } from 'react';
import verifyReducer, { initialState } from './verifyReducer';
import { verifyDispatchUtil } from '../utils/dispatchUtil';

const VerifyContext = createContext();

const VerifyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(verifyReducer, initialState);

  useEffect(() => {
    if (!verifyDispatchUtil.isReady) {
      verifyDispatchUtil.isReady = true;
      verifyDispatchUtil.dispatch = (params) => dispatch(params);
      Object.freeze(verifyDispatchUtil);
    }
  }, []);

  return (
    <VerifyContext.Provider value={[state, dispatch]}>
      {children}
    </VerifyContext.Provider>
  );
};

const useVerifyStore = () => useContext(VerifyContext)[0];
const useVerifyDispatch = () => useContext(VerifyContext)[1];

export { VerifyContext, useVerifyStore, useVerifyDispatch };
export default VerifyProvider;
