import { styled } from '@mui/system';
import { colorPalette } from '../../../../utils/colorPalette';

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'active' &&
    prop !== 'bgColor' &&
    prop !== 'margin' &&
    prop !== 'noShadow' &&
    prop !== 'color' &&
    prop !== 'iColor',
})(({ theme, active, bgColor, margin, noShadow, color, iColor }) => ({
  width: 'fit-content',
  margin: margin,
  marginLeft: 'auto',
  marginRight: 'auto',
  button: {
    backgroundColor:
      bgColor === 'none'
        ? 'transparent'
        : colorPalette[bgColor || 'indigo'][500],
    color: colorPalette[iColor || 'common']['white'],
    pointerEvents: active ? undefined : 'none',
    boxShadow: noShadow ? undefined : 'initial',
    ':hover': {
      backgroundColor:
        bgColor === 'none' ? colorPalette.grey[200] : theme.palette[color].main,
    },
  },
}));
