import { isValidPhoneNumber } from 'react-phone-number-input';

export const namesRegex =
  /^[a-zA-Z\u00C0-\u017F]+(([',. -][a-zA-Z \u00C0-\u017F])?[a-zA-Z\u00C0-\u017F]*)*$/;
export const privateCodeRegex = /^(?=.{4,})/;
export const passwordRegex = /^(?=.{8,})/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const rfcRegex =
  /(^(\w{3}|\w{4})\d{2}(0+[1-9]|1[012])([12][0-9]|3[01]|0+[1-9])\w{3}$)|(^SOR@\w{9}$)/;
export const curpRegex =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/i;
export const fileNameRegex =
  /^([A-Za-z\d\u00C0-\u017F])+([.\w\-\u00C0-\u017F ]*)([A-Za-z\d\u00C0-\u017F])$/;
export const fileNameValidCharsRegex = /[\w\d\u00C0-\u017F\-. ]/g;
export const urlRegex =
  /((https):\/\/)+[a-zA-Z0-9-@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const phoneRegex = /^([+]){1}([0-9]){11,15}$/;
const emailAliasRegex = /\+\d+@/;

export const namesValidator = (name) => {
  return name ? namesRegex.test(name) : true;
};
export const privateCodeValidator = (pswd) => {
  return privateCodeRegex.test(pswd);
};
export const pswdValidator = (pswd) => {
  return passwordRegex.test(pswd);
};

export const validateEmail = (email) => {
  return emailRegex.test(email) && !emailAliasRegex.test(email);
};

export const validateRFC = (rfc) => {
  return rfcRegex.test(rfc);
};

export const curpValidator = (curp) => {
  return curpRegex.test(curp);
};

export const validatePhone = (phone = '') => {
  return isValidPhoneNumber(phone);
};
export const fileNameValidator = (fileName) => {
  return fileNameRegex.test(fileName);
};

export const urlValidator = (url) => {
  return urlRegex.test(url);
};

export const dateValidator = (date) => {
  if (typeof date === 'object' && date.toString() === 'Invalid Date') {
    return false;
  }
  return true;
};

export const validateUnprotectedRoutes = () => {
  return (
    !window.location.pathname.includes('reset') &&
    !window.location.pathname.includes('recovery') &&
    !window.location.pathname.includes('login') &&
    !window.location.pathname.includes('signup')
  );
};

export const validateUserData = (user, cachedUser) => {
  return !user.email && !user.rfc && !cachedUser?.email && !cachedUser?.rfc;
};
