const apiConfig = {
  local: {
    protocol: 'http',
    hostname: 'localhost',
    port: 3001,
    api: 'api',
    version: 'v1',
  },
  staging: {
    protocol: 'https',
    hostname: 'staging.api.sife.io',
    api: 'api',
    version: 'v1',
  },
  dev: {
    protocol: 'https',
    hostname: 'dev.api.sife.io',
    api: 'api',
    version: 'v1',
  },
  prod: {
    protocol: 'https',
    hostname: 'api.sife.io',
    api: 'api',
    version: 'v1',
  },
};

export const sifeConfig = (environment) => apiConfig[environment];
