import React from 'react';
import { Box } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import { MUIPhoneField } from '../MUICustom/MUIPhoneField';
import { styled } from '@mui/system';

export const CustomStyledPhoneInput = styled(PhoneInput)(({ size }) => ({
  '&.PhoneInput': {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& input': {
      marginLeft: '45px',
    },
  },
  '& label:not(.MuiInputLabel-shrink)': {
    marginLeft: '45px',
  },
  '& .PhoneInputCountry': {
    position: 'absolute',
    top: size === 'small' ? '12px' : '20px',
    left: '20px',
    '& .PhoneInputCountrySelectArrow': {
      display: 'none',
    },
  },
}));

export const CustomPhoneInput = (props) => {
  const { phone, setPhone, error, onBlur, disabled, id, helperText, size } =
    props;

  const handleChangePhone = (value) => {
    setPhone({ target: { value, name: 'phone', id: 'phone' } });
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <CustomStyledPhoneInput
        defaultCountry="MX"
        value={phone}
        error={error}
        onChange={(value) => handleChangePhone(value)}
        onBlur={onBlur}
        inputComponent={MUIPhoneField}
        disabled={disabled}
        helperText={helperText}
        id={id}
        size={size}
      />
    </Box>
  );
};
