import { endpointHandler } from '../../endpoints';
const {
  storeCryptoSignatureEndpoint,
  getCryptoSignatureEndpoint,
  getSatCryptoSignatureEndpoint,
  revokeCryptoSignatureEndpoint,
} = endpointHandler();

export const storeCryptoSignatureAPI = (data, reqConfig, urlVar) => {
  return storeCryptoSignatureEndpoint.post(data, reqConfig, urlVar);
};

export const getCryptoSignatureAPI = (reqConfig, urlVar) => {
  return getCryptoSignatureEndpoint.get(reqConfig, urlVar);
};

export const getSatCryptoSignatureAPI = (reqConfig, urlVar) => {
  return getSatCryptoSignatureEndpoint.get(reqConfig, urlVar);
};

export const revokeCryptoSignatureAPI = (reqConfig, urlVar) => {
  return revokeCryptoSignatureEndpoint.delete(reqConfig, urlVar);
};
