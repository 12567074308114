import { endpointHandler } from '../../endpoints';
const {
  signDocsSifeAutographEndpoint,
  signDocsSifeCryptographicEndpoint,
  signDocsSatCryptographicEndpoint,
  pendingDocsEndpoint,
  userDocsEndpoint,
  uploadDocumentEndpoint,
  getDoucmentEndpoint,
  getDocumentStatusEndpoint,
  getSingleDocumentEndpoint,
  deleteDocsEndpoint,
} = endpointHandler();

export const signDocsSifeAutographAPI = (data, reqConfig, urlVar) => {
  return signDocsSifeAutographEndpoint.post(data, reqConfig, urlVar);
};

export const signDocsSifeCryptographicAPI = (data, reqConfig, urlVar) => {
  return signDocsSifeCryptographicEndpoint.post(data, reqConfig, urlVar);
};

export const signDocsSatCryptographicAPI = (data, reqConfig, urlVar) => {
  return signDocsSatCryptographicEndpoint.post(data, reqConfig, urlVar);
};

export const getPendingDocsAPI = (reqConfig, urlVar) => {
  return pendingDocsEndpoint.get(reqConfig, urlVar);
};

export const getSingleDocumentAPI = (reqConfig, urlVar) => {
  return getSingleDocumentEndpoint.get(reqConfig, urlVar);
};

export const allDocsAPI = (reqConfig, urlVar) => {
  return userDocsEndpoint.get(reqConfig, urlVar);
};

export const uploadDocumentAPI = (data, reqConfig, urlVar) => {
  return uploadDocumentEndpoint.post(data, reqConfig, urlVar);
};

export const getDocumentAPI = (reqConfig, urlVar) => {
  return getDoucmentEndpoint.get(reqConfig, urlVar);
};

export const getDocumentStatusAPI = (reqConfig, urlVar) => {
  return getDocumentStatusEndpoint.get(reqConfig, urlVar);
};

export const deleteDocumentAPI = (reqConfig, urlVar) => {
  return deleteDocsEndpoint.delete(reqConfig, urlVar);
};
