import { SET_DIALOG_STATE, CLOSE_DIALOG } from './actionTypes';

export const setDialogStateAction = (dialogState) => ({
  type: SET_DIALOG_STATE,
  dialogState,
});

export const closeDialogAction = () => ({
  type: CLOSE_DIALOG,
});
