import { Link } from '@mui/material';
import { dialogErrorsUtil } from '../../../utils/ErrorHandling/dialogErrorUtil';
import { snackErrorsUtil } from '../../../utils/ErrorHandling/snackErrorsUtil';

export const initialState = (user = {}) => {
  return {
    name: user.name || '',
    lastNameP: user.lastNameP || '',
    lastNameM: user.lastNameM || '',
    email: user.email || '',
    rfc: user.rfc || '',
    phone: user.phone || '',
    curp: user.curp || '',
    dateOfBirth: user.dateOfBirth || null,
    // postalCode: '',
    // country: '',
    // state: '',
    // city: '',
    // street: '',
    // extNumber: '',
    // intNumber: '',
  };
};

export const formErrorMapper = {
  name: ({ error }) => {
    return {
      manualNoValue: 'Agrega tu nombre(s)',
      pattern: 'Nombre(s) inválido',
      required: 'Campo requerido',
    }[error.type];
  },
  lastNameP: ({ error }) => {
    return {
      manualNoValue: 'Agrega tu apellido paterno',
      pattern: 'Apellido paterno inválido',
      required: 'Campo requerido',
    }[error.type];
  },
  lastNameM: ({ error }) => {
    return {
      pattern: 'Apellido materno inválido',
    }[error.type];
  },
  email: ({ error, payload }) => {
    return {
      manualVerifRequired: (
        <span>
          Pendiente de verificación.{' '}
          <Link style={{ cursor: 'pointer' }} onClick={payload.callBack}>
            ¿Volver a envíar código?
          </Link>
        </span>
      ),
      manualNoValue: 'Agrega un correo electrónico',
      pattern: 'Correo inválido',
      required: 'Campo requerido',
      conflict: 'Correo electrónico ya registrado',
    }[error.type];
  },
  rfc: ({ error }) => {
    return {
      manualNoValue: 'Agrega tu RFC',
      pattern: 'RFC inválido',
      required: 'Campo requerido',
      conflict: 'RFC ya registrado',
    }[error.type];
  },
  phone: ({ error, payload }) => {
    return {
      manualVerifRequired: (
        <span>
          Pendiente de verificación.{' '}
          <Link style={{ cursor: 'pointer' }} onClick={payload.callBack}>
            ¿Volver a envíar código?
          </Link>
        </span>
      ),
      manualNoValue: 'Agrega tu teléfono',
      pattern: 'Teléfono inválido',
      required: 'Campo requerido',
      validate: 'Teléfono inválido',
    }[error.type];
  },
  curp: ({ error }) => {
    return {
      manualNoValue: 'Agrega tu CURP',
      pattern: 'CURP inválido',
      required: 'Campo requerido',
      conflict: 'CURP ya registrado',
    }[error.type];
  },
  dateOfBirth: ({ error }) => {
    return {
      manualNoValue: 'Agrega tu fecha de nacimiento',
      validate: 'Fecha inválida',
      required: 'Campo requerido',
    }[error.type];
  },
};

export const updateUserErrorConstants = {
  400: {
    EMAIL_NOT_VALID: {
      field: 'email',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidEmail(),
    },
    RFC_NOT_VALID: {
      field: 'rfc',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidRFC(),
    },
    PHONE_NOT_VALID: {
      field: 'phone',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidPhone(),
    },
    CURP_NOT_VALID: {
      field: 'curp',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidCURP(),
    },
    DATE_OF_BIRTH_NOT_VALID: {
      field: 'dateOfBirth',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidDateOfBirth(),
    },
  },
  401: {
    TOKEN_REQUIRED: {
      action: () => snackErrorsUtil.tokenExpired(),
    },
    TOKEN_EXPIRED: {
      action: () => snackErrorsUtil.tokenExpired(),
    },
    TOKEN_MISMATCH: {
      action: () => snackErrorsUtil.tokenExpired(),
    },
  },
  404: {
    USER_DOES_NOT_EXIST: {
      action: () => snackErrorsUtil.nonExistentUser(),
    },
  },
  409: {
    EMAIL_ALREADY_REGISTERED: {
      field: 'email',
      type: 'conflict',
      action: () => snackErrorsUtil.emailConflict(),
    },
    RFC_ALREADY_REGISTERED: {
      field: 'rfc',
      type: 'conflict',
      action: () => snackErrorsUtil.rfcConflict(),
    },
    CURP_ALREADY_REGISTERED: {
      field: 'curp',
      type: 'conflict',
      action: () => snackErrorsUtil.curpConflict(),
    },
  },
  500: {
    INTERNAL_SERVER_ERROR: {
      action: () => {
        dialogErrorsUtil.errorOnServer();
        snackErrorsUtil.errorOnServer();
      },
    },
  },
};

export const verifyEmailErrorConstants = {
  400: {
    EMAIL_NOT_VALID: {
      field: 'email',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidEmail(),
    },
  },
  404: {
    USER_DOES_NOT_EXIST: {
      action: () => snackErrorsUtil.nonExistentUser(),
    },
  },
  500: {
    INTERNAL_SERVER_ERROR: {
      action: () => {
        dialogErrorsUtil.errorOnServer();
        snackErrorsUtil.errorOnServer();
      },
    },
  },
};

export const verifyPhoneErrorConstants = {
  400: {
    OTP_REQUIRED: {
      action: () => snackErrorsUtil.invalidEmail(),
    },
    OTP_MUST_BE_STRING: {
      field: 'rfc',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidRFC(),
    },
    PHONE_NOT_VALID: {
      field: 'phone',
      type: 'pattern',
      action: () => snackErrorsUtil.invalidPhone(),
    },
    OTP_MISMATCH: {
      action: () => snackErrorsUtil.invalidOtp(),
    },
    OTP_EXPIRED: {
      action: () => snackErrorsUtil.otpExpired(),
    },
  },
  404: {
    USER_DOES_NOT_EXIST: {
      action: () => snackErrorsUtil.nonExistentUser(),
    },
  },
  500: {
    INTERNAL_SERVER_ERROR: {
      action: () => {
        dialogErrorsUtil.errorOnServer();
        snackErrorsUtil.errorOnServer();
      },
    },
  },
};
