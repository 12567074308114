// @ts-check
import { Buffer } from 'buffer';

export const pipe =
  (...funcs) =>
  (initValue) =>
    funcs.reduce((res, func) => func(res), initValue);

export const log = (v) => {
  console.log('* ~ Log from your very complex log function', v);
  return v;
};

export const getObjectEntries = (obj) => {
  if (typeof obj !== 'object' || Array.isArray(obj) || obj === null) {
    throw new Error('obj must be a key:value pair object');
  }
  return Object.entries(obj);
};
export const reduceObjValues = (acc, key) => {
  acc[key[0]] = key[1].value;
  return acc;
};

export const objectsAreEqual = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || Array.isArray(obj1) || obj1 === null) {
    throw new Error('obj1 must be a key:value pair object');
  }
  if (typeof obj2 !== 'object' || Array.isArray(obj2) || obj2 === null) {
    throw new Error('obj2 must be a key:value pair object');
  }
  const obj1Entries = getObjectEntries(obj1);
  const obj2Entries = getObjectEntries(obj2);
  const obj1EntriesLength = obj1Entries.length;
  const obj2EntriesLength = obj2Entries.length;
  if (obj1EntriesLength !== obj2EntriesLength) {
    return false;
  }

  const isEqual = Object.keys(obj1).every((key) => obj1[key] === obj2[key]);

  return isEqual;
};

export const formatBytes = (bytes) => {
  var marker = 1024; // Change to 1000 if required
  var decimal = 3; // Change as required
  var kiloBytes = marker; // One Kilobyte is 1024 bytes
  var megaBytes = marker * marker; // One MB is 1024 KB
  var gigaBytes = marker * marker * marker; // One GB is 1024 MB

  // return bytes if less than a KB
  if (bytes < kiloBytes) return bytes + ' Bytes';
  // return KB if less than a MB
  else if (bytes < megaBytes)
    return (bytes / kiloBytes).toFixed(decimal) + ' KB';
  // return MB if less than a GB
  else if (bytes < gigaBytes)
    return (bytes / megaBytes).toFixed(decimal) + ' MB';
  // return GB if less than a TB
  else return (bytes / gigaBytes).toFixed(decimal) + ' GB';
};

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

/**
 * @param {object} input
 * @param {string} input.token
 * @param {string} input.property
 * @return {string}
 */
export const getPropertyFromToken = ({ token, property }) => {
  if (!token) return '';
  if (token.startsWith('Bearer ')) {
    token = token.replace('Bearer ', '');
  }
  const tokenParts = token.split('.');
  const [, payload] = tokenParts;
  const buff = Buffer.from(payload, 'base64');
  const payloadStr = buff.toString('utf-8');
  const payloadObj = JSON.parse(payloadStr);
  if (!payloadObj[property]) return '';
  return payloadObj[property];
};
