import { useState, useEffect, useCallback } from 'react';
import { setDevicesHelper } from '../helpers/setDevicesHelper';

export const useUserMedia = (constraints) => {
  const [mediaStream, setMediaStream] = useState({});
  const [availableDevices, setAvailableDevices] = useState([]);
  const [isFirstRequest, setIsFirstRequest] = useState(false);
  const [isPermsDenied, setIsPermsDenied] = useState(false);

  const requestPerms = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const devices = await navigator.mediaDevices.enumerateDevices();
      const deviceMap = setDevicesHelper(devices);
      setAvailableDevices(deviceMap);
      setMediaStream(stream);
    } catch (err) {
      console.log('Denied');
      if (err.message === 'Permission denied' || err.code === 0) {
        setIsPermsDenied(true);
      }
    }
  }, [constraints]);

  const enableStream = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const deviceMap = setDevicesHelper(devices);
      setAvailableDevices(deviceMap);

      if (!devices.some((device) => device.label)) {
        setIsFirstRequest(true);
      } else {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setMediaStream(stream);
      }
    } catch (err) {
      console.log('Denied');
      if (err.message === 'Permission denied' || err.code === 0) {
        setIsPermsDenied(true);
      }
    }
  }, [constraints, setIsFirstRequest, setIsPermsDenied]);

  useEffect(() => {
    if (!mediaStream.active) {
      enableStream();
    }
  }, [enableStream, mediaStream, constraints]);

  return {
    mediaStream,
    availableDevices,
    setMediaStream,
    requestPerms,
    isFirstRequest,
    isPermsDenied,
  };
};
