export const globalState = {
  loading: false,
  backdrop: {
    feedbackType: '',
    total: 0,
    current: 0,
    open: false,
    clickable: true,
  },
  user: {
    email: '',
    signatures: {
      cryptoSignature: {
        encryptedKey: '',
        publicKeyEncoded: '',
      },
    },
    rfc: '',
    curp: '',
  },
  verify: {
    idType: 'INEIFE',
    selfie: {},
    INEIFE: {
      activeStep: 0,
      usedCamera: 'user',
      steps: [
        'Captura el frente de tu ID',
        'Captura el reverso de tu ID',
        'Captura tu selfie',
      ],
      text: 'INE/IFE',
      id: {},
      idBack: {},
    },
    PASSPORT: {
      activeStep: 0,
      usedCamera: 'user',
      steps: ['Captura el frente de tu pasaporte', 'Captura tu selfie'],
      text: 'Pasaporte',
      id: {},
    },
    OTHER: {
      activeStep: 0,
      usedCamera: 'user',
      steps: ['Captura el frente de tu ID', 'Captura tu selfie'],
      text: 'Otro',
      id: {},
    },
  },
  sign: {
    steps: [
      'Crea tu firma electrónica',
      'Selecciona tus documentos',
      'Firmar documento(s)',
    ],
    activeStep: 0,
  },
  docs: {
    docsFetched: [],
    selectedDocs: [],
    pendingDocs: [],
    signedDocs: [],
  },
  dialog: {
    open: false,
    type: '',
    onConfirm: () => {},
    onCancel: () => {},
    payload: {},
  },
  snack: {
    open: false,
    text: '',
    err: '',
    position: '',
    severity: '',
    variant: '',
    changerFunc: () => {},
    duration: 10000,
  },
  deviceInfo: {
    device: '',
    userAgent: '',
    mediaDevices: [],
    support: null,
    ip: '',
    geolocation: { permission: null, value: null },
  },
  language: 'es',
};
