import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import React from 'react';

export const HelpDialog = (props) => {
  const { openHelpDialog, setOpenHelpDialog } = props;
  return (
    <Dialog
      open={openHelpDialog}
      maxWidth="xs"
      onClose={() => setOpenHelpDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <List>
          <ListItem>
            <Typography>
              1. Mueve el rectángulo guía <strong>dando click dentro</strong> y
              moviendo tu cursor.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              2. Muevete en <strong>vertical (scroll)</strong> para hacer zoom a
              tu fotografía.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              3. Mueve la fotografía dando <strong> doble click </strong> fuera
              del rectángulo guía y moviendo tu cursor.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              4. Recorta tu imagen dando click en "Recortar".
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};
