export const urlBuilder = (url, urlVar) => {
  if (!urlVar || !Object.keys(urlVar).length) return url;

  if (url.includes(':') && Object.keys(urlVar).length) {
    const entries = Object.entries(urlVar);
    let newUrl;
    for (const [key, value] of entries) {
      newUrl = url.replace(`:${key}`, value);
    }
    return newUrl;
  }
};
