import { closeDialogAction, setDialogStateAction } from '../actions';
import { notificationDispatchUtil } from './dispatchUtil';

const defaultOnAction = function () {
  notificationDispatchUtil.dispatch(closeDialogAction());
};

export const dialogUtil = {
  defaultOnAction,
  requireUserData: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'requireUserData',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  sentOtp: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'sentOtp',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  requirePhoneOtp: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'requirePhoneOtp',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  emailOtpVerificationSent: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'emailOtpVerificationSent',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  emailLinkVerificationSent: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'emailLinkVerificationSent',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  veryContactData: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'veryContactData',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  emailVerified: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'emailVerified',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  geolocationInformative: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'geolocationInformative',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  viewFile: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'viewFile',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  docAlreadySigned: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'docAlreadySigned',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  finishRedirect: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'finishRedirect',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  signDocuments: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'signDocuments',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  confirmAddExtAPI: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'confirmAddExtAPI',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  confirmEditExtAPI: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'confirmEditExtAPI',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  downloadExtAPIFile: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'downloadExtAPIFile',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  cropProfilePicture: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'cropProfilePicture',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  verificationNeeded: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'verificationNeeded',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  confirmDeleteCron: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'confirmDeleteCron',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  confirmEditCron: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'confirmEditCron',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  maxDocsSelected: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'maxDocsSelected',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  maxDocsWithVerifSelected: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'maxDocsWithVerifSelected',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  confirmDeleteDocs: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'confirmDeleteDocs',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  customSuccess: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'customSuccess',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
  privacyNotice: ({ payload, onConfirm, onCancel } = {}) =>
    notificationDispatchUtil.dispatch(
      setDialogStateAction({
        open: true,
        type: 'privacyNotice',
        payload,
        onConfirm: onConfirm || defaultOnAction,
        onCancel: onCancel || defaultOnAction,
      }),
    ),
};
