import React, { useState } from 'react';
import { CircularProgress, Menu, MenuItem, Tooltip } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Wrapper, SpanWrapper, ButtonWrapper, GridWrapper } from './style';
import { HelpDialog } from './HelpDialog';
import { CropperDialog } from './CropperDialog';
import { ConfirmPictureDialog } from './ConfirmPictureDialog';
import 'cropperjs/dist/cropper.css';
import { TextWithIcon } from '..';
// import PencilLine from '../Icons/design/PencilLine';
import DeleteBinLine from '../Icons/system/DeleteBinLine';
import AddLine from '../Icons/system/AddLine';
// import { getBase64FromUrl, getBase64Image } from '../../../utils/imageUtils';

export const ProfilePicture = (props) => {
  const {
    children,
    width = 150,
    // Recommended an aspect ratio of 1:1
    height = width,
    // Recommended -10 from width
    croppedWidth = width - 10,
    isButton,
    acceptedHandler = (files) => {
      console.log('Accepted', files);
    },
    rejectedHandler = (files) => {
      console.log('Rejected', files);
    },
    maxFiles = 1,
    droppedPicture = null,
    openDialog = false,
    setOpenDialog = () => {},
    fullScreen = false,
    handleConfirmPicture = () => {},
    handleDeletePicture = () => {},
    profilePicture = '',
  } = props;

  const [cropper, setCropper] = useState();
  const [croppedPicture, setCroppedPicture] = useState('');
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const [editPicture, setEditPicture] = useState(null);

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
  } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    maxFiles,
    onDropAccepted: acceptedHandler,
    onDropRejected: rejectedHandler,
  });

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCroppedPicture(cropper.getCroppedCanvas().toDataURL());
      setOpenConfirmDialog(true);
      return setOpenDialog(false);
    }
    return null;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (ev) => {
    const type = ev.target.innerText;
    if (type === 'Cargar nueva') {
      openDropzone();
    }
    if (type === 'Eliminar') {
      handleDeletePicture();
    }
    setAnchorEl(null);
  };

  let rootProps = getRootProps();
  if (profilePicture) {
    rootProps = { onClick: handleClick };
  }

  return (
    <Wrapper
      container
      width={width}
      height={height}
      pictureReady={Boolean(profilePicture)}
    >
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={
          profilePicture
            ? 'Da click para abrir menú'
            : 'Se aceptan *.jpeg, *.jpg y *.png, maximo 3mb'
        }
      >
        <SpanWrapper>
          <ButtonWrapper disabled={!isButton} {...rootProps}>
            <input {...getInputProps()} />
            <GridWrapper item>
              {!loaded && profilePicture && (
                <CircularProgress
                  size={width}
                  thickness={1}
                  style={{ position: 'absolute' }}
                />
              )}
              {profilePicture ? (
                <img
                  src={profilePicture}
                  alt="No profile"
                  id="profile-picture"
                  style={{
                    borderRadius: '50%',
                    width: croppedWidth,
                    visibility: loaded ? 'visible' : 'hidden',
                  }}
                  onLoad={() => setLoaded(true)}
                />
              ) : (
                <>{children}</>
              )}
            </GridWrapper>
          </ButtonWrapper>
        </SpanWrapper>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <TextWithIcon iconH={20} IconComponent={AddLine}>
            Cargar nueva
          </TextWithIcon>
        </MenuItem>
        {/* TODO: CORS problem with s3 <MenuItem onClick={handleClose}>
          <TextWithIcon iconH={20} IconComponent={PencilLine}>
            Editar
          </TextWithIcon>
        </MenuItem> */}
        <MenuItem onClick={handleClose}>
          <TextWithIcon iconH={20} IconComponent={DeleteBinLine}>
            Eliminar
          </TextWithIcon>
        </MenuItem>
      </Menu>
      <HelpDialog
        openHelpDialog={openHelpDialog}
        setOpenHelpDialog={setOpenHelpDialog}
      />
      <CropperDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setCropper={setCropper}
        getCropData={getCropData}
        droppedPicture={droppedPicture}
        setOpenHelpDialog={setOpenHelpDialog}
        fullScreen={fullScreen}
      />
      <ConfirmPictureDialog
        width={280}
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        croppedPicture={croppedPicture}
        handleConfirm={() => {
          setOpenConfirmDialog(false);
          handleConfirmPicture({ croppedPicture });
        }}
        handleCancel={() => {
          setOpenConfirmDialog(false);
          setOpenDialog(true);
        }}
        fullScreen={fullScreen}
      />
    </Wrapper>
  );
};
