export const setDevicesHelper = (devices) => {
  const videoDevices = [];
  if (devices.length) {
    devices.forEach((device) => {
      if (device.kind === 'videoinput') {
        videoDevices.push(device);
      }
    });
  }
  return videoDevices;
};
