import { useEffect, useState } from 'react';
import { snackErrorsUtil } from '../../utils/ErrorHandling/snackErrorsUtil';
import { localStorageUtil } from '../../utils/localStorageUtils';
import { userDataAPI } from '../../API/services';

export const useUserData = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const getUserData = async () => {
    try {
      const cachedToken = localStorageUtil.getToken();
      if (!cachedToken) return;

      const userDataRes = await userDataAPI({}, {});
      if (userDataRes.status === 200) {
        setData(userDataRes.data.data);
      } else {
        setError(true);
      }
    } catch (err) {
      console.log(err);
      setError(true);
      snackErrorsUtil.errorOnServer();
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return {
    user: data,
    isError: error,
    isLoading: !error && !data,
    refetch: getUserData,
  };
};
