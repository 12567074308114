import React, { useRef, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Wrapper } from './style';

const keyCodesExcluded = [
  9, 13, 16, 17, 18, 19, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 45, 46,
  91, 92, 93,
];

export const Otp = ({ otp = '', setOtp }) => {
  const arrayOtp = otp.toUpperCase().split('');

  const refs = {
    value1: useRef(null),
    value2: useRef(null),
    value3: useRef(null),
    value4: useRef(null),
    value5: useRef(null),
    value6: useRef(null),
  };
  const [values, setValues] = useState({
    value1: arrayOtp.length >= 1 ? arrayOtp[0] : '',
    value2: arrayOtp.length >= 2 ? arrayOtp[1] : '',
    value3: arrayOtp.length >= 3 ? arrayOtp[2] : '',
    value4: arrayOtp.length >= 4 ? arrayOtp[3] : '',
    value5: arrayOtp.length >= 5 ? arrayOtp[4] : '',
    value6: arrayOtp.length === 6 ? arrayOtp[5] : '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const oldValue = values[name];
    const newValue = value.toUpperCase();
    const newValues = {
      ...values,
      [name]: newValue.replace(oldValue, ''),
    };
    setValues(newValues);
  };

  const handleKeyUp = ({ keyCode, target }) => {
    const { name } = target;
    if (keyCodesExcluded.includes(keyCode)) return;
    const position = parseInt(name.substr(-1));
    if (position < 6 && keyCode !== 8) {
      refs[`value${position + 1}`].current.focus();
    }
    if (position > 1 && keyCode === 8) {
      refs[`value${position - 1}`].current.focus();
    }
    setOtp(Object.values(values).join(''));
  };

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const codePasted = e.clipboardData.getData('text/plain');
    if (codePasted.length === 6) {
      const newValues = {};
      const codeArray = codePasted.split('');
      Object.keys(values).forEach((inputKey, index) => {
        newValues[inputKey] = codeArray[index].toUpperCase();
      });
      setValues(newValues);
      setOtp(Object.values(newValues).join(''));
    }
  };

  return (
    <Wrapper>
      <Grid container spacing={1}>
        {Object.keys(values).map((inputKey, index) => (
          <Grid item xs={2} key={index}>
            <TextField
              variant="outlined"
              inputRef={refs[inputKey]}
              value={values[inputKey]}
              name={inputKey}
              inputProps={{
                style: {
                  textAlign: 'center',
                  fontSize: '1.3rem',
                  fontWeight: 'bold',
                  padding: '18px 5px',
                },
              }}
              style={{ maxWidth: '55px' }}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onPaste={handlePaste}
            />
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  );
};
