import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Wrapper, GridWrapper } from './style';

export const ConfirmPictureDialog = (props) => {
  const {
    openConfirmDialog,
    setOpenConfirmDialog,
    croppedPicture,
    handleConfirm,
    handleCancel,
    width,
    height = width,
    fullScreen = false,
  } = props;

  return (
    <Dialog
      open={openConfirmDialog}
      maxWidth="md"
      onClose={() => setOpenConfirmDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <DialogTitle
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        Confirma tu foto de perfil
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10px',
          paddingBottom: '25px',
          boxSizing: 'border-box',
        }}
      >
        <Wrapper
          container
          height={height}
          width={width}
          pictureReady={Boolean(croppedPicture)}
        >
          <GridWrapper item>
            <img
              style={{ borderRadius: '50%', width }}
              src={croppedPicture}
              alt="no profile pic"
            />
          </GridWrapper>
        </Wrapper>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-evenly',
          paddingTop: '10px',
          paddingBottom: '25px',
          boxSizing: 'border-box',
        }}
      >
        <Button
          onClick={handleCancel}
          variant="text"
          color="secondary"
          style={{ width: '7rem', marginRight: '10px' }}
        >
          Atras
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          style={{ width: '7rem' }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
