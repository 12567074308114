import React, { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { CircularFeedBack } from '../CircularFeedBack';
import { LinearFeedBack } from '../LinearFeedBack';
import { closeBackdropAction } from '../../../../actions';
import {
  useNotificationDispatch,
  useNotificationStore,
} from '../../../../Providers/NotificationProvider';
import { Box, Grid, Typography } from '@mui/material';

export const BackdropFeedback = () => {
  const notifStore = useNotificationStore();
  const notifDispatch = useNotificationDispatch();

  const {
    total,
    current,
    feedbackType = 'circular',
    open,
    clickable = true,
    variant = 'indeterminate',
    text,
  } = notifStore.backdrop;

  useEffect(() => {
    if (total && total === current) {
      setTimeout(() => notifDispatch(closeBackdropAction()), 1000);
    }
  }, [total, current, notifDispatch]);

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1, color: '#fff' }}
      open={open}
      onClick={() => (clickable ? notifDispatch(closeBackdropAction()) : null)}
    >
      <Grid container spacing={3} justifyContent="center">
        {feedbackType === 'circular' ? (
          <Grid item xs={12}>
            <CircularFeedBack
              variant={variant}
              total={total}
              current={current}
              backdrop
              big
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <LinearFeedBack variant={variant} total={total} current={current} />
          </Grid>
        )}
        {text && (
          <Grid item>
            <Typography>
              <Box component="span" fontWeight="bolder">
                {text}
              </Box>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Backdrop>
  );
};
