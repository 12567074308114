import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useStore } from '../../../Store';
// import { validateUnprotectedRoutes } from '../../../utils/validators';
import { localStorageUtil } from '../../../utils/localStorageUtils';
import { dialogErrorsUtil } from '../../../utils/ErrorHandling/dialogErrorUtil';
import { logoutUtil } from '../../../utils/dispatchUtil';
import { setLoadingAction } from '../../../actions';

export const ProtectedRoute = (props) => {
  const history = useHistory();
  const { state } = useStore();
  const { dispatch } = useDispatch();
  const { user } = state;

  useEffect(() => {
    try {
      const userSession = localStorageUtil.getUserSession();
      if (!userSession) {
        logoutUtil();
      }

      const { location } = history;
      if (!userSession?.isAdmin && location.pathname === '/home/admin') {
        logoutUtil();
      }

      const cachedToken = localStorageUtil.getToken();
      if (cachedToken && Date.now() > cachedToken?.exp) {
        dialogErrorsUtil.tokenExpired();
      } else if (!cachedToken) {
        dispatch(setLoadingAction(true));
        setTimeout(() => {
          // FIXME: this is a hack to prevent the redirect to login when token is injected
          // For some reason, the token is injected after the first render
          const cachedToken = localStorageUtil.getToken();
          if (!cachedToken) {
            logoutUtil();
            history.replace({
              pathname: '/login',
              state: { nextPathname: location.pathname },
            });
          } else if (cachedToken && Date.now() < cachedToken?.exp) {
            window.location.reload();
          }
          dispatch(setLoadingAction(false));
        }, 500);
      }
    } catch (err) {
      localStorageUtil.clearUserSession();
    }
  }, [history, user, dispatch]);

  return props.component ? (
    <Route
      path={props.path}
      exact={props.exact}
      strict={props.strict}
      component={props.component}
    />
  ) : (
    <Route path={props.path} exact={props.exact} strict={props.strict}>
      {props.children}
    </Route>
  );
};

// export const ProtectedRoute =  React.memo(ProtectedRouteComp , (prevProps, nextProps) => {
//   return prevProps.path === nextProps.path;
// })
