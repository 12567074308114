import { endpointHandler } from '../../endpoints';

const {
  adminUserDataEndpoint,
  adminUserDocsEndpoint,
  adminUsersEndpoint,
  adminMockPdfDocsEndpoint,
  adminCronsEndpoint,
  adminCronStatusEndpoint,
  adminCronEndpoint,
  adminChargesEndpoint,
} = endpointHandler();

export const adminSendMeDocsAPI = (data, reqConfig, urlVar) => {
  return adminUserDocsEndpoint.post(data, reqConfig, urlVar);
};

export const postAdminMockPdfDocsAPI = (data, reqConfig, urlVar) => {
  return adminMockPdfDocsEndpoint.post(data, reqConfig, urlVar);
};

export const adminDeleteMyDocsAPI = (reqConfig, urlVar) => {
  return adminUserDocsEndpoint.delete(reqConfig, urlVar);
};

export const adminGetAllUsersAPI = (reqConfig, urlVar) => {
  return adminUsersEndpoint.get(reqConfig, urlVar);
};

export const adminGetUserDataAPI = (reqConfig, urlVar) => {
  return adminUserDataEndpoint.get(reqConfig, urlVar);
};

export const adminDeleteUserDataAPI = (reqConfig, urlVar) => {
  return adminUserDataEndpoint.delete(reqConfig, urlVar);
};

export const adminPutUserDataAPI = (data, reqConfig, urlVar) => {
  return adminUserDataEndpoint.put(data, reqConfig, urlVar);
};

export const adminGetAllCronsAPI = (reqConfig, urlVar) => {
  return adminCronsEndpoint.get(reqConfig, urlVar);
};

export const adminPostCronAPI = (data, reqConfig, urlVar) => {
  return adminCronsEndpoint.post(data, reqConfig, urlVar);
};

export const adminPutCronAPI = (data, reqConfig, urlVar) => {
  return adminCronEndpoint.put(data, reqConfig, urlVar);
};

export const adminPutCronStatusAPI = (data, reqConfig, urlVar) => {
  return adminCronStatusEndpoint.put(data, reqConfig, urlVar);
};

export const adminGetCronAPI = (reqConfig, urlVar) => {
  return adminCronEndpoint.get(reqConfig, urlVar);
};

export const adminDeleteCronAPI = (reqConfig, urlVar) => {
  return adminCronEndpoint.delete(reqConfig, urlVar);
};

export const adminGetChargesAPI = (reqConfig, urlVar) => {
  return adminChargesEndpoint.get(reqConfig, urlVar);
};
