import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

const SafariIosInstructions = ({ handleClose }) => {
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-title">{'Intrucciones Safari'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <ol style={{ textAlign: 'left' }}>
            <li>
              Vaya a <strong>Configuración</strong>
            </li>
            <li>
              Busque el navagador <strong>Safari</strong>
            </li>
            <li>
              Deshabilite la opción
              <strong> Evitar rastreo entre sitios.</strong>
            </li>
          </ol>
          <br />
          <br />
          <Link component="button" variant="body1" onClick={handleClose}>
            Regresar
          </Link>
        </DialogContentText>
      </DialogContent>
    </React.Fragment>
  );
};

const ChromeInstructions = ({ handleClose }) => {
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-title">{'Intrucciones Chrome'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <ol style={{ textAlign: 'left' }}>
            <li>
              Vaya a <strong>Configuración</strong>
            </li>
            <li>
              En <strong>Seguridad y privacidad</strong>, haz clic en
              <strong> Cookies y otros datos de sitios</strong>.
            </li>
            <li>
              Seleccione la opción <strong>Permitir todas las cookies</strong>
            </li>
          </ol>
          <br />
          <br />
          <Link component="button" variant="body1" onClick={handleClose}>
            Regresar
          </Link>
        </DialogContentText>
      </DialogContent>
    </React.Fragment>
  );
};

export const CookiesBlockedAlert = () => {
  const [showInstructions, setShowInstructions] = useState({
    show: false,
    type: 'SAFARI-IOS',
  });

  return (
    <Dialog
      open={true}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ textAlign: 'center' }}
    >
      {showInstructions.show && showInstructions.type === 'SAFARI-IOS' && (
        <SafariIosInstructions
          handleClose={() => setShowInstructions({ show: false, type: '' })}
        />
      )}
      {showInstructions.show && showInstructions.type === 'CHROME' && (
        <ChromeInstructions
          handleClose={() => setShowInstructions({ show: false, type: '' })}
        />
      )}
      {!showInstructions.show && (
        <>
          <DialogTitle id="alert-dialog-title">
            {'Cookies Bloqueadas'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Las cookies se encuentran bloqueadas, por favor habilítelas para
              poder continuar.
              <br />
              <br />
              Si estas usando Safari en IOS,{' '}
              <Link
                component="button"
                variant="body1"
                onClick={() =>
                  setShowInstructions({ show: true, type: 'SAFARI-IOS' })
                }
              >
                has clic aquí
              </Link>
              .
              <br />
              <br />
              Si estas usando Google Chrome,{' '}
              <Link
                component="button"
                variant="body1"
                onClick={() =>
                  setShowInstructions({ show: true, type: 'CHROME' })
                }
              >
                has clic aquí
              </Link>
              .
            </DialogContentText>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
