import { endpointHandler } from '../../endpoints';
const {
  testApiEndpoint,
  testPreRegEndpoint,
  testPhoneSMSEndPoint,
  testSESEndpoint,
  testUnverifyUserEndpoint,
} = endpointHandler();

export const testApiCallAPI = () => {
  return testApiEndpoint.get();
};
export const testPreRegAPI = (data, reqConfig, urlVar) => {
  return testPreRegEndpoint.post(data, reqConfig, urlVar);
};

export const testPhoneSMSAPI = (data, reqConfig, urlVar) => {
  return testPhoneSMSEndPoint.post(data, reqConfig, urlVar);
};

export const testSESAPI = (data, reqConfig, urlVar) => {
  return testSESEndpoint.post(data, reqConfig, urlVar);
};

export const testUnverifyUserAPI = (data, reqConfig, urlVar) => {
  return testUnverifyUserEndpoint.put(data, reqConfig, urlVar);
};
