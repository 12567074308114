import { closeDialogAction } from '../../actions';

export const snackStates = {
  invalidConfirmPassword: {
    open: true,
    text: 'Las contraseñas deben ser iguales.',
    err: 'pswdMismatch',
  },
  invalidPassword: {
    open: true,
    text: 'Por tu seguridad, la contraseña debe ser de al menos 8 caracteres',
    err: 'invalidPswd',
  },
};

export const dialogStates = {
  resetTokenFailed: (dispatch, history) => ({
    open: true,
    type: 'resetTokenFailed',
    onConfirm: () => {
      dispatch(closeDialogAction());
      history.push('/login');
    },
    onCancel: () => {},
  }),
  resetPasswordSuccess: (dispatch, history) => ({
    open: true,
    type: 'resetPasswordSuccess',
    onConfirm: () => {
      dispatch(closeDialogAction());
      history.push('/login');
    },
    onCancel: () => {},
  }),
  resetPasswordFailed: (dispatch, history) => ({
    open: true,
    type: 'resetPasswordFailed',
    onConfirm: () => {
      dispatch(closeDialogAction());
      history.push('/login');
    },
    onCancel: () => {},
  }),
};
