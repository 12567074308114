import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

export const TextWithIcon = React.memo(
  (props) => {
    const theme = useTheme();
    const {
      iconFill = theme.palette.text.primary,
      textVar = 'body1',
      IconComponent,
      iconRight,
      iconxs = 3,
      iconsm,
      iconmd,
      textxs = 12 - iconxs,
      textsm = 12 - iconsm,
      textmd = 12 - iconmd,
      txtFontSize = 'inherit',
      alignItems = 'center',
      alignContent = 'center',
      justifyContent = 'center',
      direction = 'row',
      textAlign = 'inherit',
      textColor = 'inherit',
      iconAlign = 'inherit',
      iconMargin = 'auto',
      iconH = 'auto',
      iconW = 'auto',
      spacing = 0,
      wrap,
      children,
      bold,
    } = props;

    return (
      <Grid
        container
        alignItems={alignItems}
        alignContent={alignContent}
        justifyContent={justifyContent}
        direction={iconRight ? 'row-reverse' : direction}
        m={0}
        spacing={spacing || 0}
        wrap={wrap || 'wrap'}
      >
        {IconComponent && (
          <Grid
            item
            xs={iconxs}
            sm={iconsm || 'auto'}
            md={iconmd || 'auto'}
            style={{
              textAlign: iconAlign,
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
          >
            <IconComponent
              fill={iconFill}
              style={{
                display: 'block',
                margin: iconMargin,
                height: iconH,
                width: iconW,
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={textxs || 'auto'}
          sm={textsm || 'auto'}
          md={textmd || 'auto'}
          style={{
            textAlign,
            overflowWrap: 'break-word',
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          <Typography
            variant={textVar}
            component="span"
            color={textColor}
            style={{ fontSize: txtFontSize }}
          >
            <Box fontWeight={bold ? 'fontWeightBold' : 'fontWeightRegular'}>
              {children}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    );
  },
  (prev, next) => prev.children !== next.children,
);
