import { createTheme, darken, lighten } from '@mui/material';

export const customTheme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Source Sans Pro',
    },
    h2: {
      fontFamily: 'Source Sans Pro',
    },
    h3: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 'bold',
      opacity: '0.8',
    },
    h4: {
      fontFamily: 'Source Sans Pro',
      opacity: '0.8',
    },
    h5: {
      fontFamily: 'Source Sans Pro',
      opacity: '0.8',
    },
    h6: {
      fontFamily: 'Source Sans Pro',
      opacity: '0.8',
    },
    subtitle1: {
      fontFamily: 'Source Sans Pro',
      opacity: '0.8',
    },
    subtitle2: {
      fontFamily: 'Source Sans Pro',
      opacity: '0.8',
    },
    body1: {
      fontFamily: 'Karla',
    },
    body2: {
      fontFamily: 'Karla',
    },
    button: {
      fontFamily: 'Karla',
      textTransform: 'none',
    },
    caption: {
      fontFamily: 'Karla',
    },
    overline: {
      fontFamily: 'Karla',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#14cda9',
      hover: '#0e8f76',
      dark: '#0AAC9D',
      selected: 'rgb(219, 247, 242)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f50057',
      hover: '#f50057',
      contrastText: '#ffffff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    tertiary: {
      main: '#1E95FF',
      hover: '#1e5fff',
      contrastText: '#ffffff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    disabled: {
      main: '#bfc0d1',
      contrastText: '#ffffff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
      variants: [
        {
          props: { color: 'warning' },
          style: ({ theme }) => ({
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.warning.light,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.warning.dark,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.warning.main,
              borderWidth: '2px',
            },
          }),
        },
      ],
    },
    MuiInputLabel: {
      variants: [
        {
          props: { color: 'warning' },
          style: ({ theme }) => ({
            color: theme.palette.warning.main,
            '&.Mui-focused': {
              color: theme.palette.warning.main,
            },
          }),
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        colorInherit: {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(20,205,169,0.08)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(20,205,169,0.08)',
          },
          height: 30,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        sizeSmall: {
          padding: '0px 1rem',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.dark,
          textTransform: 'uppercase',
          '&:hover': {
            color: lighten(theme.palette.primary.dark, 0.45),
          },
          '&.MuiTableSortLabel-active': {
            color: darken(theme.palette.primary.dark, 0.25),
          },
        }),
        icon: { color: 'inherit !important' },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.dark,
        }),
        selectIcon: ({ theme }) => ({
          color: theme.palette.primary.dark,
        }),
        actions: ({ theme }) => ({
          color: theme.palette.primary.dark,
          '&:disabled': {
            color: lighten(theme.palette.primary.dark, 0.45),
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '20px',
        },
      },
      variants: [
        {
          props: { fullScreen: true },
          style: ({ theme }) => ({
            [theme.breakpoints.down('sm')]: {
              '& .MuiDialog-paper': { borderRadius: 0 },
            },
          }),
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          opacity: 1,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.5, 1),
          borderRadius: '8px',
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
          '&.Mui-selected .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
  },

  // overrides: {
  //   MuiOutlinedInput: {
  //     root: {
  //       borderRadius: '8px',
  //     },
  //   },
  //   MuiTypography: {
  //     colorPrimary: {
  //       color: colorPalette.primary.main,
  //     },
  //   },
  //   MuiCheckbox: {
  //     colorPrimary: {
  //       '&$checked': {
  //         color: colorPalette.primary.main,
  //       },
  //     },
  //   },
  //   MuiListItem: {
  //     button: {
  //       '&:hover': {
  //         backgroundColor: 'rgba(20,205,169,0.1)',
  //       },
  //     },
  //     root: {
  //       paddingTop: '0px',
  //       paddingBottom: '0px',
  //     },
  //   },
  // },
});
