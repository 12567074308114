import produce from 'immer';
import {
  CLEAR_DOCS,
  LOGOUT,
  SET_DOCS,
  SET_DOCS_PAGINATION,
  SET_PENDING_DOCS,
  SET_SELECTED_DOCS,
  SET_SIGNED_DOCS,
} from '../actions/actionTypes';

const initialState = {
  docsFetched: [],
  docsPagination: { page: 1, pageSize: 10 },
  selectedDocs: [],
  pendingDocs: [],
  signedDocs: [],
};

const docsReducer = produce((state, action) => {
  switch (action.type) {
    case SET_DOCS: {
      const { docs } = action;
      state.docsFetched = docs;
      break;
    }
    case SET_DOCS_PAGINATION: {
      const { pagination } = action;
      state.docsPagination = {
        page: pagination.page,
        pageSize: pagination.pageSize,
      };
      break;
    }
    case SET_SELECTED_DOCS: {
      const { selectedDocs } = action;
      state.selectedDocs = selectedDocs;
      break;
    }
    case SET_SIGNED_DOCS: {
      const { docs } = action;
      state.signedDocs = docs;
      break;
    }
    case SET_PENDING_DOCS: {
      const { docs } = action;
      state.pendingDocs = docs;
      break;
    }
    case CLEAR_DOCS: {
      return initialState;
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return;
  }
}, initialState);

export { initialState };
export default docsReducer;
