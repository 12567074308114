import { environment } from '../../API/instance/createInstance';
import { dialogErrorsUtil } from './dialogErrorUtil';
import { DOMErrors } from './errorConstants';
import { snackErrorsUtil } from './snackErrorsUtil';

export class ErrorHandler {
  constructor({ error, message, code }) {
    this.name = 'handler';
    this.error = error ? error : 'UnhandledError';
    this.message = message ? message : error?.message;
    this.code = error?.code || code;
    this.mappedError = this.mapError();
    this.log = environment !== 'prod';
  }
  logError = (override) => {
    if (!this.log && !override) return this;
    const error = this.error ? this.error : 'no error provided';
    console.log('error:', error);
    console.log('mappedError:', this.mappedError);
    return this;
  };
  logMessage = (override) => {
    if (!this.log && !override) return this;
    const message = this.message ? this.message : 'no message provided';
    console.log('message:', message);
    return this;
  };
  logAll = (override) => {
    this.logError(override);
    this.logMessage(override);
    return this;
  };
  mapError = () => {
    if (this.error instanceof window.GeolocationPositionError) {
      this.mappedError = DOMErrors.geoLocation[this.error.code];
    }
    return this;
  };
  act = (actions) => {
    actions.forEach((action) => {
      switch (action.type) {
        case 'dialog':
          dialogErrorsUtil[action.id](action.payload);
          break;
        case 'snack':
          snackErrorsUtil[action.id](action.payload);
          break;
        default:
          return this;
      }
    });
    return this;
  };
  throwError = () => {
    const newError = new Error(
      `${this.name} - ${this.mappedError} - ${this.message}`,
    );
    newError.handlerObject = {
      error: this.error,
      message: this.message,
      code: this.code,
      mappedError: this.mappedError,
    };
    throw newError;
  };
  returnError = () => ({
    error: this.error,
    message: this.message,
    code: this.code,
    mappedError: this.mappedError,
  });
}
