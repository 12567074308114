import { styled } from '@mui/system';
import { Paper } from '@mui/material';

export const PaperWrapper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: 'left',
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '16px',
}));
