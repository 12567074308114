import { setUserAction } from '../actions';
import { dispatchUtil } from './dispatchUtil';

export const localStorageUtil = {
  setUserSession: (userData) => {
    try {
      const { token, ...userInfo } = userData;
      localStorage.setItem('user', JSON.stringify(userInfo));
      localStorage.setItem('token', JSON.stringify(token));
      dispatchUtil.dispatch(setUserAction(userInfo));
    } catch (err) {
      console.log(err);
    }
  },
  setUserData: (userData) => {
    try {
      localStorage.setItem('user', JSON.stringify(userData));
      dispatchUtil.dispatch(setUserAction(userData));
    } catch (err) {
      console.error(err);
    }
  },
  clearUserSession: () => {
    try {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('verifProcessData');
      localStorage.removeItem('verifConcludedData');
    } catch (err) {
      console.error(err);
    }
  },
  setQueryParams: (search, email, rfc, userFieldModified) => {
    try {
      const parsedParams =
        search &&
        JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === '' ? value : decodeURIComponent(value);
          },
        );
      localStorage.setItem('redirect', parsedParams.redirect);
      if (
        parsedParams?.user?.includes('@') &&
        !parsedParams?.user?.includes('SOR@') &&
        !email &&
        !userFieldModified
      ) {
        const { user } = parsedParams;
        return { form: 'email', user };
      } else if (parsedParams?.user && !rfc && !email && !userFieldModified) {
        const { user } = parsedParams;
        return { form: 'rfc', user };
      }
      return null;
    } catch (err) {}
  },
  setGeolocationAsked: (asked) => {
    if (asked) {
      return localStorage.setItem('geolocationAsked', true);
    }
    return localStorage.setItem('geolocationAsked', false);
  },
  getUserSession: () => {
    const cachedUser = localStorage.getItem('user');
    if (cachedUser && cachedUser !== 'undefined') {
      const parsedUser = JSON.parse(cachedUser);
      return parsedUser;
    }
    return null;
  },
  getToken: () => {
    const cachedToken = localStorage.getItem('token');
    if (cachedToken) {
      return JSON.parse(cachedToken);
    }
    return '';
  },
  setDeviceInfo: (deviceInfo) => {
    localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
  },
  getDeviceInfo: () => {
    const cachedDeviceInfo = localStorage.getItem('deviceInfo');
    if (cachedDeviceInfo) {
      return JSON.parse(cachedDeviceInfo);
    }
  },
  getGeolocationAsked: () => {
    console.log(localStorage.getItem('geolocationAsked'));
  },
  setCustomParams: ({ name, value }) => {
    localStorage.setItem(name, value);
  },
  setVerifProcessData: (data) => {
    localStorage.setItem('verifProcessData', JSON.stringify(data));
  },
  getVerifProcessData: () => {
    const cachedToken = localStorage.getItem('verifProcessData');
    if (cachedToken) return JSON.parse(cachedToken);
    return null;
  },
  setVerifConcludedData: (data) => {
    localStorage.setItem('verifConcludedData', JSON.stringify(data));
  },
  getVerifConcludedData: () => {
    const cachedToken = localStorage.getItem('verifConcludedData');
    if (cachedToken) return JSON.parse(cachedToken);
    return null;
  },
  removeVerifConcludedData: () => {
    try {
      localStorage.removeItem('verifConcludedData');
    } catch (err) {
      console.error(err);
    }
  },
  getCustomParams: ({ name }) => {
    const param = localStorage.getItem(name);
    if (param) {
      return param;
    }
    return null;
  },
};
