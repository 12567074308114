import { trustedOrigins } from '../API/instance/createInstance';
import { localStorageUtil } from './localStorageUtils';
import { getPropertyFromToken } from './funcUtils';

export const windowUtil = {
  preventAutoClose: function (e) {
    e.preventDefault();
    e.returnValue = '';
  },
};

export function handleSifeMessage(e) {
  if (/^react-devtools/gi.test(e.data.source)) {
    e.target.removeEventListener('message', handleSifeMessage);
    return null;
  }
}

export const windowPostUtil = ({ data, cbOnSuccess, cbOnFail, message }) => {
  function messgeReceiver(event) {
    if (/^react-devtools/gi.test(event.data.source)) {
      return null;
    }
    window.removeEventListener('message', messgeReceiver, false);
    const { data } = event;
    if (!data.success) {
      cbOnFail();
    } else {
      cbOnSuccess();
    }
    return data.success;
  }

  localStorageUtil.setUserSession(data.data);

  const sifeUser = {
    id: data.data._id,
    email: data.data.email,
    rfc: data.data.rfc,
    name: data.data.name,
    lastNameP: data.data.lastNameP,
    lastNameM: data.data.lastNameM,
    token_sife: localStorageUtil.getToken().token,
  };

  sendPostMessage({ payload: sifeUser, closeWindow: false });
  window.addEventListener('message', messgeReceiver);

  return;
};

function handleMessage(event) {
  if (/^react-devtools/gi.test(event.data.source)) {
    // event.target.removeEventListener('message', handleMessage);
    return null;
  }

  if (!trustedOrigins.includes(event.origin)) {
    return null;
  }

  sessionStorage.setItem('parentWindowLocation', event.origin);
  if (event.data.type === 'INJ_USER') {
    const { user } = event.data.payload;
    const token = localStorageUtil.getToken();
    localStorageUtil.setUserSession(user);
    if (token && Date.now() > token.exp) {
      sendPostMessage({ payload: { message: 'TOKEN_EXPIRED' } });
      // window.location.reload();
    }
  }
  if (event.data.message === 'openerMessage') {
    window.removeEventListener('message', handleMessage);
  }
}

export const setMessageReceiver = function () {
  window.addEventListener('message', handleMessage, false);
  sendPostMessage({ payload: { message: 'openedReady' }, closeWindow: false });
};

export const sendPostMessage = ({ payload, closeWindow = true }) => {
  const originUrl = getPropertyFromToken({
    token: localStorageUtil.getToken().token,
    property: 'originUrl',
  });
  if (originUrl && !trustedOrigins.includes(originUrl)) {
    trustedOrigins.push(originUrl);
  }

  // send postMessage if popup is opened
  if (window.opener) {
    trustedOrigins.forEach((origin) => {
      window.opener.postMessage(payload, origin);
    });

    if (closeWindow) {
      window.close();
    }
  }

  // send postMessage if iframe is opened
  if (window.self !== window.top) {
    trustedOrigins.forEach((origin) => {
      window.parent.postMessage(payload, origin);
    });
  }
};

export const inIframe = () => window.self !== window.top;
