import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useController } from 'react-hook-form';

export const CheckboxControlled = ({
  control,
  name,
  muiProps,
  callbackChange = () => {},
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <FormControlLabel
      control={<Checkbox color="primary" />}
      checked={value}
      onChange={(e) => {
        callbackChange(e);
        onChange(e.target.checked);
      }}
      {...muiProps}
    />
  );
};
