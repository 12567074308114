import { globalState } from './globalState';
import produce from 'immer';

import {
  SET_USER,
  LOGOUT,
  SET_ACTIVE_STEP,
  CLEAR_WIZARD,
  SET_LOADING,
  SET_DOCS,
  SET_DIALOG_STATE,
  SET_SELECTED_DOCS,
  SET_DEVICE_INFO,
  SET_GEOLOCACTION,
  SET_SNACK_STATE,
  CLOSE_DIALOG,
  CLEAR_DOCS,
  SET_LANGUAGE,
} from './actions/actionTypes';

const mainReducer = produce((state = globalState, action) => {
  switch (action.type) {
    case SET_LOADING: {
      state.loading = action.state;
      break;
    }
    case SET_DEVICE_INFO: {
      state.deviceInfo = action.payload;
      break;
    }
    case SET_GEOLOCACTION: {
      state.deviceInfo.geolocation = action.payload;
      break;
    }
    case SET_ACTIVE_STEP: {
      const { step, type } = action.payload;
      state[type].activeStep = step;
      break;
    }
    case CLEAR_WIZARD: {
      // state.verify = globalState.verify;
      state.sign = {
        steps: [
          'Crea tu firma electrónica',
          'Selecciona tus documentos',
          'Firmar documento(s)',
        ],
        activeStep: 0,
      };
      break;
    }
    case SET_DOCS: {
      const { docs } = action;
      state.docs.docsFetched = docs;
      break;
    }
    case SET_SELECTED_DOCS: {
      const { selectedDocs } = action;
      state.docs.selectedDocs = selectedDocs;
      break;
    }
    case CLEAR_DOCS: {
      state.docs.docsFetched = [];
      state.docs.selectedDocs = [];
      break;
    }
    case SET_DIALOG_STATE: {
      const { dialogState } = action;
      state.dialog = dialogState;
      break;
    }
    case CLOSE_DIALOG: {
      state.dialog = {
        open: false,
        type: '',
        onConfirm: () => {},
        onCancel: () => {},
      };
      break;
    }
    case SET_SNACK_STATE: {
      const { snackState } = action;
      state.snack = snackState;
      break;
    }
    case SET_USER: {
      const { user } = action;
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      break;
    }
    case LOGOUT: {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('initVerify');
      return globalState;
    }
    case SET_LANGUAGE: {
      const { language } = action;
      state.language = language;
      break;
    }
    default: {
      return;
    }
  }
}, globalState);

export default mainReducer;
