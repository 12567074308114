import React from 'react';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useController } from 'react-hook-form';

export const SelectControlled = ({
  control,
  name,
  muiProps,
  options = [],
  callbackChange = () => {},
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl fullWidth>
      <InputLabel id="simple-select-label">{muiProps.label || ''}</InputLabel>
      <Select
        labelId="simple-select-label"
        value={value}
        label={muiProps.label || ''}
        onChange={(e) => {
          callbackChange(e);
          onChange(e);
        }}
        {...muiProps}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
