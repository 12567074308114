import produce from 'immer';
import {
  CLEAR_WIZARD,
  SET_ACTIVE_STEP,
  SET_ID_BACK_PIC,
  SET_ID_FRONT_PIC,
  SET_ID_TYPE,
  SET_SELFIE_PIC,
  SET_USED_CAMERA,
  SET_SELFIE_READY,
  SET_ID_READY,
  SET_ID_BACK_READY,
  LOGOUT,
  SET_VERIFY_RES,
  RESET_VERIFY_RES,
  SET_DEV_VERIFY,
} from '../actions/actionTypes';

const initialState = {
  devVerify: false,
  idType: 'INEIFE',
  INEIFE: {
    activeStep: 0,
    steps: [
      'Captura el frente de tu INE/IFE',
      'Captura el reverso de tu INE/IFE',
      'Captura tu selfie',
    ],
    text: 'INE/IFE',
    0: {
      id: 'front',
      imageData: {
        dataUrl: '',
        width: 0,
        height: 0,
      },
      ready: false,
      verifyRes: {},
      tries: 0,
      showOverlayGuide: true,
      usedCamera: 'environment',
    },
    1: {
      id: 'back',
      imageData: {
        dataUrl: '',
        width: 0,
        height: 0,
      },
      ready: false,
      verifyRes: {},
      tries: 0,
      showOverlayGuide: true,
      usedCamera: 'environment',
    },
    2: {
      id: 'selfie',
      imageData: {
        dataUrl: '',
        width: 0,
        height: 0,
      },
      ready: false,
      verifyRes: {},
      tries: 0,
      showOverlayGuide: false,
      usedCamera: 'user',
    },
  },
  PASSPORT: {
    activeStep: 0,
    steps: ['Captura el frente de tu PASAPORTE', 'Captura tu selfie'],
    text: 'Pasaporte',
    0: {
      id: 'front',
      imageData: {
        dataUrl: '',
        width: 0,
        height: 0,
      },
      ready: false,
      verifyRes: {},
      tries: 0,
      showOverlayGuide: true,
      usedCamera: 'environment',
    },
    1: {
      id: 'selfie',
      imageData: {
        dataUrl: '',
        width: 0,
        height: 0,
      },
      ready: false,
      verifyRes: {},
      tries: 0,
      showOverlayGuide: false,
      usedCamera: 'user',
    },
  },
  OTHER: {
    activeStep: 0,
    steps: ['Captura el frente de tu ID', 'Captura tu selfie'],
    text: 'ID',
    0: {
      id: 'front',
      imageData: {
        dataUrl: '',
        width: 0,
        height: 0,
      },
      ready: false,
      verifyRes: {},
      tries: 0,
      showOverlayGuide: true,
      usedCamera: 'environment',
    },
    1: {
      id: 'selfie',
      imageData: {
        dataUrl: '',
        width: 0,
        height: 0,
      },
      ready: false,
      verifyRes: {},
      tries: 0,
      showOverlayGuide: false,
      usedCamera: 'user',
    },
  },
};

const verifyReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_ID_TYPE: {
      state.idType = action.idType;
      break;
    }
    case SET_ID_FRONT_PIC: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].imageData = action.imageData;
      break;
    }
    case SET_ID_READY: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].ready = action.payload;
      break;
    }
    case SET_ID_BACK_PIC: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].imageData = action.imageData;
      break;
    }
    case SET_ID_BACK_READY: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].ready = action.payload;
      break;
    }
    case SET_SELFIE_PIC: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].imageData = action.imageData;
      break;
    }
    case SET_SELFIE_READY: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].ready = action.payload;
      break;
    }
    case SET_USED_CAMERA: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].usedCamera = action.usedCamera;
      break;
    }
    case SET_VERIFY_RES: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      let tries = selectedIdState[activeStep].tries;
      selectedIdState[activeStep].tries = tries += 1;
      selectedIdState[activeStep].verifyRes = action.payload;
      selectedIdState[activeStep].ready = action.payload.success;
      break;
    }
    case RESET_VERIFY_RES: {
      const selectedIdState = state[state.idType];
      const activeStep = selectedIdState.activeStep;
      selectedIdState[activeStep].verifyRes = {};
      break;
    }
    case SET_ACTIVE_STEP: {
      const { step } = action.payload;
      const selectedIdState = state[state.idType];
      selectedIdState.activeStep = step;
      break;
    }
    case SET_DEV_VERIFY: {
      state.devVerify = !state.devVerify;
      break;
    }
    case CLEAR_WIZARD: {
      return initialState;
    }
    case LOGOUT: {
      return initialState;
    }
    default: {
      return;
    }
  }
});
export { initialState };
export default verifyReducer;
