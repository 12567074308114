import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  border-radius: 5;
  justify-content: center;
`;

export const Form = styled('form')`
  width: 100%;
  margin-top: 1rem;
`;

export const Contents = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inputText: {
    textAlign: 'center',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    padding: '18px 5px',
  },
});
