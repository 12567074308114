import { environment } from '../API/instance/createInstance';

export const loggerUtil = {
  errorLogger: (error) => {
    if (environment === 'dev' || environment === 'local') {
      console.log(error);
    }
  },
  requestLog: (config) => {
    if (environment === 'dev' || environment === 'local') {
      console.log('-->', {
        METHOD: config.method.toUpperCase(),
        URL: config.url,
        config,
        BASE: config.baseURL,
      });
    }
  },
  responseLog: (res) => {
    if (environment === 'dev' || environment === 'local') {
      console.log(`<--`, {
        STATUS: res.status,
        URL: res.config.url,
        METHOD: res.config.method.toUpperCase(),
        data: res.data,
        BASE: res.config.baseURL,
      });
    }
  },
};
