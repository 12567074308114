import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import 'react-phone-number-input/style.css';

export const MUIPhoneField = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      label="Teléfono celular"
      variant="outlined"
      name="phone"
    />
  );
});
