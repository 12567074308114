import { getContactMethodsAPI, sendRecoveryAPI } from '../../API/services';
import { globalFeedbackUtil } from '../../utils/FeedbackUtils/globalUtils';
import { dialogErrorsUtil } from '../../utils/ErrorHandling/dialogErrorUtil';
import { snackErrorsUtil } from '../../utils/ErrorHandling/snackErrorsUtil';

const contactMethodsErrors = {
  404: {
    USER_DOES_NOT_EXIST: {
      action: () => dialogErrorsUtil.userNotFound(),
    },
    CONTACT_METHODS_NOT_FOUND: {
      action: () =>
        dialogErrorsUtil.customError({
          payload: {
            title: 'Sin métodos de contacto',
            message: 'No cuentas con ningún método de contacto.',
          },
        }),
    },
  },
  500: {
    INTERNAL_EXCEPTION: {
      action: () => snackErrorsUtil.errorOnServer(),
    },
  },
  default: () =>
    dialogErrorsUtil.customError({
      payload: {
        title: '😟 Opps hubo un error',
        message: 'Hubo un error al validar la información del usuario.',
      },
    }),
};

const recoveryErrors = {
  404: {
    USER_DOES_NOT_EXIST: {
      action: () => dialogErrorsUtil.userNotFound(),
    },
  },
  400: {
    USER_INCOMPLETE_REGISTRATION: {
      action: () =>
        dialogErrorsUtil.customError({
          payload: {
            title: 'Registro incompleto',
            message:
              'Enlace de recuperación no enviado, debido a que aún no has completado tu registro.',
          },
        }),
    },
  },
  500: {
    INTERNAL_EXCEPTION: {
      action: () => snackErrorsUtil.errorOnServer(),
    },
  },
  default: () =>
    dialogErrorsUtil.customError({
      payload: {
        title: '😟 Opps hubo un error',
        message: 'Hubo un error al enviar en enlace de recuperación.',
      },
    }),
};

export const getContactMethods = async ({
  user,
  callbackSuccess = () => {},
}) => {
  try {
    globalFeedbackUtil.setBackdropIndeterminate({
      text: 'Validando usuario...',
      clickable: false,
    });

    const { data, status } = await getContactMethodsAPI({}, { user });

    if (status === 200) {
      callbackSuccess(data.data.contactMethods);
    } else {
      const { errors } = data;
      const error = contactMethodsErrors[status][errors[0].type];
      if (error && error.action) error?.action();
      else contactMethodsErrors.default();
    }
  } catch (err) {
    console.log(err);
    contactMethodsErrors.default();
  } finally {
    globalFeedbackUtil.closeBackdrop();
  }
};

export const sendRecovery = async ({
  user,
  contactMethod,
  callbackSuccess = () => {},
}) => {
  try {
    globalFeedbackUtil.setBackdropIndeterminate({
      text: 'Enviando liga de recuperación...',
      clickable: false,
    });

    const { data, status } = await sendRecoveryAPI(
      { contactMethod },
      {},
      { user },
    );

    if (status === 200) {
      callbackSuccess(data.data);
    } else {
      const { errors } = data;
      const error = recoveryErrors[status][errors[0].type];
      if (error && error.action) error?.action();
      else recoveryErrors.default();
    }
  } catch (err) {
    console.log(err);
  } finally {
    globalFeedbackUtil.closeBackdrop();
  }
};
