import { useEffect, useState } from 'react';

export const useIntersectionObserver = (reference) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
          observer.disconnect();
        }
      });
    };
    // Create the observer, passing in the callback
    const observer = new IntersectionObserver(handleIntersect, {
      // rootMargin: '0px 0px -20%',
      // threshold: 0.5,
    });

    // If we have a ref value, start observing it
    if (reference) {
      observer.observe(reference.current);
    }

    // If unmounting, disconnect the observer
    return () => observer.disconnect();
  }, [reference]);

  return isVisible;
};
