import React from 'react';

export default function QuillPenLine(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="remix-icons/outlined/design/quill-pen-line">
        <g id="Group">
          <path
            id="Vector"
            d="M6.94 14.036C6.707 14.66 6.51 15.236 6.334 15.819C7.294 15.122 8.435 14.68 9.752 14.515C12.265 14.201 14.498 12.542 15.628 10.457L14.172 9.002L15.585 7.587L16.585 6.586C17.015 6.156 17.5 5.362 18.013 4.218C12.42 5.085 8.995 8.51 6.939 14.036H6.94ZM17 9.001L18 10C17 13 14 16 10 16.5C7.331 16.834 5.664 18.667 4.998 22H3C4 16 6 2 21 2C20 4.997 19.002 6.996 18.003 7.997L17 9.001Z"
            fill={props.fill ? props.fill : 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
}
