import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import ArrowLeftLineIcon from '../_common/Icons/system/ArrowLeftLine';
import { Otp } from '../_common/Otp';
import { Form } from './style';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../Store';
import {
  emailVerificationAPI,
  sendEmailVerificationAPI,
} from '../../API/services';
import { dialogUtil } from '../../utils/dialogUtils';
import { snackUtil } from '../../utils/snackUtils';
import { snackErrorsUtil } from '../../utils/ErrorHandling/snackErrorsUtil';
import { globalFeedbackUtil } from '../../utils/FeedbackUtils/globalUtils';
import { typeInErrors } from '../../utils/ErrorHandling/errorUtil';
import { useLanguage } from '../../Hooks';
import { i18n } from './i18n';

export const VerifyOtp = ({
  type = 'email',
  noBack = false,
  noLogo = false,
  noMargin = false,
  noDesc = false,
  noSendAgain = false,
  noButton = false,
  overrideSendAgain,
  overrideSubmit,
  noHeight = false,
}) => {
  const {
    state: { user },
  } = useStore();
  const history = useHistory();
  const { translations, lang } = useLanguage({ i18n });
  const [otp, setOtp] = useState('');
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(false);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (overrideSubmit) {
        return overrideSubmit(otp);
      }
      globalFeedbackUtil.setBackdropIndeterminate({
        clickable: true,
        text: translations?.texts?.text3,
      });

      let verifyOtpRes;
      const body = { token: otp };
      verifyOtpRes = await emailVerificationAPI(body, {}, { user: user._id });

      if (typeInErrors(verifyOtpRes.data.errors, 'OTP_MISMATCH')) {
        globalFeedbackUtil.closeBackdrop();
        return snackErrorsUtil.invalidOtp({ lang });
      }

      if (typeInErrors(verifyOtpRes.data.errors, 'OTP_EXPIRED')) {
        globalFeedbackUtil.closeBackdrop();
        return snackErrorsUtil.otpExpired({ lang });
      }

      // Disable button
      setDisableVerifyBtn(true);
      // Define callbacks
      const onConfirm = () => {
        const { from, prevSearch } = history.location.state;
        if (from.includes('signup')) {
          if (from.includes('ext')) {
            history.push('/ext-login' + prevSearch);
          } else {
            history.push('/login' + prevSearch);
          }
          snackUtil.loginAgainPlease({ lang });
        } else if (from.includes('login')) {
          snackUtil.loginAgainPlease({ lang });
          history.goBack();
        }
        dialogUtil.defaultOnAction();
      };

      dialogUtil.emailVerified({ onConfirm });
      globalFeedbackUtil.closeBackdrop();
    } catch (err) {
      /* handle error */
      globalFeedbackUtil.closeBackdrop();
      return snackErrorsUtil.errorOnServer({ lang });
    }
  }

  const handleSendAgain = async () => {
    try {
      globalFeedbackUtil.setBackdropIndeterminate({
        clickable: true,
        text: translations?.texts?.text4,
      });

      if (overrideSendAgain) {
        return overrideSendAgain();
      }

      const body = { email: user.email, emailVerificationType: 'otp' };
      const sendEmailVerifRes = await sendEmailVerificationAPI(body, {}, {});

      globalFeedbackUtil.closeBackdrop();
      if (sendEmailVerifRes?.status === 201) {
        dialogUtil.sentOtp({ payload: { email: user.email } });
        snackUtil.sentOtp({ lang });
      }
    } catch (err) {
      /* handle error */
      globalFeedbackUtil.closeBackdrop();
      history.push('/');
      console.error(err);
    }
  };

  const handleGoBack = () => {
    setOtp('');
    history.goBack();
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: noHeight ? undefined : '100vh',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: (theme) => (!noMargin ? theme.spacing(8) : 0),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {!noLogo && (
          <img
            src={process.env.PUBLIC_URL + '/assets/imgs/logo_sife_crop.png'}
            alt=""
            height="65px"
            width="auto"
            style={{ margin: '10px' }}
          />
        )}
        <Grid container spacing={2}>
          <Form noMargin={noMargin} onSubmit={handleSubmit}>
            {!noBack && (
              <IconButton onClick={handleGoBack} size="small">
                <ArrowLeftLineIcon />
              </IconButton>
            )}
            <Typography
              variant="body1"
              style={{
                fontWeight: 'bold',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
              }}
            >
              {translations?.titles?.title1}
            </Typography>
            {!noDesc && <p>{translations?.texts?.text1}</p>}
            <div>
              <Otp otp={otp} setOtp={(val) => setOtp(val)} />
              {!noSendAgain && (
                <div>
                  {translations?.texts?.text2}{' '}
                  <Button
                    onClick={handleSendAgain}
                    disabled={disableVerifyBtn}
                    color="primary"
                  >
                    {translations?.buttons?.button2}
                  </Button>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 20,
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={otp.length !== 6 || disableVerifyBtn}
                  color="primary"
                  fullWidth
                  sx={{ margin: (theme) => theme.spacing(3, 0, 2) }}
                >
                  {translations?.buttons?.button1}
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginTop: 10,
                }}
              ></div>
            </div>
          </Form>
        </Grid>
      </Box>
    </Container>
  );
};
