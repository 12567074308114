import produce from 'immer';
import {
  SET_SNACK_STATE,
  SET_DIALOG_STATE,
  CLOSE_DIALOG,
  LOGOUT,
  SET_BACKDROP,
  CLOSE_BACKDROP,
} from '../actions/actionTypes';

const initialState = {
  snack: {
    open: false,
    text: '',
    err: '',
    position: '',
    severity: 'success',
    variant: 'filled',
    changerFunc: () => {},
    duration: 10000,
  },
  dialog: {
    open: false,
    type: '',
    onConfirm: () => {},
    onCancel: () => {},
    payload: {},
  },
  backdrop: {
    feedbackType: '',
    total: 0,
    current: 0,
    open: false,
    clickable: true,
  },
};

const notificationReducer = produce((state, action) => {
  switch (action.type) {
    case SET_SNACK_STATE: {
      state.snack = action.snackState;
      break;
    }
    case SET_DIALOG_STATE: {
      state.dialog = action.dialogState;
      break;
    }
    case CLOSE_DIALOG: {
      state.dialog = {
        open: false,
        type: '',
        onConfirm: () => {},
        onCancel: () => {},
      };
      break;
    }
    case SET_BACKDROP: {
      state.backdrop.open = true;
      state.backdrop = action.state;
      break;
    }
    case CLOSE_BACKDROP: {
      state.backdrop.open = false;
      state.backdrop = action.state;
      break;
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return;
  }
}, initialState);

export { initialState };
export default notificationReducer;
