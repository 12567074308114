import { styled } from '@mui/system';

export const DialogOptionsContents = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1rem',
  '& p': {
    textAlign: 'center',
  },
}));

export const FormDialog = styled('form')(() => ({
  padding: '1rem',
}));
