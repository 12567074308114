import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SendRecoveryForm } from './SendRecoveryForm';
import { Wrapper } from './style';
import { Container, CssBaseline } from '@mui/material';

export const SendRecovery = (props) => {
  const [payload, setPayload] = useState({});
  const [step, setStep] = useState(1);

  const defaultValues = { user: '', contactMethod: '' };
  const form = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleUpdatePayload = (newData = {}) => {
    setPayload({ ...payload, ...newData });
  };

  return (
    <Wrapper>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ paddingRight: '16px !important', paddingLeft: '16px !important' }}
      >
        <CssBaseline />
        <SendRecoveryForm
          form={form}
          step={step}
          payload={payload}
          handleNext={handleNext}
          handleUpdatePayload={handleUpdatePayload}
        />
      </Container>
    </Wrapper>
  );
};
