import React, { useState, useRef, useEffect, Suspense } from 'react';
import {
  Box,
  Dialog,
  Button,
  DialogActions,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormDialog } from './style';
import {
  useNotificationDispatch,
  useNotificationStore,
} from '../../../../Providers/NotificationProvider';
import { dialogOptions } from './dialogOptions';
import { useForm } from '../../../../Hooks/useForm';
import { closeDialogAction } from '../../../../actions';
import { DialogToShow } from './Dialogs';

export const MUIDialog = React.memo(
  () => {
    const [password, setPassword] = useState('');
    const [visibility, setVisibility] = useState(false);
    const [visibility2, setVisibility2] = useState(false);
    const [visibility3, setVisibility3] = useState(false);
    const { dialog } = useNotificationStore();
    const notifDispatch = useNotificationDispatch();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const passwordRef = useRef();

    const {
      open,
      type,
      onConfirm = () => notifDispatch(closeDialogAction()),
      onCancel = () => notifDispatch(closeDialogAction()),
      payload = {},
    } = dialog;

    const form = useForm({
      initialValues: payload?.initialState,
      formValidations: payload?.formValidations,
      onSubmit: (args) => {
        onConfirm(args, form.handleForceError, form.resetForm);
      },
      dontReset: payload?.dontReset || true,
    });

    useEffect(() => {
      if (!open && Object.keys(form.data).length) {
        form.resetForm();
      }
      if (payload?.autoConfirmTimeout) {
        setTimeout(() => {
          onConfirm();
        }, payload.autoConfirmTimeout);
      }
    }, [open, form, onConfirm, payload?.autoConfirmTimeout]);

    const onConfirmBtnStyle = () => {
      if (type === 'finishRedirect') {
        return { color: 'secondary', variant: 'contained' };
      }
      return { color: 'primary', variant: 'contained' };
    };

    const handleWindowOpen = (url, name) => window.open(url, name);

    function handleConfirm() {
      if (type === 'revokeSignature') {
        return onConfirm(password, passwordRef);
      }

      onConfirm();
    }
    const handleCancel = () => {
      if (type === 'signDocuments' || type === 'resetPasswordInternal') {
        form.resetForm();
      }
      return onCancel();
    };

    if (DialogToShow[type]) {
      const DialogToRender = DialogToShow[type];
      return (
        <Suspense fallback="Loading...">
          <DialogToRender
            open={open}
            onCancel={onCancel}
            onConfirm={onConfirm}
            payload={payload}
          />
        </Suspense>
      );
    }

    if (type) {
      const dialogToRender = dialogOptions({
        password,
        setPassword,
        visibility,
        setVisibility,
        visibility2,
        setVisibility2,
        visibility3,
        setVisibility3,
        passwordRef,
        handleWindowOpen,
        payload,
        form,
        theme,
      })[type];

      const { contents, buttons, img } = dialogToRender();

      return (
        <Box>
          <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={type === 'viewFile' ? 'xl' : 'sm'}
            fullScreen={fullScreen}
          >
            {img && img}
            {contents}
            <DialogActions
              style={{
                justifyContent: fullScreen ? 'space-evenly' : 'flex-end',
              }}
            >
              <FormDialog onSubmit={form.handleSubmit}>
                {buttons.cancel && (
                  <Button
                    onClick={handleCancel}
                    variant="text"
                    color="secondary"
                    style={{ width: '7rem', marginRight: '10px' }}
                  >
                    {buttons.cancel}
                  </Button>
                )}
                {buttons.confirm && (
                  <Button
                    onClick={
                      type === 'signDocuments' ||
                      type === 'resetPasswordInternal' ||
                      type === 'requireUserData'
                        ? () => {}
                        : handleConfirm
                    }
                    color={onConfirmBtnStyle().color}
                    variant={onConfirmBtnStyle().variant}
                    style={{ width: '7rem' }}
                    type={
                      type === 'signDocuments' ||
                      type === 'resetPasswordInternal' ||
                      type === 'requireUserData'
                        ? 'submit'
                        : 'button'
                    }
                  >
                    {buttons.confirm}
                  </Button>
                )}
              </FormDialog>
            </DialogActions>
          </Dialog>
        </Box>
      );
    }
    return null;
  },
  (prev, next) => prev.type !== next.type,
);
