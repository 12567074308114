import { Grid } from '@mui/material';
import React from 'react';
import { supportEmail } from '../../../utils/constants';
import { useLanguage } from '../../../Hooks';

export const SupportEmailMessage = () => {
  const i18n = {
    es: { texts: { text1: 'Correo de soporte:' } },
    en: { texts: { text1: 'Support email:' } },
  };
  const { translations } = useLanguage({ i18n });

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems="center"
      spacing={1}
      style={{ textAlign: 'center' }}
    >
      <Grid item xs={10}>
        <strong>{translations?.texts?.text1}</strong>
      </Grid>
      <Grid item xs={12}>
        <strong style={{ color: '#673ab7', fontSize: '1.1rem' }}>
          {supportEmail}
        </strong>
      </Grid>
      <br />
      <br />
    </Grid>
  );
};
