import React from 'react';
import { CircularProgress, Box } from '@mui/material';

export const MUICircularProgress = (props) => {
  const { duration } = props;
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const interval = 250;
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + interval);
    }, interval);

    if (progress >= duration) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [duration, progress]);

  const getProgressPercent = (total, current) => {
    let percent = Math.ceil((current * 100) / (total - 500));
    // console.log(percent);
    if (percent >= 100) {
      percent = 100;
    }

    return percent;
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <CircularProgress
        style={{
          position: 'absolute',
          top: '6px',
          left: '6px',
          color: 'white',
          width: '1em',
          height: '1em',
        }}
        variant="determinate"
        value={getProgressPercent(duration, progress)}
      />
    </Box>
  );
};
