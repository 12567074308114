import {
  SET_LOADING,
  SET_DEVICE_INFO,
  SET_BACKDROP,
  CLOSE_BACKDROP,
  SET_DYNAMIC_FORM,
  SET_GEOLOCACTION,
  SET_LANGUAGE,
} from './actionTypes';

export const setLoadingAction = (state) => ({
  type: SET_LOADING,
  state,
});

export const setDeviceInfo = (payload) => ({
  type: SET_DEVICE_INFO,
  payload,
});
export const setGeolocationAction = (payload) => ({
  type: SET_GEOLOCACTION,
  payload,
});

export const setBackdropAction = (state) => ({
  type: SET_BACKDROP,
  state,
});

export const closeBackdropAction = () => ({
  type: CLOSE_BACKDROP,
  state: { open: false, current: 0, total: 0 },
});

export const setDynamicFormAction = (payload) => ({
  type: SET_DYNAMIC_FORM,
  payload,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  language,
});
