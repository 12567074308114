import React, { useState } from 'react';
import { Card, CardMedia, Skeleton } from '@mui/material';

export const Picture = (props) => {
  const [loaded, setLoaded] = useState(false);
  const { src, srcWidth, srcHeight, title, scaleBy = 1, flip } = props;

  let width = srcWidth / scaleBy || 'auto';
  let height = srcHeight / scaleBy || 'auto';
  let dataUrl = src || '';

  return (
    <Card
      sx={{ maxWidthL: 320, margin: 'auto' }}
      variant="outlined"
      style={{
        width: width || 'auto',
        height: height || 'auto',
      }}
    >
      <CardMedia
        component="img"
        src={dataUrl}
        title={title}
        height={'auto'}
        style={{
          transform: flip ? 'scale(-1, 1)' : undefined,
          display: loaded ? 'initial' : 'none',
        }}
        onLoad={(ev) => setLoaded(true)}
      />
      {!loaded && <Skeleton variant="rectangular" height={180} width={width} />}
    </Card>
  );
};
