import React from 'react';
import { Tooltip, useTheme } from '@mui/material';
import InformationLine from '../Icons/system/InformationLine';

export const WarnTooltip = ({ label = 'Dato' }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={`${label} pendiente de verificación. No podrás usarlo en su totalidad hasta que sea verificado`}
      placement="top"
    >
      <span style={{ height: '24px' }}>
        <InformationLine fill={theme.palette.warning.main} />
      </span>
    </Tooltip>
  );
};
