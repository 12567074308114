import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  Link as MUILink,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PaperWrapper } from './style';

export const FallbackCard = (props) => {
  const { to, text, Icon } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PaperWrapper elevation={4}>
      <MUILink to={to} component={to ? Link : 'div'}>
        <Grid
          container
          spacing={3}
          textAlign="center"
          justifyContent="center"
          alignContent="center"
          sx={{
            height: '100%',
            paddingTop: '15vh',
            paddingBottom: '15vh',
          }}
        >
          {Icon && (
            <Grid item xs={12}>
              <Icon
                fill="#0000008a"
                width={isMobile ? 70 : 100}
                height={isMobile ? 70 : 100}
              />
            </Grid>
          )}
          <Grid item xs={8}>
            <Typography variant={isMobile ? 'h6' : 'h3'}>{text}</Typography>
          </Grid>
        </Grid>
      </MUILink>
    </PaperWrapper>
  );
};
