import { urlBuilder } from '../../helpers/apiHelpers';

// creates endpoints attached to conditionally pointed methods
export const createEndpoint = (url = '', instance) => {
  return {
    request: (reqConfig) => {
      return instance.request({ ...reqConfig, url });
    },
    get: (reqConfig = {}, urlVar) => {
      return instance.get(urlBuilder(url, urlVar), { ...reqConfig });
    },
    delete: (reqConfig = {}, urlVar) => {
      return instance.delete(urlBuilder(url, urlVar), { ...reqConfig });
    },
    post: (data, reqConfig = {}, urlVar) => {
      return instance.post(urlBuilder(url, urlVar), data, {
        ...reqConfig,
      });
    },
    put: (data, reqConfig = {}, urlVar) => {
      return instance.put(urlBuilder(url, urlVar), data, {
        ...reqConfig,
      });
    },
    patch: (reqConfig = {}, urlVar) => {
      return instance.patch(urlBuilder(url, urlVar), reqConfig.data, {
        ...reqConfig,
      });
    },
  };
};
