import { validationUtil, sanitationUtil } from '../../utils/fieldUtils';

// export const snackStates = {
//   invalidEmail: {
//     open: true,
//     text: 'Tu correo no tiene un formato correcto, verificalo por favor',
//     err: 'noUser',
//   },
//   invalidRFC: {
//     open: true,
//     text: 'Tu RFC no tiene un formato correcto, verificalo por favor',
//     err: 'badRFC',
//   },
//   invalidPhone: {
//     open: true,
//     text: 'Tu teléfono no tiene un formato correcto, verificalo por favor',
//     err: 'phone',
//   },
//   linkSentSuccess: (selectedForm, history) => ({
//     open: true,
//     severity: 'success',
//     text: `Se ha enviado un link de recuperación a tu ${
//       selectedForm === 'email' ? 'correo' : 'teléfono'
//     } con exito!`,
//     changerFunc: () => {
//       history.push('/login');
//     },
//   }),
//   linkSentFailure: (history) => ({
//     open: true,
//     text: 'Hubo un error enviando tu link, por favor intenta mas tarde...',
//     err: 'noUser',
//     changerFunc: () => {
//       history.push('/login');
//     },
//   }),
//   nonExistentUser: {
//     open: true,
//     text: 'Este usuario no existe, selecciona la opción de registro en la barra superior',
//     err: 'noUser',
//   },
//   phoneConflict: {
//     open: true,
//     text: 'El telefono de registro no coincide, verificalo por favor',
//     err: 'phone',
//   },
// };

export const errors = {
  user: ({ error }) => {
    return {
      required: 'RFC, CURP o Correo Electronico requerido',
      notValid: 'RFC, CURP o Correo Electronico no valido',
      notExist: 'Usuario no existe',
      incompleteRegistration: 'Registro incompleto',
    }[error.type];
  },
};

export const sanitizers = {
  user: (value) => {
    if (validationUtil.email(value)) {
      return sanitationUtil.email(value);
    }

    if (validationUtil.rfc(value)) {
      return sanitationUtil.rfc(value);
    }

    if (validationUtil.curp(value)) {
      return sanitationUtil.curp(value);
    }

    return value;
  },
};
