import React from 'react';
import 'dayjs/locale/es-mx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { customTheme } from './AppStyle';
import { ThemeProvider } from '@mui/material/styles';
import StoreProvider from './Store';
import NotificationProvider from './Providers/NotificationProvider';
import VerifyProvider from './Providers/VerifyProvider';
import DocsProvider from './Providers/DocsProvider';
import { InternalRouter } from './Components/InternalRouter';
import { CookiesBlockedAlert } from './Components/_common/CookiesBlockedAlert/CookiesBlockedAlert';
import { localStorageUtil } from './utils/localStorageUtils';
import { useWindowQuery } from './Hooks/useWindowQuery';

const App = () => {
  const queryParams = useWindowQuery();
  const initVerify = queryParams.get('init-verify');

  if (initVerify) {
    localStorageUtil.setCustomParams({ name: 'initVerify', value: initVerify });
  }

  if (!navigator.cookieEnabled) {
    return <CookiesBlockedAlert />;
  }

  return (
    <StoreProvider>
      <NotificationProvider>
        <VerifyProvider>
          <DocsProvider>
            <LocalizationProvider
              // utils={DayJsUtils}
              dateAdapter={AdapterDayjs}
              adapterLocale="es-mx"
            >
              <div
                style={{
                  textAlign: 'center',
                  backgroundColor: '#ffffff',
                  minHeight: '100vh',
                }}
              >
                <ThemeProvider theme={customTheme}>
                  <InternalRouter />
                </ThemeProvider>
              </div>
            </LocalizationProvider>
          </DocsProvider>
        </VerifyProvider>
      </NotificationProvider>
    </StoreProvider>
  );
};
export default App;
