import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';

export const LinearFeedBack = (props) => {
  const [progress, setProgress] = useState(0);

  const { total, current } = props;

  useEffect(() => {
    const percent = Math.min((current * 100) / total);
    setProgress(percent);
    if (percent === 100) {
      setTimeout(() => {
        setProgress(0);
      }, 1000);
    }

    return () => {
      // clearInterval(timer);
    };
  }, [total, current]);

  return (
    <div style={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
};
