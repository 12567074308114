import { endpointHandler } from '../../endpoints';
const {
  verifyUserEndpoint,
  verifyUserManuallyEndpoint,
  detectFaceEndpoint,
  verifyIdFrontEndpoint,
  verifyIdBackEndpoint,
  verificationDataEndpoint,
  startVerificationEndpoint,
  getManuallyVerifTokenEndpoint,
  concludeVerifTestEndpoint,
  addVideoAgreementEndpoint,
} = endpointHandler();

export const verifyUserAPI = (data, reqConfig, urlVar) => {
  return verifyUserEndpoint.post(data, reqConfig, urlVar);
};

export const detectFaceAPI = (data, reqConfig, urlVar) => {
  return detectFaceEndpoint.post(data, reqConfig, urlVar);
};

export const verifyUserManuallyAPI = (data, reqConfig, urlVar) => {
  return verifyUserManuallyEndpoint.post(data, reqConfig, urlVar);
};

export const verifyIdFrontAPI = (data, reqConfig, urlVar) => {
  return verifyIdFrontEndpoint.post(data, reqConfig, urlVar);
};

export const verifyIdBackAPI = (data, reqConfig, urlVar) => {
  return verifyIdBackEndpoint.post(data, reqConfig, urlVar);
};

export const getVerificationDataAPI = (reqConfig, urlVar) => {
  return verificationDataEndpoint.get(reqConfig, urlVar);
};

export const startVerificationAPI = (data, reqConfig, urlVar) => {
  return startVerificationEndpoint.post(data, reqConfig, urlVar);
};

export const getManuallyVerifTokenAPI = (reqConfig, urlVar) => {
  return getManuallyVerifTokenEndpoint.get(reqConfig, urlVar);
};

export const concludeVerifTestAPI = (data, reqConfig, urlVar) => {
  return concludeVerifTestEndpoint.put(data, reqConfig, urlVar);
};

export const addVideoAgreementAPI = (data, reqConfig, urlVar) => {
  return addVideoAgreementEndpoint.post(data, reqConfig, urlVar);
};
