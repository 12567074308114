import React from 'react';
import { Snackbar, IconButton, Alert } from '@mui/material';
import CloseLineIcon from '../../Icons/system/CloseLine';
import { MUICircularProgress } from '../MUICircularProgress/MUICircularProgress';
import {
  useNotificationStore,
  useNotificationDispatch,
} from '../../../../Providers/NotificationProvider';
import { setSnackState } from '../../../../actions';
import { deviceUtil } from '../../../../utils/deviceUtils';

export const MUINewSnackbar = () => {
  const { snack } = useNotificationStore();
  const dispatch = useNotificationDispatch();
  const [duration, setDuration] = React.useState(10000);
  const positionMapping = {
    topLeft: { vertical: 'top', horizontal: 'left' },
    topRight: { vertical: 'top', horizontal: 'right' },
    topCenter: { vertical: 'top', horizontal: 'center' },
    bottomLeft: { vertical: 'bottom', horizontal: 'left' },
    bottomRight: { vertical: 'bottom', horizontal: 'right' },
    bottomCenter: { vertical: 'bottom', horizontal: 'center' },
  };
  React.useEffect(() => {
    if (snack.duration) {
      setDuration(snack.duration);
    }
    return () => setDuration(10000);
  }, [duration, snack]);

  const handleClose = (e, closeReason) => {
    if (snack.changerFunc) {
      dispatch(setSnackState({ ...snack, open: false, err: '' }));
      return snack.changerFunc();
    }
    return dispatch(setSnackState({ ...snack, open: false, err: '' }));
  };

  let position = snack.position;
  if (!position && deviceUtil.isMobile()) position = 'topCenter';
  if (!position && !deviceUtil.isMobile()) position = 'bottomCenter';

  return (
    <Snackbar
      open={snack.open}
      autoHideDuration={snack.duration || 10000}
      anchorOrigin={positionMapping[position]}
      onClose={handleClose}
    >
      <Alert
        severity={snack.severity || 'error'}
        variant={snack.variant || 'filled'}
        onClose={handleClose}
        sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
        action={
          <IconButton
            style={{ padding: '10px' }}
            onClick={(e) => handleClose(e, 'closebutton')}
          >
            <MUICircularProgress duration={duration} />
            <CloseLineIcon width="1rem" height="1rem" color="white" />
          </IconButton>
        }
      >
        {snack.text}
      </Alert>
    </Snackbar>
  );
};
