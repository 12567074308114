import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { TrWrapper, Wrapper } from './style';

export const Loading = (props) => {
  const { tr, text, style } = props;
  return tr ? (
    <TrWrapper style={{ ...style }}>
      <td>
        <CircularProgress size={60} />
      </td>
    </TrWrapper>
  ) : (
    <Wrapper style={{ ...style }}>
      <CircularProgress size={60} />
      {text && (
        <Typography variant="subtitle1" style={{ margin: '1rem' }}>
          {text}
        </Typography>
      )}
    </Wrapper>
  );
};
