export const SET_SELFIE_PIC = 'SET_SELFIE_PIC';
export const SET_ID_FRONT_PIC = 'SET_ID_FRONT_PIC';
export const SET_ID_BACK_PIC = 'SET_ID_BACK_PIC';
export const SET_SELFIE_READY = 'SET_SELFIE_READY';
export const SET_ID_READY = 'SET_ID_READY';
export const SET_ID_BACK_READY = 'SET_ID_BACK_READY';
export const SET_ID_TYPE = 'SET_ID_TYPE';
export const SET_SELFIE_VERIFY = 'SET_SELFIE_VERIFY';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_ID_FRONT_RES = 'SET_ID_FRONT_RES';
export const SET_ID_BACK_RES = 'SET_ID_BACK_RES';
export const SET_SELFIE_RES = 'SET_SELFIE_RES';
export const SET_VERIFY_RES = 'SET_VERIFY_RES';
export const SET_DEV_VERIFY = 'SET_DEV_VERIFY';
export const RESET_VERIFY_RES = 'RESET_VERIFY_RES';
export const CLEAR_WIZARD = 'CLEAR_WIZARD';

export const SET_DYNAMIC_FORM = 'SET_DYNAMIC_FORM';

export const SET_DOCS = 'SET_DOCS';
export const SET_DOCS_PAGINATION = 'SET_DOCS_PAGINATION';
export const SET_SIGNED_DOCS = 'SET_SIGNED_DOCS';
export const SET_PENDING_DOCS = 'SET_PENDING_DOCS';
export const SET_SELECTED_DOCS = 'SET_SELECTED_DOCS';
export const CLEAR_DOCS = 'CLEAR_DOCS';
export const SET_DIALOG_STATE = 'SET_DIALOG_STATE';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const SET_SNACK_STATE = 'SET_SNACK_STATE';

export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';

export const SET_DEVICE_INFO = 'SET_DEVICE_INFO';
export const SET_GEOLOCACTION = 'SET_GEOLOCACTION';
export const SET_LOADING = 'SET_LOADING';
export const SET_BACKDROP = 'SET_BACKDROP';
export const CLOSE_BACKDROP = 'CLOSE_BACKDROP';
export const SET_USED_CAMERA = 'SET_USED_CAMERA';
export const SET_LANGUAGE = 'SET_LANGUAGE';
