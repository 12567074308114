import { setLoadingAction, setSnackState } from '../actions';
import { dispatchUtil, notificationDispatchUtil } from './dispatchUtil';

const defaultAction = () =>
  notificationDispatchUtil.dispatch(
    setSnackState({
      open: false,
      text: '',
    }),
  );

export const snackUtil = {
  defaultAction,
  userDataComplete: (changerFunc) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Datos de usuario completos',
        severity: 'success',
        duration: 2000,
        changerFunc,
      }),
    ),
  userDataUpdateSuccess: (changerFunc) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Actualización exitosa',
        severity: 'success',
        duration: 2000,
        changerFunc,
      }),
    ),
  phoneVerificationSuccess: (changerFunc) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: '¡Teléfono verificado con exito!',
        severity: 'success',
        duration: 5000,
        changerFunc,
      }),
    ),
  loginAgainPlease: ({ lang } = {}) => {
    const i18n = {
      es: `Inicia sesión de nuevo`,
      en: `Sign in again`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'info',
      }),
    );
  },
  loginSuccess: ({ changerFunc, lang } = {}) => {
    const i18n = {
      es: `Ingreso exitoso`,
      en: `Successful login`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'success',
        duration: 2000,
        changerFunc,
      }),
    );
  },
  signupSuccess: (changerFunc) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Registro exitoso',
        severity: 'success',
        duration: 2000,
        changerFunc,
      }),
    ),
  docsCreated: (numOfDocs) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: `${numOfDocs} documentos creados`,
        severity: 'success',
      }),
    ),
  docsDeleted: (numOfDocs) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Borramos tus documentos',
        severity: 'success',
      }),
    ),
  extAPICreated: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: `API agregada correctamente`,
        severity: 'success',
      }),
    ),
  extAPIModified: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: `API modificada correctamente`,
        severity: 'success',
      }),
    ),
  noRedirectStored: (history) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'No tenemos guardada una ruta de redirección, te enviaremos a casa',
        severity: 'info',
        changerFunc: () => {
          dispatchUtil.dispatch(setLoadingAction(false));
          return history.push('/home');
        },
        duration: 3000,
      }),
    ),
  docsSigningSuccess: ({ lang } = {}) => {
    const i18n = {
      es: `Firma de documento(s) exitosa!`,
      en: `Document(s) signed successfully!`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'success',
      }),
    );
  },
  signingInProgress: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Firmando...',
        severity: 'info',
      }),
    ),
  signatureCreated: ({ lang, duration, changerFunc } = {}) => {
    const i18n = {
      es: `¡Firma creada!`,
      en: `Signature created!`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'success',
        duration,
        changerFunc,
      }),
    );
  },
  changePasswordSuccess: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Cambio de contraseña exitoso',
        severity: 'success',
      }),
    ),
  revokeSuccess: ({ lang } = {}) => {
    const i18n = {
      es: `Se revocó tu firma correctamente`,
      en: `Your signature was successfully revoked`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'success',
      }),
    );
  },
  sentOtp: ({ lang } = {}) => {
    const i18n = {
      es: `Envío de código exitoso, ¡Revisa tu correo!`,
      en: `Successful code submission, check your email!`,
    };
    return notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'success',
      }),
    );
  },
  verificationChangeSuccess: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Se ha cambiado tu verificación',
        severity: 'success',
      }),
    ),
  profilePictureUploadSuccess: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Se ha cambiado tu foto de perfil exitosamente',
        severity: 'success',
      }),
    ),
  deleteProfPicSuccess: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Se ha eliminado tu foto de perfil',
        severity: 'success',
      }),
    ),

  notAuthorized: ({ lang, duration, changerFunc } = {}) => {
    const i18n = {
      es: `Intenta de nuevo cuando se cierre esta ventana`,
      en: `Please try again when this window closes.`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        duration,
        changerFunc,
      }),
    );
  },
  createCronSuccess: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Created cron successfully',
        severity: 'success',
      }),
    ),
  updatedCronSuccess: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Updated cron successfully',
        severity: 'success',
      }),
    ),
  deleteCronSuccess: () =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Cron deleted successfully',
        severity: 'success',
      }),
    ),
  cronMarkedForDeletion: (payload) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Cron marked for deletion, please wait at least 1 minutes for it to be deleted',
        severity: 'warning',
        ...payload,
      }),
    ),
  cronMarkedForUpdate: (payload) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'Cron marked for update, please wait at least 1 minutes for it to be updated',
        severity: 'warning',
        ...payload,
      }),
    ),
  docAlreadySigned: ({ lang, duration, changerFunc } = {}) => {
    const i18n = {
      es: `Enviaremos una notificación a la plataforma external`,
      en: `We will send a notification to the external platform`,
    };
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: i18n[lang] || i18n.es,
        severity: 'warning',
        duration,
        changerFunc,
      }),
    );
  },
  docDeleted: (payload) =>
    notificationDispatchUtil.dispatch(
      setSnackState({
        open: true,
        text: 'El documento se ha eliminado',
        severity: 'success',
        ...payload,
      }),
    ),
};
